<div class="card">
  <form
    [ngClass]="{ 'was-validated': validate }"
    class="form theme-form"
    [formGroup]="form"
    novalidate
    (ngSubmit)="onSubmitAddBugReport()"
  >
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label" for="exampleFormControlText">
              {{ "support.newTicket.object" | translate }} *
            </label>
            <input
              class="form-control"
              id="exampleFormControlText"
              type="text"
              required=""
              [placeholder]="'support.newTicket.placeholderObject' | translate"
              formControlName="title"
              [ngClass]="{
                'is-invalid':
                  !form.get('title')?.valid && form.get('title')?.touched
              }"
            />
            <div
              class="invalid-feedback"
              *ngIf="!form.get('title')?.valid && form.get('title')?.touched"
            >
              {{ "support.newTicket.feedback.required-title" | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label" for="exampleFormControlTextarea"
              >{{ "support.newTicket.msg" | translate }} *</label
            >
            <textarea
              class="form-control"
              id="exampleFormControlTextarea"
              rows="3"
              required=""
              [ngClass]="{
                'is-invalid':
                  !form.get('description')?.valid &&
                  form.get('description')?.touched
              }"
              [placeholder]="'support.newTicket.placeholderMsg' | translate"
              formControlName="description"
            ></textarea>
            <div
              class="invalid-feedback"
              *ngIf="
                !form.get('description')?.valid &&
                form.get('description')?.touched
              "
            >
              {{
                "support.newTicket.feedback.required-description" | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <label for="formFileDisabled" class="form-label">{{
        "support.newTicket.attachment" | translate
      }}</label>
      <div class="m-b-10" *ngIf="showProgress">
        <ngb-progressbar [value]="uploadProgress"
          >{{ uploadProgress }}%</ngb-progressbar
        >
      </div>
      <!--<input class="form-control" type="file" id="formFile" multiple (change)="onSelect($event)"/>-->
      <ngx-dropzone
        [multiple]="false"
        (change)="onSelect($event.addedFiles)"
        accept="application/pdf,*.csv,image/*,video/*"
      >
        <ngx-dropzone-label>
          <div>
            <div class="dz-message needsclick">
              <i class="icon-cloud-up"></i>
              <p style="color: black">
                {{ "dropzoneFile.title" | translate }}
              </p>
              <p class="dz-message needsclick" style="color: black">
                {{ "dropzoneFile.rulePart1" | translate }}
                <strong [ngClass]="{ 'txt-danger': showError }"
                  >{{ "dropzoneFile.rulePart2" | translate }} 4MB</strong
                >
              </p>
            </div>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          [file]="f"
          [attr.data-type]="f.type"
          (removed)="onRemove()"
        >
          <ngx-dropzone-label style="align-self: flex-end; margin: 0">
            <div class="ligne-top"></div>
            <div class="new-dropdown-label">
              {{ f.name.split(".")[0] | castFileName }} .{{
                f.type.split("/")[1]
              }}
            </div>
          </ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <div class="card-footer text-end">
      <button class="btn btn-outline-primary" (click)="clear()">
        {{ "buttons.clear" | translate }}
      </button>
      <button
        [disabled]="!form.valid"
        class="btn btn-primary ms-2"
        type="submit"
      >
        <loading-button
          [loading]="loading$ | async"
          [value]="'buttons.submit' | translate"
          [icon]="null"
        ></loading-button>
      </button>
    </div>
  </form>
</div>
