import { Component, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/base.component';
import { UserHistoryActionsEnum } from 'src/app/shared/enum/userHistoryActions';
import { UserHistoryServicesEnum } from 'src/app/shared/enum/userHistoryService';
import { Data_invoice } from 'src/app/shared/models/iberis/facture/data_invoice';
import { ResultFacturesIberis } from 'src/app/shared/models/iberis/facture/resultFacturesIberis';
import { CommonParamsIberis } from 'src/app/shared/models/iberis/paramsForGetAll';
import { IColumn } from 'src/app/shared/models/table/column.interface';
import { SaveUserHistoryService } from 'src/app/shared/services/history/saveHistory';
import { invokeIdIberis, resultIdIberis } from 'src/app/store/advertiser/advertiser.actions';
import { resultIdIberisSelector } from 'src/app/store/advertiser/advertiser.selectors';
import { AppStateInterface } from 'src/app/store/appState.interface';
import { invokeAllFacturesForClientFromIberis, invokeDownloadInvoice, resultFacturesForClientFromIberis } from 'src/app/store/facture/facture.actions';
import { loadingInvoiceSelector, resultAllFacturesByClientFromIberisSelector, resultDownloadInvoiceSelector } from 'src/app/store/facture/facture.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent extends BaseComponent implements OnInit {

  ad : string
  loading$: Observable<boolean | null>;
  columns: IColumn[] = [
    {
      key: "",
      label: "invoices.numInvoice",
      sorter: true,
    },
    {
      key: "",
      label: "invoices.dateInvoice",
      sorter: true,
    },
    {
      key: "",
      label: "campaignDetails.campaignName",
      sorter: false,
    },
  ];
  listInvoicesFromIberis$ : Observable<ResultFacturesIberis | null>;
  resultListInvoicesFromIberis : Data_invoice[] = []
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  isDownloadInvoice: boolean;
  specificColumun: number;
  totalRecords : number
  pdfUrl = new EventEmitter<SafeResourceUrl>();
  idIberis$: Observable<string | null>;
  idIberis: string;
  downloadInvoiceFromIberis$: Observable<any>;
  downloadInvoicesFromIberis: any;
  blob: Blob;
  invoiceNumber: string;
  itemsPerPage : number = 10
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    protected translate: TranslateService,
    private sanitizer: DomSanitizer,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store)
    this.listInvoicesFromIberis$ = this.store
    .pipe(select(resultAllFacturesByClientFromIberisSelector))
    .pipe(takeUntil(this.ngDestroyed$));
  this.loading$ = this.store
    .pipe(select(loadingInvoiceSelector))
    .pipe(takeUntil(this.ngDestroyed$));
    this.idIberis$ = this.store
    .pipe(select(resultIdIberisSelector))
    .pipe(takeUntil(this.ngDestroyed$));
    this.downloadInvoiceFromIberis$ = this.store
    .pipe(select(resultDownloadInvoiceSelector))
    .pipe(takeUntil(this.ngDestroyed$));
   }
  ngOnInit(): void {
    this.loading$.subscribe((data) => {
    });
    this.store.dispatch(resultIdIberis({ idIberis: "" }));
    this.store.dispatch(invokeIdIberis());
    this.store.dispatch(
      resultFacturesForClientFromIberis({ resultFacturesIberis: null })
    );
    this.idIberis$.subscribe((result) => {
      if (result) {
        this.idIberis = result;
        this.store.dispatch(
          invokeAllFacturesForClientFromIberis({
            params: { ...this.params },
            start: 0,
            length: 10,
            draw : 10,
            contactHashedId: this.idIberis,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.LIST_INVOICES
        );
      }
    });
    this.listInvoicesFromIberis$.subscribe(
      (result: ResultFacturesIberis | null) => {
        if (result != null) {
          this.resultListInvoicesFromIberis = result.data.invoices.data;
          this.totalRecords = result.data.invoices.recordsTotal
        }
      }
    );
    this.downloadInvoiceFromIberis$.subscribe((result) => {
      if (result) {
        this.downloadInvoicesFromIberis = result;
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        if (this.isDownloadInvoice) {
          const downloadLink = document.createElement("a");
          downloadLink.href = pdf;
          //TOFIX : ${invoice_Number}
          downloadLink.download = `${this.invoiceNumber}.pdf`;
          downloadLink.click();
          URL.revokeObjectURL(pdf);
          this.isDownloadInvoice = false;
        } else {
          this.pdfUrl.emit(this.sanitizer.bypassSecurityTrustResourceUrl(pdf));
        }
      }
    });
  }
  searchTerm(ad: string) {
  }
  onClickshowModal(
    approveAdvertiserModal: any,
    invoiceId: string,
    invoice_Number: string
  ) {
    this.isDownloadInvoice = false;
    this.store.dispatch(
      invokeDownloadInvoice({ params: this.params, invoiceId: invoiceId })
    );
    const modalRef = this.modalService.open(approveAdvertiserModal, {
      centered: true,
      size: "xl",
    });
  }
  onClickDownload(invoiceId: string, invoice_Number: string, i: number) {
    this.specificColumun = i;
    this.isDownloadInvoice = true;
    this.invoiceNumber = invoice_Number;
    this.store.dispatch(
      invokeDownloadInvoice({ params: this.params, invoiceId: invoiceId })
    );
  }
  changePage(event: number) {
    let start : number = 0
    if(event>1){
      start =  this.itemsPerPage * (event-1)
    }else start = 0
    this.store.dispatch(
      invokeAllFacturesForClientFromIberis({
        params: { ...this.params },
        start: start,
        length: this.itemsPerPage,
        draw : this.itemsPerPage,
        contactHashedId: this.idIberis,
      })
    );
  }
}
