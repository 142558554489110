import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { BadgeService } from "src/app/shared/services/badge.service";
import { RoutersFilterInterface } from "src/app/shared/models/router/routersFilter.interface";
import { RouterInterface } from "src/app/shared/models/router/router.interface";
import { RoutersResultInterface } from "src/app/shared/models/router/routersResult.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  routerSuccessMessageSelector,
  loadingRouterSelector,
  routerFilterSelector,
  routersResultSelector,
} from "src/app/store/router/router.selectors";
import {
  routerSuccessMessage,
  invokeRouterFilter,
  invokeRouters,
} from "src/app/store/router/router.action";
import { NgbdSortableHeader } from "src/app/shared/directive/sortable.directive";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { DecimalPipe } from "@angular/common";
import { TableService } from "src/app/shared/services/table/table.service";
import { initialState } from "src/app/store/router/router.reducers";
import {
  RouterStatus,
  RouterStatusToShow,
} from "src/app/shared/enum/router-status";
import { DateView } from "@danielmoncada/angular-datetime-picker";
import { RouterType } from "src/app/shared/enum/routerType";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateRouterStepperComponent } from "./create-router-stepper/create-router-stepper.component";
import {
  FilterInterface,
  routersFilter,
} from "src/app/shared/data/filter-data";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";

@Component({
  selector: "app-router",
  templateUrl: "./router.component.html",
  providers: [TableService, DecimalPipe],
})
export class RouterComponent extends BaseComponent implements OnInit {
  public years = DateView.MULTI_YEARS;
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;

  columns: IColumn[] = [
    {
      key: "MAC",
      label: "MAC Address",
      sorter: true,
    },
    {
      key: "locationPartner",
      label: "Location partner",
      sorter: true,
    },
    {
      key: "productionDate",
      label: "Production Date",
      sorter: true,
    },
    {
      key: "dateOfPurchase",
      label: "Date of purchase",
      sorter: true,
    },
  ];
  columnsFsi: IColumn[] = [
    {
      key: "AcquisitionPrice",
      label: "Acquisition price",
      sorter: true,
    },
    {
      key: "Supplier",
      label: "Supplier",
      sorter: true,
    },
  ];
  columnsRelead: IColumn[] = [
    {
      key: "IP",
      label: "FAS IP",
      sorter: true,
    },
    {
      key: "SessiontimeOut",
      label: "Session timeOut",
      sorter: true,
    },
    {
      key: "SSID",
      label: "SSID",
      sorter: true,
    },
    {
      key: "Reference",
      label: "Reference",
      sorter: true,
    },
  ];
  loading$: Observable<boolean | null>;
  loading: boolean | null;
  macToDelete: string;
  successMessageRouter$: Observable<any | null>;
  isDeleted$: Observable<boolean | null>;
  deletedIsLoading = false;
  routerDetail: RouterInterface;
  routersResult$: Observable<RoutersResultInterface | null>;
  routersResult: RoutersResultInterface;
  isFiltring: boolean;
  formFilter: FormGroup;
  error = "";
  success = "";
  productionDateRange = [null, null];
  purchaseDateRange = [null, null];
  routerStatusToShow = RouterStatusToShow;
  routerStatus = RouterStatus;
  pageSize: number = 10;
  pageNumber: number = 1;
  routersFilter$: Observable<RoutersFilterInterface>;
  routersFilter: RoutersFilterInterface = {
    ...initialState.routersFilter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  releadRouters: boolean;
  typeRouter = RouterType;
  listStatus: string[];
  result: RoutersFilterInterface;
  type: RouterType;
  filterRouter: FilterInterface = routersFilter;
  constructor(
    private store: Store<AppStateInterface>,
    private route: Router,
    public badgeService: BadgeService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private saveHistory : SaveUserHistoryService
  ) {
    super(store);
    this.loading$ = this.store
      .pipe(select(loadingRouterSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    //Delete Router
    this.successMessageRouter$ = this.store
      .pipe(select(routerSuccessMessageSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.routersResult$ = this.store
      .pipe(select(routersResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.routersFilter$ = this.store
      .pipe(select(routerFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.formFilter = this.fb.group({
      companyName: [null],
      MAC: [null],
      IP: [null],
      status: [null],
      productionDate: [[null, null]],
      purchaseDate: [[null, null]],
    });
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.releadRouters = this.route.url.endsWith("/relead");
        if (this.releadRouters) {
          this.type = RouterType.RELEAD_ROUTER;
        } else {
          this.type = RouterType.FSI_ROUTER;
        }
      }
    });
  }
  ngOnInit(): void {
    this.filterRouter.name.map(
      (e) =>
        (e.data = Object.keys(RouterStatus)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              RouterStatusToShow[key as keyof typeof RouterStatus]
          ))
    );
    this.routersFilter$.subscribe((result: RoutersFilterInterface) => {
      if (result != null) {
        this.routersFilter = result;
        this.pageNumber = result.pager.pageNumber;
        this.store.dispatch(invokeRouters());
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.LIST_ROUTERS
        );
      }
    });
    this.routersResult$.subscribe((result: RoutersResultInterface | null) => {
      if (result) {
        this.routersResult = result ?? null;
      }
    });
    this.successMessageRouter$.subscribe((success) => {
      if (success != null) {
        this.toastr.success(
          this.translate.instant("response.success." + success.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(invokeRouters());
        this.store.dispatch(routerSuccessMessage({ successMessage: null }));
      }
    });
  }
  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeRouterFilter({
        routerFilter: {
          ...this.routersFilter,
          MAC: this.formFilter.get("MAC")?.value,
          type: this.type,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  saveOption(options: string[] | null) {
    this.store.dispatch(invokeApiDistroyed());
    let listFounds: any[] = [];
    options?.map((option) => {
      listFounds.push(
        Object.keys(RouterStatusToShow).find(
          (key) => RouterStatusToShow[key as RouterStatus] === option
        )
      );
    });
    this.store.dispatch(
      invokeRouterFilter({
        routerFilter: {
          ...this.routersFilter,
          status: listFounds,
          type: this.type,
          pager: {
            ...this.routersFilter.pager,
          },
        },
      })
    );
  }
  createRouter() {
    const modal = this.modalService.open(CreateRouterStepperComponent, {
      centered: true,
    });
    modal.componentInstance.routerType = this.releadRouters;
  }
}
