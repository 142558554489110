<div class="modal-header">
  <h4 class="modal-title">
    {{ "campaignDetails.updateAdvertisingCampaign" | translate }} {{ ad.title }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row" *ngIf="ad.objectiveName == objectiveEnum.WEB_SITE_TRAFFIC">
      <div class="col">
        <div class="mb-3">
          <label class="form-label label" for="exampleFormControlText">
            {{ "campaignDetails.redirectionLink" | translate }} *
          </label>
          <input
            class="form-control"
            id="exampleFormControlText"
            type="text"
            [placeholder]="'campaignDetails.placeholderRedirection' | translate"
            formControlName="redirectionLink"
            required
          />
        </div>
      </div>
    </div>
    <div class="col mb-3">
      <div class="form-group">
        <label class="col-form-label label" for="Start Date">
          {{ "campaignDetails.startDate" | translate }} *
        </label>
        <div class="input-group">
          <div
            class="input-group date"
            id="dt-date"
            data-target-input="nearest"
          >
            <input
              class="form-control datetimepicker-input digits"
              type="text"
              [owlDateTime]="dt1"
              formControlName="startDate"
              placeholder="yyyy-mm-dd"
            />
            <div
              class="input-group-text bg-primary"
              data-target="#dt-date"
              [owlDateTimeTrigger]="dt1"
            >
              <i
                class="icofont icofont-ui-calendar"
                style="color: white; font-size: 12px"
              ></i>
            </div>
          </div>
          <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        </div>
      </div>
    </div>
    <div class="col mb-3">
      <div class="form-group">
        <label class="col-form-label label" for="Start Date">
          {{ "campaignDetails.endDate" | translate }} *
        </label>
        <div class="input-group">
          <div
            class="input-group date"
            id="dt-date"
            data-target-input="nearest"
          >
            <input
              class="form-control datetimepicker-input digits"
              type="text"
              [owlDateTime]="dt"
              formControlName="endDate"
              readonly
              placeholder="yyyy-mm-dd"
            />
            <div
              class="input-group-text bg-primary"
              data-target="#dt-date"
              [owlDateTimeTrigger]="dt"
            >
              <i
                class="icofont icofont-ui-calendar"
                style="color: white; font-size: 12px"
              ></i>
            </div>
          </div>
          <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
        </div>
      </div>
    </div>
    <div class="col mb-3">
      <div class="form-group">
        <label class="label"
          >{{ "campaignDetails.locations" | translate }} *</label
        >
        <ng-select
          formControlName="locations"
          [items]="locations"
          bindLabel="locations"
          bindValue="id"
          [multiple]="true"
          (clear)="(true)"
          [placeholder]="'campaignDetails.locations' | translate"
        >
        </ng-select>
      </div>
    </div>
    <div class="col">
      <label class="label">{{ "addCampaign.video" | translate }} *</label>
      <div class="m-b-10" *ngIf="showProgress">
        <ngb-progressbar [value]="uploadProgress"
          >{{ uploadProgress }}%</ngb-progressbar
        >
      </div>
      <ngx-dropzone
        [multiple]="false"
        (change)="onSelect($event.addedFiles)"
        accept="video/*"
      >
        <ngx-dropzone-label>
          <div>
            <div class="dz-message needsclick">
              <i class="icon-cloud-up"></i>
              <p style="color: black">{{ "dropzoneFile.title" | translate }}</p>
              <p class="dz-message needsclick" style="color: black">
                {{ "dropzoneFile.rulePart1" | translate }}
                <strong [ngClass]="{ 'txt-danger': showError }"
                  >{{ "dropzoneFile.rulePart2" | translate }} 5MB</strong
                >
              </p>
            </div>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [file]="f"
          [removable]="true"
          (removed)="onRemove()"
        >
        <ngx-dropzone-label style="align-self: flex-end; margin: 0;">
          <div class="ligne-top"></div>
          <div class="new-dropdown-label">
            {{ f.name.split(".")[0] | castFileName }} .{{ f.type.split("/")[1] }}
          </div>
        </ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-primary"
    data-bs-dismiss="modal"
    (click)="close()"
  >
    {{ "buttons.close" | translate }}
  </button>
  <button class="btn btn-primary" (click)="update()" [disabled]="!form.valid || filename == null">
    <loading-button
      [loading]="loading"
      [value]="'buttons.confirm'"
      [icon]="null"
    ></loading-button>
  </button>
</div>
