import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { UpdateComponent } from "src/app/shared/components/drob-zone-options/modal/update/update.component";
import { OptionActionEnum } from "src/app/shared/data/actions-data";
import { TypeCards } from "src/app/shared/enum/typeCards";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { BillingInterface } from "src/app/shared/models/billing/billing.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import { billingSelector } from "src/app/store/billing/billing..selectors";
import { invokeBillingInfo } from "src/app/store/billing/billing.actions";
import {
  invokeCheckIfExistByEmail,
  setCheckUserExistsByEmail,
} from "src/app/store/user/user.actions";
import { checkUserExistByEmailSelector } from "src/app/store/user/user.selectors";

@Component({
  selector: "app-update-advertiser",
  templateUrl: "./update-advertiser.component.html",
})
export class UpdateAdvertiserComponent extends BaseComponent implements OnInit {
  checkLocationExistByEmail$: Observable<boolean | null>;
  checkAndSaveChanges() {
    let isUpdateEmail = false;
    if (
      this.advertiser.email.toLowerCase() !=
      this.personalInfoForm.get("email")?.value.toLowerCase()
    ) {
      isUpdateEmail = true;
      this.store.dispatch(
        invokeCheckIfExistByEmail({
          email: this.personalInfoForm.get("email")?.value.toLowerCase(),
        })
      );
    }
    if (!isUpdateEmail) {
      this.saveChanges();
    }
  }
  saveChanges() {
    this.advertiser = {
      ...this.personalInfoForm.value,
      ...this.companyInfoForm.value,
    };
    this.billingInfo = this.billingInfoForm.value;
    this.onClickBack();
  }
  onClickBack() {
    const modal = this.modalService.dismissAll(UpdateAdvertiserComponent);
    const modalRef = this.modalService.open(UpdateComponent, {
      centered: true,
      size: "xl",
    });
    modalRef.componentInstance.isCol4 = true;
    modalRef.componentInstance.type = TypeCards.ADMIN_ADVERTISER_UPDATE;
    modalRef.componentInstance.title = "actions.advertiser.updateAdvertiser";
    modalRef.componentInstance.advertiser = this.advertiser;
    modalRef.componentInstance.email = this.email.toLowerCase();
    modalRef.componentInstance.billingInfo = this.billingInfo;
  }
  onClickCloseModal() {
    const modal = this.modalService.dismissAll(UpdateAdvertiserComponent);
  }

  personalInfoForm: FormGroup<any>;
  companyInfoForm: FormGroup<any>;
  billingInfoForm: FormGroup<any>;

  advertiser: AdvertiserDetailsInterface;

  billingInfo$: Observable<BillingInterface | null>;
  billingInfo: BillingInterface | null;

  @Input() selectedOption: OptionActionEnum;
  section = OptionActionEnum;
  @Input() email: string;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    super(store);
    this.checkLocationExistByEmail$ = this.store
      .pipe(select(checkUserExistByEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.billingInfo$ = this.store
      .pipe(select(billingSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.personalInfoForm = this.fb.group({
      firstName: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ],
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ],
      ],
      email: new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
      ]),
      phone: [
        null,
        [Validators.required, Validators.pattern("^(?=.*?[0-9]).{8}$")],
      ],
      logo: [null],
      birthday: [null],
    });

    this.companyInfoForm = this.fb.group({
      companyName: [null, [Validators.required]], //relead
      companyActivity: [null, [Validators.required]],
      companyAddress: [null, [Validators.required]],
      companyRole: [null, [Validators.required]],
      website: new FormControl(null, [
        Validators.pattern(
          "^(?=.*?(https://.))(?=.*?[-a-zA-Z0-9@:%_+.~#?&/=]).{11,}$"
        ),
        Validators.required,
      ]),
    });

    this.billingInfoForm = this.fb.group({
      legalName: [null],
      taxRegistration: [null],
      address: [null],
      email: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.personalInfoForm.patchValue({
      firstName: this.advertiser.firstName,
      lastName: this.advertiser.lastName,
      email: this.advertiser.email.toLowerCase(),
      phone: this.advertiser.phone,
      logo: this.advertiser.logo,
      birthday: this.advertiser.birthday,
    });
    this.companyInfoForm.patchValue({
      companyName: this.advertiser.companyName,
      companyActivity: this.advertiser.companyActivity,
      companyAddress: this.advertiser.companyAddress,
      companyRole: this.advertiser.companyRole,
      website: this.advertiser.website,
    });
    if (this.billingInfo != null)
      this.billingInfoForm.patchValue({
        legalName: this.billingInfo.legalName,
        taxRegistration: this.billingInfo.taxRegistration,
        address: this.billingInfo.address,
        email: this.billingInfo.email.toLowerCase(),
      });
    this.billingInfo$.subscribe((result) => {
      if (result) {
        if (this.billingInfo == null)
          this.billingInfoForm.patchValue({
            legalName: result.legalName,
            taxRegistration: result.taxRegistration,
            address: result.address,
            email: result.email.toLowerCase(),
          });
      } else {
        this.store.dispatch(
          invokeBillingInfo({ email: this.advertiser.email.toLowerCase() })
        );
      }
    });

    this.checkLocationExistByEmail$.subscribe((data) => {
      if (data != null) {
        this.store.dispatch(setCheckUserExistsByEmail({ isExist: null }));
        if (data) {
          this.toastr.error(
            this.translate.instant("response.error.email-exist"),
            this.translate.instant("response.errorTitle")
          );
        }
        if (!data) {
          this.saveChanges();
        }
      }
    });
  }

  isDisableButton() {
    switch (this.selectedOption) {
      case OptionActionEnum.ADMIN_ADVERTISER_UPDATE_PERSONAL_INFORMATION: {
        return !this.personalInfoForm.valid;
      }
      case OptionActionEnum.ADMIN_ADVERTISER_UPDATE_COMPANY_INFORMATION: {
        return !this.companyInfoForm.valid;
      }
      case OptionActionEnum.ADMIN_ADVERTISER_UPDATE_BILLING: {
        return false;
      }
      default:
        return;
    }
  }
}
