import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { Account } from "src/app/shared/models/user/account";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeAccount,
  invokeLogin,
  loginSuccess,
} from "src/app/store/auth/auth.actions";
import {
  accountSelector,
  authLoadingSelector,
  userIsLoggedInSelector,
} from "src/app/store/auth/auth.selectors";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { NavigatorService } from "src/app/shared/services/navigator/navigator.service";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { RecaptchaService } from "src/app/shared/services/recaptcha.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends BaseComponent implements OnInit {
  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
  isSuccess$: Observable<string | null>;

  loading$: Observable<boolean | null>;
  loading: boolean | null = false;
  submitted: boolean;
  account$: Observable<Account | null>;
  advertiserInfo$: Observable<AdvertiserDetailsInterface>;
  advertisser: AdvertiserDetailsInterface;
  public validate = false;

  form: FormGroup;
  error: string | null = null;

  public show: boolean = false;
  apiStatus: string;

  ipAddress: string | null = null;

  reCaptchaKey = environment.RECAPTCHA_PUBLIC_KEY;
  isValidateCaptcha: boolean | null = null;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppStateInterface>,
    private nagivatorService: NavigatorService,
    private captchaService : RecaptchaService
  ) {
    super(store);

    this.isSuccess$ = this.store
      .pipe(select(userIsLoggedInSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(authLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.account$ = this.store
      .pipe(select(accountSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
          Validators.pattern("^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*?\\s).{8,}$"),
        ],
      ],
    });
  }
  ngOnInit(): void {
    this.nagivatorService.getIpAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });

    this.isSuccess$.subscribe((token) => {
      if (token != null) {
        localStorage.setItem("token", token);
        this.store.dispatch(invokeAccount());
      } else {
        this.store.dispatch(loginSuccess({ token: null }));
      }
    });
    this.loading$.subscribe((loading) => {});
    this.account$.subscribe((account) => {
      if (account?.role && account.user) {
        this.router.navigate(["dashboard"]);
      }
    });
  }
  get email() {
    return this.form.get("email");
  }
  get password() {
    return this.form.get("password");
  }
  login(event: any) {
    this.submitted = true;
    this.loading = true;
    this.error = "";
    this.validate = !this.form.valid;
    this.error = "";
    if (this.form.valid) {
      this.loading = true;
      let user = this.form.value;
      this.store.dispatch(
        invokeLogin({
          email: user.email.toLowerCase(),
          password: user.password,
          ipAddress: this.ipAddress,
        })
      );
    }
    if (this.form.invalid) {
      return;
    }
  }
  showPassword() {
    this.show = !this.show;
  }

  resolved(token: string) {
    if(token){
      this.isValidateCaptcha = true
    }
    this.captchaService.validateCaptcha(token).subscribe({
      next :(response)=>{
        //this.isValidateCaptcha = response;
        console.log("isValidateCaptcha",response)
      }
    })
  }

  onExpired(){
    this.isValidateCaptcha = false
  }

  onError(log: any) {
    this.isValidateCaptcha = false
  }

  getToken(token : any){
    console.log(token)
  }
}
