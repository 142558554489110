import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { TableService } from "src/app/shared/services/table/table.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  loadingLocationPartnersSelector,
  locationPartnerCreatedSelector,
  locationPartnerDeletedByEmailSelector,
  locationPartnerFilterSelector,
  locationPartnersResultSelector,
  pricingByCompanyNameSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { initialState } from "src/app/store/locationPartner/location-partner.reducers";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { LocationPartnersFilterInterface } from "src/app/shared/models/location-partners/locationPartnersFilter.interface";
import { LocationPartnersResultInterface } from "src/app/shared/models/location-partners/locationPartnersResult.interface";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  PartnerStatus,
  PartnerStatusToShow,
  PartnerStatusInterface,
} from "src/app/shared/enum/partner-status";
import {
  InternetProvider,
  InternetProviderInterface,
  InternetProvidersToShow,
} from "src/app/shared/enum/internet-providers";
import {
  PaymentFrequency,
  PaymentFrequencyEnumMapping,
  PaymentFrequencyInterface,
} from "src/app/shared/enum/payment-frequency";
import { DateViewType } from "@danielmoncada/angular-datetime-picker";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  PricingMethod,
  PricingMethodEnumMapping,
  PricingMethodInterface,
} from "src/app/shared/enum/pricing-method";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ToastrService } from "ngx-toastr";
import { allTagNamesSelector } from "src/app/store/tag/tag.selectors";
import {
  allSubCategoryNamesSelector,
  selectedSubCategoriesSelector,
} from "src/app/store/subCategory/subCategory.selectors";
import { invokeAllSubGategoryNames } from "src/app/store/subCategory/subCategory.actions";
import { invokeAllTagNames } from "src/app/store/tag/tag.actions";
import { UpdateComponent } from "src/app/shared/components/drob-zone-options/modal/update/update.component";
import { updateFieldsSelector } from "src/app/store/settings/settings.selectors";
import { OptionActionInterface } from "src/app/shared/models/option-action.interface";
import {
  saveUpdateFields,
  updateFields,
} from "src/app/store/settings/settings.actions";
import { OwlOptions } from "ngx-owl-carousel-o";
import {
  invokeLocationPartnerFilter,
  invokeLocationPartners,
} from "src/app/store/locationPartner/actions/get-locations.actions";
import { TranslateService } from "@ngx-translate/core";
import { PricingInterface } from "src/app/shared/models/location-partners/pricing";
import { ExportComponent } from "src/app/shared/components/export/export.component";
import { saveAs } from "file-saver";
import {
  ActionInterface,
  ActionReturned,
  createLocation,
  locationActions,
  OptionActionEnum,
  updateLocation,
} from "src/app/shared/data/actions-data";
import { saveSelectedRegionSelector } from "src/app/store/region/region.selectors";
import { saveSelectedZonesSelector } from "src/app/store/zone/zone.selectors";
import { saveSelectedRegion } from "src/app/store/region/region.actions";
import { saveSelectedZone } from "src/app/store/zone/zone.actions";
import { DeleteComponent } from "src/app/shared/components/drob-zone-options/modal/delete/delete.component";
import { DetailsLpComponent } from "src/app/shared/components/details-modal/details-lp/details-lp.component";
import { TypeCards } from "src/app/shared/enum/typeCards";
import {
  FilterInterface,
  locationFilter,
} from "src/app/shared/data/filter-data";
import { PremiumAccessComponent } from "./premium-access/premium-access.component";
import {
  deleteLocationPartnerSuccess,
  selectedLPs,
  createLocationPartnerSuccess,
  invokeDeleteLocationPartner,
} from "src/app/store/locationPartner/location-partner.action";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
@Component({
  selector: "app-location-partners",
  templateUrl: "./location-partners.component.html",
  styleUrls: ["./location-partners.component.scss"],
})
export class LocationPartnersComponent extends BaseComponent implements OnInit {
  deletedIsLoading: any;

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  @ViewChild("value") mySelect: ElementRef;

  demographic: IColumn[] = [
    {
      key: "region",
      label: "admin.locations.repository.region",
      sorter: true,
    },
    {
      key: "zone",
      label: "admin.locations.repository.zone",
      sorter: true,
    },
    {
      key: "category",
      label: "admin.locations.repository.category",
      sorter: true,
    },
    {
      key: "subCategory",
      label: "admin.locations.repository.subCategory",
      sorter: true,
    },
    {
      key: "tags",
      label: "admin.locations.repository.tags",
      sorter: true,
    },
  ];
  info: IColumn[] = [
    {
      key: "option",
      label: "Option",
      sorter: true,
    },
    {
      key: "endPurshaseDate",
      label: "admin.locations.repository.endPhase",
      sorter: true,
    },
  ];
  coordinates: IColumn[] = [
    {
      key: "name",
      label: "admin.locations.repository.responsable",
      sorter: true,
    },
    {
      key: "email",
      label: "admin.locations.repository.email",
      sorter: true,
    },
    {
      key: "phone",
      label: "admin.locations.repository.phone",
      sorter: true,
    },
    {
      key: "address",
      label: "admin.locations.repository.address",
      sorter: true,
    },
  ];
  discount: IColumn[] = [
    {
      key: "pricingMethode",
      label: "admin.locations.repository.pricingMethod",
      sorter: true,
    },
    {
      key: "price",
      label: "admin.locations.repository.price",
      sorter: true,
    },
    {
      key: "paymentFrequency",
      label: "admin.locations.repository.priceFrequency",
      sorter: true,
    },
  ];
  formFilter: FormGroup;
  isLoading$: Observable<boolean | null>;
  orderHeader: String = "";
  isFiltring: boolean | null;
  pageSize: number = 10;
  pageNumber: number = 1;

  locationPartnersResult$: Observable<LocationPartnersResultInterface | null>;
  locationPartnersResult: LocationPartnersResultInterface;

  locationCreated$: Observable<SuccessDto | null>;
  closeModal: Subject<boolean> = new Subject<boolean>();

  tags$: Observable<string[] | null>;

  subcategories$: Observable<string[] | null>;

  locationPartnerFilter$: Observable<LocationPartnersFilterInterface>;
  locationPartnerFilter: LocationPartnersFilterInterface = {
    ...initialState.locationPartnerFilter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };

  deleted$: Observable<SuccessDto | null>;

  partnerStatus: PartnerStatusInterface[];
  internetProvider: InternetProviderInterface[];
  paymentFrequency: PaymentFrequencyInterface[];
  pricingMethod: PricingMethodInterface[];
  value2: number = 100;
  maxvalue: number = 70;
  options: ActionInterface[] = locationActions;
  updateLocation: ActionInterface[] = updateLocation;
  createLocation: ActionInterface[] = createLocation;
  filter: FilterInterface = locationFilter;
  productionDateRange: any;
  years: DateViewType;
  selectedCityIds2: any;
  Placeholder: any;
  locationPartner: LocationPartnerInterface;
  update: OptionActionInterface = {
    name: [],
    icon: [],
    optionSelected: null,
    title: "",
  };
  owlcarousel1ptions: OwlOptions;
  zones: string[] = [];
  updateFields$: Observable<OptionActionInterface | null>;

  pricing$: Observable<PricingInterface | null>;
  pricing: PricingInterface = {
    price: 0,
    pricingMethod: PricingMethod.NONE,
    paymentFrequency: PaymentFrequency.NONE,
    pilotPhaseEndDate: new Date(),
  };
  loading: boolean;
  columnVisibility: boolean[] = [true, true, true, true];
  colspan: number[] = [5, 3, 4, 3];

  selectedRegion: string[] = [];
  selectedRegion$: Observable<string[] | null>;
  selectedZones$: Observable<string[] | null>;
  selectedZones: string[] = [];

  show: boolean[] = [false];

  selectedSubCategories$: Observable<string[] | null>;
  selectedSubCategories: string[];
  fields: OptionActionInterface = {
    name: [],
    icon: [],
    optionSelected: null,
    title: "",
  };
  optionSelected: PartnerStatus | null;
  pricingMethode = PricingMethod;
  paymentFrequencyToShow = PaymentFrequency;
  constructor(
    private store: Store<AppStateInterface>,
    public service: TableService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    public badgeService: BadgeService,
    private modalService: NgbModal,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.tags$ = this.store
      .pipe(select(allTagNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.subcategories$ = this.store
      .pipe(select(allSubCategoryNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.locationPartnerFilter$ = this.store
      .pipe(select(locationPartnerFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.locationPartnersResult$ = this.store
      .pipe(select(locationPartnersResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.isLoading$ = this.store
      .pipe(select(loadingLocationPartnersSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.deleted$ = this.store
      .pipe(select(locationPartnerDeletedByEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.locationCreated$ = this.store
      .pipe(select(locationPartnerCreatedSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.updateFields$ = this.store
      .pipe(select(updateFieldsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.pricing$ = this.store
      .pipe(select(pricingByCompanyNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.selectedRegion$ = this.store
      .pipe(select(saveSelectedRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.selectedZones$ = this.store
      .pipe(select(saveSelectedZonesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.selectedSubCategories$ = this.store
      .pipe(select(selectedSubCategoriesSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.formFilter = this.fb.group({
      companyName: [""],
    });
  }

  ngOnInit(): void {
    this.filter.name.map(
      (e) =>
        (e.data = Object.keys(PartnerStatus)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              PartnerStatusToShow[key as keyof typeof PartnerStatus]
          ))
    );
    this.store.dispatch(deleteLocationPartnerSuccess({ successMessage: null }));
    this.store.dispatch(saveSelectedRegion({ selectedRegion: null }));
    this.store.dispatch(saveSelectedZone({ selectedZones: null }));
    this.store.dispatch(selectedLPs({ selectedLps: null }));
    this.selectedSubCategories$.subscribe((result) => {
      if (result) {
        this.selectedSubCategories = result;
        this.saveOptionFilter(this.optionSelected);
      }
    });
    this.selectedZones$.subscribe((data) => {
      if (data) {
        this.selectedZones = data;
        this.saveOptionFilter(this.optionSelected);
      }
    });
    this.selectedRegion$.subscribe((data) => {
      if (data) {
        this.selectedRegion = data;
        this.saveOptionFilter(this.optionSelected);
      }
    });

    this.pricing$.subscribe((pricing: PricingInterface | null) => {
      if (pricing) {
        this.pricing = pricing;
      }
    });
    this.isLoading$.subscribe((data) => {});
    this.store.dispatch(invokeAllSubGategoryNames());
    this.store.dispatch(invokeAllTagNames());

    this.locationPartnerFilter$.subscribe(
      (result: LocationPartnersFilterInterface) => {
        if (result != null) {
          this.locationPartnerFilter = result;
          this.pageNumber = result.pager.pageNumber;
          this.store.dispatch(invokeLocationPartners());
        } else {
          this.store.dispatch(
            invokeLocationPartnerFilter({
              locationPartnerFilter: {
                ...this.locationPartnerFilter,
                pager: {
                  pageSize: this.pageSize,
                  pageNumber: this.pageNumber,
                },
              },
            })
          );
          this.saveHistory.saveUserHistory(
            UserHistoryActionsEnum.GET,
            UserHistoryServicesEnum.LIST_LOCATIONS
          );
        }
      }
    );
    this.locationPartnersResult$.subscribe(
      (result: LocationPartnersResultInterface | null) => {
        if (result != null) { 
          this.locationPartnersResult = result;
          result.locationPartners.map((value) => {
            this.zones.push(value.zoneName);
          });
        }
      }
    );
    this.locationCreated$.subscribe((data) => {
      if (data) {
        this.toastr.success(
          this.translate.instant("response.success." + data.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(invokeLocationPartners());
        this.modalService.dismissAll();
        this.closeModal.next(true);
        this.store.dispatch(
          createLocationPartnerSuccess({ successMessage: null })
        );
      }
    });

    this.pricingMethod = Object.keys(PricingMethod)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: PricingMethod[key as keyof typeof PricingMethod],
        value: PricingMethodEnumMapping[key as keyof typeof PricingMethod],
      }));

    this.partnerStatus = Object.keys(PartnerStatus)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: PartnerStatus[key as keyof typeof PartnerStatus],
        value: PartnerStatusToShow[key as keyof typeof PartnerStatus],
      }));

    this.internetProvider = Object.keys(InternetProvider)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: InternetProvider[key as keyof typeof InternetProvider],
        value: InternetProvidersToShow[key as keyof typeof InternetProvider],
      }));

    this.paymentFrequency = Object.keys(PaymentFrequency)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: PaymentFrequency[key as keyof typeof PaymentFrequency],
        value:
          PaymentFrequencyEnumMapping[key as keyof typeof PaymentFrequency],
      }));
  }
  getLocationPartnerPricingMethodValue(
    id: PricingMethod
  ): PricingMethod | undefined {
    return this.pricingMethod.find((item) => item.id == id)?.id;
  }
  removeFilter() {
    this.formFilter.reset({
      companyName: "",
    });
    this.isFiltring = false;
    this.store.dispatch(
      invokeLocationPartnerFilter({
        locationPartnerFilter: {
          ...initialState.locationPartnerFilter,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  get changePageSize() {
    return this.locationPartnerFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }

  //Pagination Config
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeLocationPartnerFilter({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          pager: {
            ...this.locationPartnerFilter.pager,
            pageNumber: event,
          },
        },
      })
    );
  }

  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeLocationPartnerFilter({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          pager: {
            ...this.locationPartnerFilter.pager,
            pageSize: pageSize,
          },
        },
      })
    );
  }

  columnFilterChange(event: any) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeLocationPartnerFilter({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          sortName: event.column,
        },
      })
    );
  }
  //sort table
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }
    this.store.dispatch(
      invokeLocationPartnerFilter({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          sortName: column,
          isAscending: sort,
        },
      })
    );
  }

  onClickDeleteLocationPartner() {
    this.store.dispatch(
      invokeDeleteLocationPartner({
        email: this.locationPartner.email,
      })
    );
  }
  onClickShowDetails(
    showRouterDetailsModal: any,
    locationPartner: LocationPartnerInterface
  ) {
    const modalRef = this.modalService.open(showRouterDetailsModal);
    this.locationPartner = locationPartner;
  }
  //Modals
  showDeleteModal(deleteModal: any, locationPartner: LocationPartnerInterface) {
    const modalRef = this.modalService.open(deleteModal);
    this.locationPartner = locationPartner;
  }
  saveOptionFilter(option: string | null) {
    this.store.dispatch(invokeApiDistroyed());
    let filter: PartnerStatus | null = null;
    const KeyFound = Object.keys(PartnerStatusToShow).find(
      (key) => PartnerStatusToShow[key as PartnerStatus] === option
    );
    switch (KeyFound) {
      case PartnerStatus.INTERNET_PROVIDER_PAYMENT:
        filter = PartnerStatus.INTERNET_PROVIDER_PAYMENT;
        break;
      case PartnerStatus.REVENUE_SHARING:
        filter = PartnerStatus.REVENUE_SHARING;
        break;
      default:
        filter = null;
    }
    this.optionSelected = filter;
    this.store.dispatch(
      invokeLocationPartnerFilter({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          partnerStatus: filter,
          regions: this.selectedRegion,
          zoneNames: this.selectedZones,
          subcategories: this.selectedSubCategories,
        },
      })
    );
  }
  saveOption(action: ActionReturned, location: LocationPartnerInterface) {
    switch (action.option.type) {
      case OptionActionEnum.ADMIN_LOCATION_DELETE: {
        const modal = this.modalService.open(DeleteComponent, {
          centered: true,
        });
        modal.componentInstance.name = "pop-up.lp.title";
        modal.componentInstance.reference = location.email;
        modal.componentInstance.title = "LPOptions.delete";
        modal.componentInstance.type = OptionActionEnum.ADMIN_LOCATION_DELETE;
        break;
      }
      case OptionActionEnum.ADMIN_LOCATION_DETAILS: {
        const modal = this.modalService.open(DetailsLpComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.locationPartner = location;
        modal.componentInstance.title = "LPOptions.details";
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.LOCATION_DETAILS
        );
        break;
      }
      case OptionActionEnum.ADMIN_LOCATION_UPDATE: {
        this.store.dispatch(saveUpdateFields({ options: this.updateLocation }));
        const modal = this.modalService.open(UpdateComponent, {
          centered: true,
          size: "xl",
        });
        modal.componentInstance.type = TypeCards.ADMIN_LOCATION_UPDATE;
        modal.componentInstance.isCol4 = false;
        modal.componentInstance.title = "LPOptions.update";
        modal.componentInstance.email = location.email;
        modal.componentInstance.locationPartner = location;
        break;
      }
      case OptionActionEnum.ADMIN_LOCATION_PREMIUM_ACCESS: {
        const modal = this.modalService.open(PremiumAccessComponent, {
          centered: true,
        });
        modal.componentInstance.locationPartner = location;
        modal.componentInstance.title = "LPOptions.grantPremium";
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.PATCH,
          UserHistoryServicesEnum.LOCATION_PREMIUM_ACCESS
        );
        break;
      }
    }
  }
  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeLocationPartnerFilter({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          companyName: this.formFilter.get("companyName")?.value,
        },
      })
    );
  }
  onClickShowModal() {
    this.store.dispatch(saveUpdateFields({ options: this.createLocation }));
    this.store.dispatch(updateFields({ fields: this.update }));
    const modalRef = this.modalService.open(UpdateComponent, {
      centered: true,
      size: "xl",
    });
    modalRef.componentInstance.type = TypeCards.ADMIN_LOCATION_CREATE;
    modalRef.componentInstance.isCol4 = true;
    modalRef.componentInstance.title = "addLP.title";
  }
  showModal() {
    const modalRef = this.modalService.open(ExportComponent, {
      centered: true,
    });
  }
  exportCSV(exportModal: any) {
    const modalRef = this.modalService.open(exportModal, {
      centered: true,
    });
  }

  exportToCsv() {
    this.loading = true;
    const csvData = this.convertToCsv();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `${this.translate.instant("exportLP.title")}.csv`);
  }
  convertToCsv() {
    const csvRows: string[] = [];
    let headers;
    let subHeaders;
    headers = [
      "LP",
      this.translate.instant("admin.locations.repository.demographicData"),
      " ",
      " ",
      " ",
      " ",
      this.translate.instant("admin.locations.repository.informations"),
      " ",
      this.translate.instant("admin.locations.repository.coordinates"),
      " ",
      " ",
      this.translate.instant("admin.locations.repository.discount"),
      " ",
      " ",
    ];
    subHeaders = [
      " ",
      this.translate.instant("admin.locations.repository.region"),
      this.translate.instant("admin.locations.repository.zone"),
      this.translate.instant("admin.locations.repository.category"),
      this.translate.instant("admin.locations.repository.subCategory"),
      this.translate.instant("admin.locations.repository.tags"),
      "Option",
      this.translate.instant("admin.locations.repository.endPhase"),
      this.translate.instant("admin.locations.repository.responsable"),
      this.translate.instant("admin.locations.repository.phone"),
      this.translate.instant("admin.locations.repository.address"),
      this.translate.instant("admin.locations.repository.pricingMethod"),
      this.translate.instant("admin.locations.repository.price"),
      this.translate.instant("admin.locations.repository.priceFrequency"),
    ];
    this.locationPartnersResult.locationPartners.forEach((item) => {
      const row = [
        item.companyName,
        item.region,
        item.zoneName,
        ,
        item.subCategories,
        item.tags,
        ,
        ,
        item.pilotPhaseEndDate,
        item.firstName,
        item.phone,
        item.companyAddress,
        item.pricingMethod,
        item.price,
      ];
      csvRows.push(row.join(","));
    });
    this.modalService.dismissAll();
    return [headers, subHeaders, ...csvRows].join("\n");
  }
  toggleColumn(columnNumber: number) {
    this.columnVisibility[columnNumber] = !this.columnVisibility[columnNumber];
  }
  openMenu(j: number) {
    this.show[j] = !this.show[j];
  }
}
