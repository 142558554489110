<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 box-col-12">
          <div class="title">
            <h4>{{ "newCampaign.title" | translate }}</h4>
          </div>

          <div class="row d-flex justify-content-center">
            <ng-container *ngFor="let card of campaignsData">
              <div [class]="card.class">
                <div>
                  <a
                    (click)="
                      changeGif(card.gifName);
                      OnSelectCard(card.objective, card.disabled)
                    "
                    style="cursor: pointer"
                    ><!-- (click)="" -->
                    <div
                      class="card py-4 card-hover"
                      [class]="{
                        disabled: card.disabled,
                        'ribbon-wrapper-bottom': card.ribbon,
                        'border-active': card.objective == objectiveName
                      }"
                    >
                      <div
                        *ngIf="card.ribbon"
                        class="ribbon ribbon-{{
                          card.ribbon.color
                        }} ribbon-clip-right ribbon-right"
                      >
                        {{ card.ribbon.text | translate }}
                      </div>
                      <img class="img" [src]="card.logo" alt="prospect" />
                      <p style="text-align: center">
                        {{ card.title | translate }}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
          <!--next button-->
          <div class="d-flex justify-content-center">
            <a
              *ngIf="!isDisabled; else disabled"
              [routerLink]="[
                '/',
                'dashboard',
                'advertiser',
                'advertising-campaigns',
                'create-ad',
                HashedObjectiveEnum[this.objectiveName]
              ]"
              class="btn btn-primary text-white"
              >{{ "buttons.launchCampaign" | translate }}</a
            >
            <ng-template #disabled>
              <a class="btn btn-primary text-white">{{
                "buttons.launchCampaign" | translate
              }}</a>
            </ng-template>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 box-col-12">
          <div style="margin-top: 30px">
            <h4>{{ "newCampaign.preview" | translate }}</h4>
            <hr />
          </div>
          <div class="btn-showcase">
            <app-ad-video-preview-statistics
              [muteExist]="false"
              class="phone-preview"
              style="height: 70%; position: sticky"
              [isUrl]="true"
              [url]="selectedGif"
              [height]="430"
            ></app-ad-video-preview-statistics>
            <div class="mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
