<div class="row align-items-center">
  <div class="col-sm-12 col-md-6 col-lg-8 col-xl-8 col-xxl-8">
    <div class="card">
      <div class="card-body p-b-0">
        <div class="d-flex align-items-baseline">
          <i class="icofont icofont-abacus-alt" style="font-size: 18px"></i>
          <h4 class="ps-2 m-r-5">
            {{ "newCampaign.step2.title" | translate }}
          </h4>
          <!--<i class="icofont icofont-info-circle" style="color: darkgrey"></i>-->
        </div>
      </div>
      <div class="card-body">
        <h6>{{ "newCampaign.step2.specification.title" | translate }}</h6>
        <label class="col-form-label label">{{
          "newCampaign.step2.specification.description" | translate
        }}</label>
        <div class="m-b-10 m-l-30 color-darkGray">
          <div class="m-l-5">
            <ul>
              <li class="font-a">
                <b>{{
                  "newCampaign.step2.specification.line1.title" | translate
                }}</b
                >{{
                  "newCampaign.step2.specification.line1.description"
                    | translate
                }}
              </li>
              <li class="font-a">
                <b>{{
                  "newCampaign.step2.specification.line2.title" | translate
                }}</b
                >{{
                  "newCampaign.step2.specification.line2.description"
                    | translate
                }}
              </li>
              <li class="font-a">
                <b>{{
                  "newCampaign.step2.specification.line3.title" | translate
                }}</b
                >{{
                  "newCampaign.step2.specification.line3.description"
                    | translate
                }}
              </li>
              <li class="font-a">
                <b>{{
                  "newCampaign.step2.specification.line4.title" | translate
                }}</b
                >{{
                  "newCampaign.step2.specification.line4.description"
                    | translate
                }}
              </li>
              <li class="font-a">
                <b>{{
                  "newCampaign.step2.specification.line5.title" | translate
                }}</b
                >{{
                  "newCampaign.step2.specification.line5.description"
                    | translate
                }}
              </li>
            </ul>
          </div>
        </div>
        <label class="col-form-label label p-t-0">
          {{
            "newCampaign.step2.specification.downloadGuide.part1" | translate
          }}
          <a
            type="button"
            style="color: #ff69b4"
            class="font-a"
            [href]="videoGuideLink"
            target="_blank"
            >{{
              "newCampaign.step2.specification.downloadGuide.part2" | translate
            }}</a
          >
        </label>
        <div class="m-b-10" *ngIf="showAlert">
          <div
            class="alert alert-danger outline alert-dismissible fade show"
            role="alert"
          >
            <app-feather-icon [icon]="'alert-triangle'"></app-feather-icon>
            <p>
              {{ "dropzoneFile.rulePart1" | translate
              }}<b>{{ "dropzoneFile.rulePart2" | translate }}5MB</b>!
            </p>
            <button
              class="btn-close"
              type="button"
              aria-label="Close"
              (click)="showAlert = false"
            ></button>
          </div>
        </div>
        <div class="m-b-10" *ngIf="showProgress">
          <ngb-progressbar [value]="uploadProgress"
            >{{ uploadProgress }}%</ngb-progressbar
          >
        </div>
        <ngx-dropzone
          style="position: sticky"
          [multiple]="false"
          (change)="onSelect($event.addedFiles)"
          accept="video/*"
        >
          <ngx-dropzone-label>
            <div>
              <div class="dz-message needsclick">
                <i class="icon-cloud-up"></i>
                <p style="color: black">
                  {{ "dropzoneFile.title" | translate }}
                </p>
                <p class="dz-message needsclick" style="color: black">
                  {{ "dropzoneFile.rulePart1" | translate }}
                  <strong [ngClass]="{ 'txt-danger': showError }"
                    >{{ "dropzoneFile.rulePart2" | translate }} 5MB</strong
                  >
                </p>
              </div>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-preview
            *ngFor="let f of files"
            [file]="f"
            [removable]="true"
            (removed)="onRemove(f)"
          >
            <ngx-dropzone-label *ngIf="f" style="align-self: flex-end; margin: 0;">
              <div class="ligne-top"></div>
              <div class="new-dropdown-label">
                {{ f.name.split(".")[0] | castFileName }} .{{ f.type.split("/")[1] }}
              </div>
            </ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>

    <div
      class="card"
      *ngIf="
        objectiveName == appPrpomotionObjective ||
        objectiveName == webTraficObjective
      "
    >
      <div class="card-body">
        <div *ngIf="objectiveName == appPrpomotionObjective">
          <div class="row">
            <div class="col-12">
              <h3 class="mt-4 f-w-300">
                {{ "newCampaign.step2.links" | translate }}
              </h3>
            </div>
            <div class="col-12">
              <h5>{{ "newCampaign.step2.mobileInfos" | translate }}</h5>
            </div>
            <div class="col-12">
              <div class="mb-3 row">
                <label class="col-sm-2 col-form-label"
                  ><img
                    height="40"
                    src="assets/identity/new-campaign/play-store.png"
                    alt="playStore"
                /></label>
                <div class="col-sm-10 align-items-center">
                  <input
                    class="form-control m-t-5"
                    type="text"
                    required=""
                    placeholder="Link here"
                  />
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3 row">
                <label class="col-sm-2 col-form-label"
                  ><img
                    height="40"
                    src="assets/identity/new-campaign/app-gallery.png"
                    alt="huawei"
                /></label>
                <div class="col-sm-10">
                  <input
                    class="form-control m-t-5"
                    type="text"
                    required=""
                    placeholder="Link here"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3 row">
                <label class="col-sm-2 col-form-label"
                  ><img
                    height="40"
                    src="assets/identity/new-campaign/app-store.png"
                    alt="A"
                /></label>
                <div class="col-sm-10">
                  <input
                    class="form-control m-t-5"
                    type="text"
                    required=""
                    placeholder="Link here"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <form class="needs-validation" novalidate>
            <div class="d-flex align-items-baseline m-b-10">
              <i class="icofont icofont-web" style="font-size: 20px"></i>
              <h4 class="mt-0 ps-2 m-r-5">
                {{ "newCampaign.step2.webSite" | translate }} *
              </h4>
            </div>
            <input
              class="form-control m-b-5"
              type="text"
              required=""
              [ngClass]="{ 'is-invalid': !WebLinkFormControl.valid }"
              [formControl]="WebLinkFormControl"
            />
            <span *ngIf="!WebLinkFormControl.valid" class="text-danger">{{
              "newCampaign.step2.linkNotValid" | translate
            }}</span>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
    <app-ad-video-preview class="phone-preview"></app-ad-video-preview>
  </div>
</div>
