<div class="video-body">
  <video
    #myVideo
    controls
    loop
    autoplay
    style="padding: 14px; border-radius: 40px; object-fit: fill"
    src="/assets/video/demo-ad.mp4"
    [width]="phone.width"
    [height]="imgHeight"
  ></video>
  <button *ngIf="!loadingVideo"
    class="muteButton"
    style="outline: none"
    type="button"
    style="padding-top: 10px; padding-right: 10px;"
    (click)="toggleMute()"
  >
    <div class="design-mute-button">
      <img
        #muteIcon
        [src]="loadIcon()"
        alt="unmuted-button"
      />
    </div>
  </button>
  <div *ngIf="loadingVideo" class="loading-video">
    <img
      style="padding: 14px"
      [width]="phone.width"
      [height]="phone.height"
      src="assets/gifs/loading.gif"
      alt="gif"
    />
  </div>
  <img src="assets/identity/iphone.png" #phone [height]="imgHeight" alt="img1" />
</div>
