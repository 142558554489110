<form [formGroup]="form">
  <div class="row">
    <div class="col-xxl-8 col-lg-8 box-m-b-5">
      <!--advertissing campaign-->
      <div class="card">
        <div class="card-header p-b-0">
          <div class="d-flex align-items-baseline">
            <i class="icofont icofont-bullhorn" style="font-size: 18px"></i>
            <h4 class="ps-2 m-0">
              {{ "campaignDetails.campaignName" | translate }}
            </h4>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-2">
            <label class="col-form-label label"
              >{{ "campaignDetails.campaignName" | translate }} *
            </label>
            <input
              required
              class="form-control"
              formControlName="title"
              type="text"
              [placeholder]="'placeholder.placeholder_campaign' | translate"
              [ngClass]="{
                'is-invalid':
                  !form.get('title')?.valid && form.get('title')?.touched
              }"
              name="campaignName"
            />
            <div
              class="invalid-feedback"
              *ngIf="!form.get('title')?.valid && form.get('title')?.touched"
            >
              {{ "erreurMsg.validTitle" | translate }}
              <ul style="padding-left: 10px">
                <li>
                  {{ "campaignDetails.campaignNameValidator1" | translate }}
                </li>
                <li>
                  {{ "campaignDetails.campaignNameValidator2" | translate }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--programming-->
      <img
        *ngIf="!adDetails.validators.title"
        src="assets/identity/new-campaign/programming-blur.png"
        alt="Card image"
        class="not-valid-img"
      />
      <ng-container *ngIf="adDetails.validators.title">
        <app-campaign-dates
          [startDate]="form.get('startingDate')?.value"
          [endDate]="form.get('endingDate')?.value"
          (datesSelected)="datesSelected($event)"
        ></app-campaign-dates>
      </ng-container>

      <img
        *ngIf="!adDetails.validators.title || !adDetails.validators.dates"
        src="assets/identity/new-campaign/choose-by-blur.png"
        alt="Card image"
        class="not-valid-img"
      />
      <!--choose by-->
      <app-campaign-locations></app-campaign-locations>
    </div>
    <!-- Calculate-->
    <div class="col-12 col-xxl-4 col-lg-4 box-col-6">
      <div
        class="m-r-15"
        style="position: fixed; justify-items: center; top: 24.8%"
      >
        <div class="card">
          <div class="card-body">
            <h4>{{ "newCampaign.step1.overview.title" | translate }}</h4>
            <hr />
            <div class="row">
              <div class="m-b-5">
                <p>
                  <span class="f-w-600">
                    {{ "campaignDetails.objective" | translate }}: </span
                  ><span class=" ">{{
                    "enumMapping.objectives." + objectiveName | translate
                  }}</span>
                </p>
              </div>
              <div class="m-b-5">
                <p>
                  <span class="f-w-600"
                    >{{
                      "newCampaign.step1.overview.duration" | translate
                    }}: </span
                  ><span
                    >{{
                      form.get("startingDate")?.value
                        | daysBetweenDates : form.get("endingDate")?.value
                    }}
                    {{ "info.days" | translate }}</span
                  >
                </p>
              </div>
              <div class="m-b-5">
                <p>
                  <span class="f-w-600">
                    {{ "campaignDetails.locations" | translate }}:
                  </span>
                  <span *ngIf="!showAllLocations; else showLocations">
                    {{ castString(selectedLocationsNames) | sliceString : 140 }}
                    <a
                      *ngIf="
                        selectedLocationsNames.length > 0 &&
                        selectedLocationsNames.toString().length > 140
                      "
                      type="button"
                      style="color: #ff69b4"
                      class="font-a"
                      (click)="showAllLocations = true"
                      >{{ "buttons.showMore" | translate }}</a
                    >
                  </span>
                  <ng-template #showLocations>
                    <span *ngFor="let lp of selectedLocationsNames">
                      {{ lp }},&ThickSpace;
                    </span>
                    <a
                      type="button"
                      style="color: #ff69b4"
                      class="font-a"
                      (click)="showAllLocations = false"
                      >{{ "buttons.showLess" | translate }}</a
                    >
                  </ng-template>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="card">
          <div class="card-body">
            <h4>{{ "newCampaign.step1.dailyResult.title" | translate }}</h4>
            <hr />
            <div class="row">
              <div class="m-b-5 d-flex align-items-baseline p-r-0">
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.dailyResult.estimatedCostPerDay"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500"
                  >{{ totalCostPerDay | toKValue }} TND HT</h5
                >
              </div>
              <div class="m-b-5 d-flex align-items-baseline p-r-0">
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.dailyResult.estimatedImpressionPerDay"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500">{{ impressionsPerDay | toKValue }}</h5>
              </div>
              <div class="m-b-5 d-flex align-items-baseline p-r-0" *ngIf="objectiveName == objectiveTypeEnum.WEB_SITE_TRAFFIC">
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.dailyResult.estimatedClicksPerDay"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500"
                  >{{ adDetails.clicksPerDay }}</h5
                >
              </div>
              <div class="m-b-5 d-flex align-items-baseline p-r-0" *ngIf="objectiveName == objectiveTypeEnum.GENERATION_DE_PROSPECTS">
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.dailyResult.estimatedLeadPerDay"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500"
                  >{{ adDetails.leadPerDay }}</h5
                >
              </div>
            </div>
          </div>
        </div>-->
        <div class="card">
          <div class="card-body">
            <h4>{{ "newCampaign.step1.totalResult.title" | translate }}</h4>
            <hr />
            <div class="row">
              <div class="m-b-5 d-flex align-items-baseline p-r-0">
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.totalResult.estimatedTotalCost"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500">{{ totalCost }} TND HT</h5>
              </div>
              <div class="m-b-5 d-flex align-items-baseline p-r-0">
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.totalResult.estimatedTotalImpression"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500 m-r-5">
                  {{ adDetails.totalImpressions }}
                </h5>
                <p>
                  {{
                    "newCampaign.step1.totalResult.indices.impression"
                      | translate
                  }}
                </p>
              </div>
              <div
                class="m-b-5 d-flex align-items-baseline p-r-0"
                *ngIf="objectiveName == objectiveTypeEnum.WEB_SITE_TRAFFIC"
              >
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.totalResult.estimatedTotalClicks"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500 m-r-5">{{ adDetails.totalClicks }}</h5>
                <p>
                  {{
                    "newCampaign.step1.totalResult.indices.clicks" | translate
                  }}
                </p>
              </div>
              <div
                class="m-b-5 d-flex align-items-baseline p-r-0"
                *ngIf="
                  objectiveName == objectiveTypeEnum.GENERATION_DE_PROSPECTS
                "
              >
                <label class="f-w-600 m-b-0 label">
                  {{
                    "newCampaign.step1.totalResult.estimatedTotalLead"
                      | translate
                  }}:
                </label>
                <h5 class="m-l-5 f-w-500 m-r-5">{{ adDetails.totalLead }}</h5>
                <p>
                  {{ "newCampaign.step1.totalResult.indices.lead" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
