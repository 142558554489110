import { createReducer, on } from "@ngrx/store";
import {
  invokeCreateUsersHistory,
  invokeUsersHistory,
  resultCreateUsersHistory,
  setUsersHistory,
  setUsersHistoryFilter,
} from "./userHistory.actions";
import { UserHistoryStateInterface } from "./userHistoryState.interface";

export const initialState: Readonly<UserHistoryStateInterface> = {
  filter: {
    emailOrNameOrCompanyName: null,
    endDate: null,
    startDate: null,
    sortName: null,
    isAscending: null,
    pager: {
      pageNumber: 1,
      pageSize: 15,
    },
    roleName : null
  },
  history: {
    count: 0,
    history: [],
    cachedAt: null,
  },
  loading: false,
  successMessage: null,
};

export const UserHistoryReducer = createReducer(
  initialState,
  on(setUsersHistoryFilter, (state, { filter: historyFilter }) => ({
    ...state,
    filter: historyFilter,
  })),
  on(invokeUsersHistory, (state) => ({
    ...state,
    loading: true,
  })),
  on(setUsersHistory, (state, { history }) => ({
    ...state,
    loading: false,
    history: history,
  })),

  on(invokeCreateUsersHistory, (state, { history }) => ({
    ...state,
  })),
  on(resultCreateUsersHistory, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  }))
);
