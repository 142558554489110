import { Component, EventEmitter, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { ActionInterface, ActionReturned, estimates_admin, OptionActionEnum } from "src/app/shared/data/actions-data";
import {
  estimatesFilter,
  FilterInterface,
} from "src/app/shared/data/filter-data";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import {
  EstimateStatusEnum,
  EstimateStatusEnumMapping,
} from "src/app/shared/enum/estimateStatus";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { AdsPublisherDetailsAndStatus } from "src/app/shared/models/advertising-campaigns/ads-publisher-details-and-status.interface";
import { Data } from "src/app/shared/models/iberis/commande/data";
import { ResultListOrdersFromIberis } from "src/app/shared/models/iberis/commande/resultListOrdersFromIberis";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { JwtService } from "src/app/shared/services/jwt.service";
import { resultStatusesByListAdsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeStatusesByListAds,
  resultStatusesByListAds,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeDownloadEstimate,
  invokeListOrdersFromIberis,
  invokeListOrdersFromIberisByStatus,
  resultListOrdersFromIberis,
} from "src/app/store/commande/commande.actions";
import {
  loadingEstimateSelector,
  resultDownloadEstimateSelector,
  resultListOrdersFromIberisSelector,
} from "src/app/store/commande/commande.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-estimates",
  templateUrl: "./estimates.component.html",
})
export class EstimatesComponent extends BaseComponent implements OnInit {
  ad: string;
  loading$: Observable<boolean | null>;
  columns: IColumn[] = [
    {
      key: "num",
      label: "admin.advertisers.commandNumber",
      sorter: true,
    },
    {
      key: "client",
      label: "admin.advertisers.responsable",
      sorter: true,
    },
    {
      key: "displayName",
      label: "admin.advertisers.companyName",
      sorter: true,
    },
    {
      key: "compaignName",
      label: "admin.advertisers.campaignName",
      sorter: true,
    },
    {
      key: "status",
      label: "admin.advertisers.status",
      sorter: true,
    },
    {
      key: "periode",
      label: "admin.advertisers.period",
      sorter: true,
    },
    {
      key: "totals",
      label: "admin.advertisers.total",
      sorter: true,
    },
  ];
  totalRecords: number;
  itemsPerPage: number = 10;
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  estimatesFromIberis$: Observable<ResultListOrdersFromIberis | null>;
  resultEstimatesFromIberis: Data[] = [];
  downloadEstimate$: Observable<any>;
  campaignsHashedIds: string[] = [];
  total: string[] = [];
  publisherAndStatus$: Observable<AdsPublisherDetailsAndStatus | null>;
  publisherAndStatus: AdsPublisherDetailsAndStatus = {
    firstName: [],
    lastName: [],
    companyName: [],
    status: [],
  };
  adsStatus: CampaignStatusEnum[] = [];
  isLoadingDownload: boolean;
  specificColumun: number;
  resultDownloadEstimate: any;
  a: any;
  blob: Blob;
  dueDate: Date = new Date();
  filterAds: FilterInterface = estimatesFilter;
  pdfUrl = new EventEmitter<SafeResourceUrl>();
  options: ActionInterface[] = estimates_admin;
  isDownloadEstimate : boolean
  estimateNumber : string
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private modalService : NgbModal,
    private sanitizer: DomSanitizer,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.estimatesFromIberis$ = this.store
      .pipe(select(resultListOrdersFromIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.downloadEstimate$ = this.store
      .pipe(select(resultDownloadEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.downloadEstimate$ = this.store
      .pipe(select(resultDownloadEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.publisherAndStatus$ = this.store
      .pipe(select(resultStatusesByListAdsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(
      resultListOrdersFromIberis({ resultListOrdersFromIberis: null })
    );
    this.filterAds.name.map((e) => {
      e.data = Object.keys(EstimateStatusEnum)
        .filter((value) => isNaN(Number(value)))
        .map(
          (key: string | any) =>
            EstimateStatusEnumMapping[key as keyof typeof EstimateStatusEnum]
        );
    });
    this.store.dispatch(
      resultStatusesByListAds({ publisherAndStatusDetails: null })
    );
    this.loading$.subscribe((data) => {});
    this.estimatesFromIberis$.subscribe((result) => {
      if (result != null) {
        this.resultEstimatesFromIberis = result.data.estimates.data;
        this.totalRecords = result.data.estimates.recordsTotal;
        result.data.estimates.data.map((e) => {
          if (e.object != null) {
            this.campaignsHashedIds = [...this.campaignsHashedIds, e.notes];
          }
        });
        this.store.dispatch(invokeStatusesByListAds({ campaignsHashedIds: this.campaignsHashedIds }));
        this.campaignsHashedIds = [];
      } else {
        this.store.dispatch(
          invokeListOrdersFromIberis({
            params: this.params,
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.GET,
          UserHistoryServicesEnum.LIST_ESTIMATES
        );
      }
    });
    this.publisherAndStatus$.subscribe((result) => {
      if (result) {
        this.publisherAndStatus = result;
        this.adsStatus = result.status;
      }
    });
    this.downloadEstimate$.subscribe((result) => {
      if (result) {
        this.resultDownloadEstimate = result;
        this.blob = this.resultDownloadEstimate.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        if (this.isDownloadEstimate) {
          const downloadLink = document.createElement("a");
          downloadLink.href = pdf;
          //TOFIX : ${invoice_Number}
          downloadLink.download = `${this.estimateNumber}.pdf`;
          downloadLink.click();
          URL.revokeObjectURL(pdf);
          this.isLoadingDownload = false;
          this.isDownloadEstimate = false
        } else {
          this.pdfUrl.emit(this.sanitizer.bypassSecurityTrustResourceUrl(pdf));
        }
      }
    });
  }
  searchTerm(ad: string) {}
  changePage(event: number) {
    let start: number = 0;
    if (event > 1) {
      start = this.itemsPerPage * (event - 1);
    } else start = 0;
    this.store.dispatch(
      resultStatusesByListAds({ publisherAndStatusDetails: null })
    );
    this.store.dispatch(
      invokeListOrdersFromIberis({
        params: { ...this.params },
        start: start,
        length: this.itemsPerPage,
        draw: this.itemsPerPage,
      })
    );
  }
  down(hashed: string, orderNumber: string | null, i: number) {
    this.specificColumun = i;
    this.isLoadingDownload = true;
    this.store.dispatch(
      invokeDownloadEstimate({ params: this.params, estimateId: hashed })
    );
  }
  filterByStatus(option: EstimateStatusEnum) {
    this.campaignsHashedIds = [];
    const KeyFound = Object.keys(EstimateStatusEnum).find(
      (key) => EstimateStatusEnumMapping[key as EstimateStatusEnum] === option
    );
    switch (KeyFound) {
      case EstimateStatusEnum.PAID:
        this.store.dispatch(
          invokeListOrdersFromIberisByStatus({
            params: { ...this.params },
            status: 2,
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
        break;
      case EstimateStatusEnum.UNPAID:
        this.store.dispatch(
          invokeListOrdersFromIberisByStatus({
            params: { ...this.params },
            status: 1,
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
        break;
      case undefined: {
        this.store.dispatch(
          invokeListOrdersFromIberis({
            params: { ...this.params },
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
        break;
      }
    }
  }

  saveOption(action: ActionReturned, estimateId: string, estimate_Number: string, i : number, showModal:any) {
    switch (action.option.type) {
      case OptionActionEnum.ADMIN_ESTIMATE_SHOW: {
        this.isDownloadEstimate = false;
        this.store.dispatch(
          invokeDownloadEstimate({ params: this.params, estimateId: estimateId })
        );
        const modalRef = this.modalService.open(showModal, {
          centered: true,
          size : 'xl'
        });
        break;
      }
      case OptionActionEnum.ADMIN_ESTIMATE_UPDATE: {
        this.isDownloadEstimate = true;
      this.down(estimateId, estimate_Number, i)
      this.estimateNumber = estimate_Number
        break;
      }
    }
  }
}
