<div class="card">
  <div class="card-header p-b-0">
    <div class="d-flex align-items-baseline">
      <i class="icofont icofont-calendar" style="font-size: 18px"></i>
      <h4 class="ps-2 m-b-0">
        {{ "newCampaign.step1.programming" | translate }}
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div class="mb-3">
      <div>
        <div class="digits">
          <div class="row">
            <div class="col-6 mb-1">
              <label class="col-form-label label" for="Start Date">
                {{ "campaignDetails.startDate" | translate }} *
              </label>
              <div class="input-group">
                <div
                  class="input-group date"
                  id="dt-date"
                  data-target-input="nearest"
                >
                  <input
                    class="form-control datetimepicker-input digits"
                    type="text"
                    [owlDateTime]="dt"
                    [(ngModel)]="startDate"
                    [max]="endDate"
                    [min]="today"
                    readonly
                    placeholder="yyyy-mm-dd"
                  />
                  <div
                    class="input-group-text bg-primary"
                    data-target="#dt-date"
                    [owlDateTimeTrigger]="dt"
                  >
                    <i
                      class="icofont icofont-ui-calendar"
                      style="color: white; font-size: 12px"
                    ></i>
                  </div>
                </div>
                <owl-date-time
                  (dateSelected)="dateChanges($event, 'startDate')"
                  [pickerType]="'calendar'"
                  #dt
                ></owl-date-time>
              </div>
            </div>
            <div class="col-6 mb-1">
              <label class="col-form-label label"
                >{{ "campaignDetails.endDate" | translate }} *
              </label>
              <div class="input-group">
                <div
                  class="input-group date"
                  id="dt-date"
                  data-target-input="nearest"
                >
                  <input
                    class="form-control datetimepicker-input digits"
                    type="text"
                    [owlDateTime]="dt1"
                    [value]="endDate | localizedDate"
                    readonly
                    [min]="startDate"
                    [(ngModel)]="endDate"
                    placeholder="yyyy-mm-dd"
                  />
                  <div
                    class="input-group-text bg-primary"
                    data-target="#dt-date"
                    [owlDateTimeTrigger]="dt1"
                  >
                    <i
                      class="icofont icofont-ui-calendar"
                      style="color: white; font-size: 12px"
                    ></i>
                  </div>
                </div>
                <owl-date-time
                  (dateSelected)="dateChanges($event, 'endDate')"
                  [pickerType]="'calendar'"
                  #dt1
                ></owl-date-time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
