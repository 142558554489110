<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: left; margin-bottom: 15px" *ngIf="!isUpdate">
    {{ "addRouter.step1.title" | translate }}
  </h5>
  <div class="form-group">
    <label class="label">{{ "addRouter.step1.userName" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="userName"
        class="form-control"
        type="text"
        required=""
        [placeholder]="'addRouter.step1.placeholderUserName' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('userName')?.valid && form.get('userName')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('userName')?.valid && form.get('userName')?.touched"
      >
        {{ "validation.userName" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "addRouter.step1.password" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="password"
        class="form-control"
        [type]="show ? 'text' : 'password'"
        required=""
        placeholder="*********"
        [ngClass]="{
          'is-invalid':
            !form.get('password')?.valid && form.get('password')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('password')?.valid && form.get('password')?.touched"
      >
        {{ "validation.password" | translate }}
      </div>
      <div class="show-hide" (click)="showPassword()" *ngIf="!show">
        <span> {{ "password.show" | translate }} </span>
      </div>
      <div class="show-hide" (click)="showPassword()" *ngIf="show">
        <span>{{ "password.cash" | translate }}</span>
      </div>
    </div>
  </div>
</form>
