<div class="modal-header">
  <div>
    <h4 class="modal-title" id="exampleModalLabel" *ngIf="!sendMail">
      {{ "admin.locations.repository.update" | translate }}
    </h4>
    <h4 class="modal-title" id="exampleModalLabel" *ngIf="sendMail">
      {{ "admin.locations.repository.export" | translate }}
    </h4>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <form novalidate [formGroup]="form">
    <div class="form-group mb-3">
      <label class="label">{{
        "admin.locations.repository.collectionStartDate" | translate
      }} *</label>
      <div class="input-group">
        <div class="input-group date" id="dt-date" data-target-input="nearest">
          <input
            class="form-control datetimepicker-input digits"
            type="text"
            [owlDateTime]="dt"
            [max]="endDate"
            (dateChange)="onChange()"
            [(ngModel)]="startDate"
            formControlName="startDate"
            placeholder="yyyy-mm-dd"
          />
          <div
            class="input-group-text bg-primary"
            data-target="#dt-date"
            [owlDateTimeTrigger]="dt"
          >
            <i
              class="icofont icofont-ui-calendar"
              style="color: white; font-size: 12px"
            ></i>
          </div>
        </div>
        <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
      </div>
    </div>
    <div class="form-group mb-3">
      <label class="label">{{
        "admin.locations.repository.collectionEndDate" | translate
      }} *</label>
      <div class="input-group">
        <div class="input-group date" id="dt-date" data-target-input="nearest">
          <input
            class="form-control datetimepicker-input digits"
            type="text"
            [owlDateTime]="dt1"
            [min]="startDate"
            [max]="endDate"
            (dateChange)="onChange()"
            readonly
            formControlName="endDate"
            placeholder="yyyy-mm-dd"
          />
          <div
            class="input-group-text bg-primary"
            data-target="#dt-date"
            [owlDateTimeTrigger]="dt1"
          >
            <i
              class="icofont icofont-ui-calendar"
              style="color: white; font-size: 12px"
            ></i>
          </div>
        </div>
        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <label class="label" style="color: black">{{
        "admin.locations.repository.locations" | translate
      }} *</label>
      <a type="button" (click)="openLocations()" class="color-primary">{{
        "admin.locations.repository.addLocations" | translate
      }}</a>
    </div>
    <div *ngIf="selectedLps.length > 0">
      <div class="col-xxl-12 col-lg-12 box-col-12 box-container">
        <ng-container *ngFor="let item of selectedLps"
          ><span
            data-bs-toggle="tooltip"
            placement="bottom"
            [ngClass]="'badge ' + badgeService.getBadgeByLocationPartner()"
            >{{ item }}
          </span></ng-container
        >
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary"
    (click)="confirm(sendMail)"
    [disabled]="form.invalid || selectedLps.length == 0 || (loading$ | async)"
  >
    <loading-button
      [loading]="loading$ | async"
      [value]="'buttons.confirm'"
      [icon]="null"
    ></loading-button>
  </button>
</div>
