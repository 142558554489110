<div class="modal-header">
  <div>
    <h4 class="modal-title" id="exampleModalLabel">
      {{"addLP.title" | translate}}
    </h4>
    <h5>{{ "enumMapping.addLP." + selectedOption.toString() | translate}}</h5>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickDismissAll()"
  ></button>
</div>
<div class="modal-body">
  <form
    novalidate
    class="theme-form login-form creation-form p-0"
    (ngSubmit)="submit()"
    [formGroup]="locationPartnerForm"
  >
    <aw-wizard #wizard [disableNavigationBar]="true">
      <aw-wizard-step formGroupName="personalInformation">
        <app-personal-information
          [form]="
            locationPartnerForm.get('personalInformation')! | asFormGroup
          "
        ></app-personal-information>

        <button
          class="btn btn-primary pull-right"
          type="button"
          (click)="verifyEmail()"
          [disabled]="!locationPartnerForm.get('personalInformation')?.valid"
        >
          {{"buttons.next" | translate}}
        </button>
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          (click)="clearForm()"
        >
        {{"buttons.cancel" | translate}}
        </button>
      </aw-wizard-step>

      <aw-wizard-step formGroupName="companyInformation">
        <app-company-information [create]="true"
          [form]="
            locationPartnerForm.get('companyInformation')! | asFormGroup 
          "
          [option]="update.optionSelected"
        ></app-company-information>
        <button
          class="btn btn-primary pull-right"
          type="button"
          (click)="verifyCompanyName()"
          [disabled]="!locationPartnerForm.get('companyInformation')?.valid || filename == null"
        >
        {{"buttons.next" | translate}}
        </button>
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
        {{"buttons.return" | translate}}
        </button>
      </aw-wizard-step>

      <aw-wizard-step formGroupName="companySpecification">
        <app-company-specification
          [form]="
            locationPartnerForm.get('companySpecification')! | asFormGroup
          "
        ></app-company-specification>
        <button
          class="btn btn-primary pull-right"
          type="button"
          [disabled]="!locationPartnerForm.get('companySpecification')?.valid"
          awNextStep
        >
        {{"buttons.next" | translate}}
        </button>
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
        {{"buttons.return" | translate}}
        </button>
      </aw-wizard-step>
      <aw-wizard-step formGroupName="locationInformation">
        <app-location-information
          [isUpdate]="false"
          [form]="
            locationPartnerForm.get('locationInformation')! | asFormGroup
          "
        ></app-location-information>
        <app-location-status
          [form]="locationPartnerForm.get('locationInformation')! | asFormGroup"
        ></app-location-status>
        <button
          class="btn btn-primary pull-right"
          type="submit"
          [disabled]="!locationPartnerForm.get('locationInformation')?.valid"
          awNextStep
        >
        {{"buttons.confirm" | translate}}
        </button>
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
        {{"buttons.return" | translate}}
        </button>
      </aw-wizard-step>
    </aw-wizard>
  </form>
</div>