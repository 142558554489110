import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, Observable, of, takeUntil, withLatestFrom } from "rxjs";
import { invokeApiDistroyed, invokeComponentDistroyed, setAPIStatus } from "../apiState.interface";
import { UsersHistoryService } from "src/app/shared/services/usersHistory.service";
import {
  invokeCreateUsersHistory,
  invokeUsersHistory,
  resultCreateUsersHistory,
  setUsersHistory,
} from "./userHistory.actions";
import { AppStateInterface } from "../appState.interface";
import { Store, select, Action } from "@ngrx/store";
import { usersHistoryFilterSelector } from "./userHistory.selectors";
import { ResultUserHistoryInterface } from "src/app/shared/models/user-history/result-user-history.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";

@Injectable()
export class UsersHistoryEffect {
  constructor(
    private actions$: Actions,
    private usersHistoryService: UsersHistoryService,
    private store: Store<AppStateInterface>
  ) {}

  invokeUsersHistory$ = createEffect((): Observable<Action> =>
  this.actions$.pipe(
    ofType(invokeUsersHistory),
    withLatestFrom(this.store.pipe(select(usersHistoryFilterSelector))),
    mergeMap(([refreshCache, filter]) =>
      this.usersHistoryService.getUsersHistoryByFilter(filter, refreshCache.refreshCache).pipe(
        map((data: ResultUserHistoryInterface) =>
          setUsersHistory({ history: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error.message || 'An error occurred',
                apiStatus: 'error',
              },
            })
          )
        ),
        takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
        takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
      )
    )
  )
);


  invokeCreateUserHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateUsersHistory),
      mergeMap((state) => {
        return this.usersHistoryService.createHistory(state.history).pipe(
          map((data: SuccessDto | null) =>
            resultCreateUsersHistory({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
