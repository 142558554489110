import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import {
  Fields,
  FilterEnum,
  FilterInterface,
  typeFilter,
} from "../../data/filter-data";
import { SelectedLps } from "../../models/location-partners/selectedLps";
import { AdvertisersComponent } from "../advertisers/advertisers.component";
import { LocationsComponent } from "../locations/locations.component";
import { RegionComponent } from "../region/region.component";
import { ZoneComponent } from "../zone/zone.component";

@Component({
  selector: "app-general-filter",
  templateUrl: "./general-filter.component.html",
})
export class GeneralFilterComponent implements OnInit {
  @Output() optionSelected = new EventEmitter<any | null>();
  openSidebar: boolean = false;
  showOption: boolean[] = [false];
  interface: any;
  activeMultiSelect: string[] = [];
  activeOptions: { [key: string]: boolean } = {};
  active: string[] = [];
  @Input() filter: FilterInterface;
  filterType = FilterEnum;
  dataType = typeFilter;
  lengthInterface: number = 0;
  saveSelectedLPs$: Observable<SelectedLps | null>;
  selectedLp: string[] = [];

  selectedAdvertisers$: Observable<string[] | null>;
  advertisers: string[] = [];

  saveSelectedZones$: Observable<string[] | null>;
  saveSelectedRegions$: Observable<string[] | null>;
  selectedZones: string[] = [];
  selectedRegions: string[] = [];

  selectedSubCategories$: Observable<string[] | null>;
  selectedSubCategories: string[];
  splineFilterList: string[] = [];
  constructor(private modalService: NgbModal, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
  onOutsideClick(): void {
    this.openSidebar = false;
    this.showOption = [false];
    this.cdr.detectChanges();
  }
  sidebarToggle() {
    this.openSidebar = !this.openSidebar;
    this.showOption = [false];
  }
  openMenu(option: typeFilter, j: number) {
    this.showOption[j] = !this.showOption[j];
    switch (option) {
      case typeFilter.LOCATION: {
        const modal = this.modalService.open(LocationsComponent, {
          size: "lg",
          centered: true,
        });
        break;
      }
      case typeFilter.ZONE: {
        const modal = this.modalService.open(ZoneComponent, {
          size: "lg",
          centered: true,
        });
        break;
      }
      case typeFilter.REGION: {
        const modal = this.modalService.open(RegionComponent, {
          size: "lg",
          centered: true,
        });
        break;
      }
      case typeFilter.ADVERTISER: {
        const modal = this.modalService.open(AdvertisersComponent, {
          size: "lg",
          centered: true,
        });
        break;
      }
    }
  }
  saveOption(option: string, filter: Fields) {
    if (filter.isMultiSelect) {
      if (!this.activeMultiSelect.includes(option)) {
        this.activeMultiSelect = [...this.activeMultiSelect, option];
      } else {
        this.activeMultiSelect = this.activeMultiSelect.filter(
          (item) => item !== option
        );
      }
      this.optionSelected.emit(this.activeMultiSelect);
    } else {
      if (this.active.length == 0) {
        this.active.push(option);
        this.optionSelected.emit(option);
      } else {
        for (let i = 0; i < this.active.length; i++) {
          if (this.active[i] != option) {
            this.active.pop();
            this.active.push(option);
            this.optionSelected.emit(option);
          } else {
            this.active.splice(i);
            this.optionSelected.emit(null);
          }
        }
      }
      if (filter.type == typeFilter.SPLINECHART) {
        if (!this.splineFilterList.includes(option)) {
          this.splineFilterList = [...this.splineFilterList, option];
        } else {
          this.splineFilterList = this.splineFilterList.filter(
            (item) => item !== option
          );
        }
      }
    }
  }
}
