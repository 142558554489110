export enum UserHistoryServicesEnum {
  LIST_ADVERTISERS = "LIST_ADVERTISERS",
  LIST_ESTIMATES = "LIST_ESTIMATES",
  LIST_INVOICES = "LIST_INVOICES",
  DELETE_ADVERTISER = "DELETE_ADVERTISER",
  UPDATE_ADVERTISER = "UPDATE_ADVERTISER",
  UPDATE_ADVERTISER_STATUS = "UPDATE_ADVERTISER_STATUS",
  ADVERTISER_DETAILS = "ADVERTISER_DETAILS",

  LIST_LOCATIONS = "LIST_LOCATIONS",
  CREATE_LOCATIONS = "CREATE_LOCATIONS",
  LOCATION_DETAILS = "LOCATION_DETAILS",
  DELETE_LOCATION = "DELETE_LOCATION",
  UPDATE_LOCATION = "UPDATE_LOCATION",
  LOCATION_PREMIUM_ACCESS = "LOCATION_PREMIUM_ACCESS",

  PAYMENT_LOCATION = "PAYMENT_LOCATION",
  UPDATE_LOCATION_PAYMENT_STATUS = "UPDATE_LOCATION_PAYMENT_STATUS",

  LIST_CAMPAIGN_LOCATIONS = "LIST_CAMPAIGN_LOCATIONS",
  CAMPAIGN_LOCATIONS_STATISTICS = "CAMPAIGN_LOCATIONS_STATISTICS",
  CAMPAIGN_LOCATIONS_SHOW_VIDEO = "CAMPAIGN_LOCATIONS_SHOW_VIDEO",
  DELETE_CAMPAIGN_LOCATION = "DELETE_CAMPAIGN_LOCATION",
  CAMPAIGN_LOCATION_UPDATE_STATUS = "CAMPAIGN_LOCATION_UPDATE_STATUS",
  CAMPAIGN_LOCATION_UPDATE = "CAMPAIGN_LOCATION_UPDATE",
  CAMPAIGN_LOCATION_DETAILS = "CAMPAIGN_LOCATION_DETAILS",
  CREATE_CAMPAIGN_LP = "CREATE_CAMPAIGN_LP",

  LIST_CAMPAIGNS = "LIST_CAMPAIGNS",
  ADVERTISER_CAMPAIGNS_STATISTICS = "ADVERTISER_CAMPAIGNS_STATISTICS",
  CAMPAIGN_DETAILS = "CAMPAIGN_DETAILS",
  ADMIN_CAMPAIGN_ADVERTISER_UPDATE = "ADMIN_CAMPAIGN_ADVERTISER_UPDATE",
  ADMIN_CAMPAIGN_UPDATE_STATUS = "ADMIN_CAMPAIGN_UPDATE_STATUS",
  DELETE_CAMPAIGN = "DELETE_CAMPAIGN",
  ADMIN_CAMPAIGN_ADVERTISER_SHOW_VIDEO = "ADMIN_CAMPAIGN_ADVERTISER_SHOW_VIDEO",
  ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT = "ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT",
  CAMPAIGN_UPLOAD_JUSTIFICATION_FILE = "CAMPAIGN_UPLOAD_JUSTIFICATION_FILE",
  CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE = "CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE",
  CALCULATE_COST = "CALCULATE_COST",
  CALENDAR = "CALENDAR",

  LIST_ROUTERS = "LIST_ROUTERS",
  UPDATE_ROUTER = "UPDATE_ROUTER",
  DELETE_ROUTER = "DELETE_ROUTER",
  ROUTER_DETAILS = "ROUTER_DETAILS",
  CREATE_ROUTERS = "CREATE_ROUTERS",

  CONSUMER_STATISTICS = "CONSUMER_STATISTICS",

  LIST_ACCESS_AND_ROLE = "LIST_ACCESS_AND_ROLE",
  DELETE_COLLABORATOR = "DELETE_COLLABORATOR",
  COLLABORATOR_HISTORY = "COLLABORATOR_HISTORY",
  COLLABORATOR_UPDATE = "COLLABORATOR_UPDATE",
  COLLABORATOR_CREATE = "COLLABORATOR_CREATE",

  DELETE_SUB_ROLE = "DELETE_SUB_ROLE",
  CREATE_SUB_ROLE = "CREATE_SUB_ROLE",
  UPDATE_SUB_ROLE = "UPDATE_SUB_ROLE",

  LIST_FIXED_CHARGE = "LIST_FIXED_CHARGE",
  DELETE_FIXED_CHARGE = "DELETE_FIXED_CHARGE",
  CREATE_FIXED_CHARGE = "CREATE_FIXED_CHARGE",
  UPDATE_FIXED_CHARGE = "UPDATE_FIXED_CHARGE",

  LIST_PROMOTION_CODES = "LIST_PROMOTION_CODES",
  DELETE_PROMOTION_CODE = "DELETE_PROMOTION_CODE",
  CREATE_PROMOTION_CODE = "CREATE_PROMOTION_CODE",
  UPDATE_PROMOTION_CODE = "UPDATE_PROMOTION_CODE",

  LIST_OBJECTIVES = "LIST_OBJECTIVES",
  UPDATE_OBJECTIVES = "UPDATE_OBJECTIVES",

  LIST_REGIONS = "LIST_REGIONS",
  DELETE_REGION = "DELETE_REGION",
  CREATE_REGION = "CREATE_REGION",
  UPDATE_REGION = "UPDATE_REGION",

  LIST_ZONES = "LIST_ZONES",
  DELETE_ZONE = "DELETE_ZONE",
  CREATE_ZONE = "CREATE_ZONE",
  UPDATE_ZONE = "UPDATE_ZONE",

  LIST_CATEGORIES = "LIST_CATEGORIES",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  CREATE_CATEGORY = "CREATE_CATEGORY",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",

  DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY",
  CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY",
  UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY",
  LIST_SUB_CATEGORIES = "LIST_SUB_CATEGORIES",

  LIST_TAGS = "LIST_TAGS",
  DELETE_TAG = "DELETE_TAG",
  CREATE_TAG = "CREATE_TAG",
  UPDATE_TAG = "UPDATE_TAG",

  LIST_IMPRESSIONS = "LIST_IMPRESSIONS",
  UPDATE_VIEWS_AVERAGE = "UPDATE_VIEWS_AVERAGE",
  EXPORT_VIEWS_AVERAGE = "EXPORT_VIEWS_AVERAGE",
  UPDATE_VIEWS_AVERAGE_MANUALLY = "UPDATE_VIEWS_AVERAGE_MANUALLY",

  MAIN_DASHBOARD_ADMIN = "MAIN_DASHBOARD_ADMIN",

  MAIN_DASHBOARD_ADVERTISER = "MAIN_DASHBOARD_ADVERTISER",
  UPDATE_ADVERTISING_CAMPAIGN = "UPDATE_ADVERTISING_CAMPAIGN",
  CREATE_ADVERTISING_CAMPAIGN = "CREATE_ADVERTISING_CAMPAIGN",
  PAYMENT_BANK_TRANSFER = "PAYMENT_BANK_TRANSFER",
  PAYMENT_KONNECT = "PAYMENT_KONNECT",
  CHECK_PROMOTION_CODE = "CHECK_PROMOTION_CODE",
  APPLY_PROMOTION_CODE = "APPLY_PROMOTION_CODE",
  UPLOAD_VIDEO_CAMPAIGN = "UPLOAD_VIDEO_CAMPAIGN",
  LIST_CAMPAIGNS_CARD = "LIST_CAMPAIGNS_CARD",

  MAIN_DASHBOARD_LP = "MAIN_DASHBOARD_LP",
  PROMOTE_CAMPAIGN_LP = "PROMOTE_CAMPAIGN_LP",
  REMUNERATION_LP = "REMUNERATION_LP",
  REMUNERATION_LP_FSI = "REMUNERATION_LP_FSI",

  LOCATION_PARTNER_STATISTICS = "LOCATION_PARTNER_STATISTICS",
  PROFILE = "PROFILE",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",

  LIST_HISTORY = "LIST_HISTORY"
}

export const UserHistoryServicesEnumMapping: Record<
  UserHistoryServicesEnum,
  string
> = {
  [UserHistoryServicesEnum.LIST_ADVERTISERS]: "userHistoryServices.LIST_ADVERTISERS",
  [UserHistoryServicesEnum.LIST_ESTIMATES]: "userHistoryServices.LIST_ESTIMATES",
  [UserHistoryServicesEnum.LIST_INVOICES]: "userHistoryServices.LIST_INVOICES",
  [UserHistoryServicesEnum.DELETE_ADVERTISER]: "userHistoryServices.DELETE_ADVERTISER",
  [UserHistoryServicesEnum.UPDATE_ADVERTISER]: "userHistoryServices.UPDATE_ADVERTISER",
  [UserHistoryServicesEnum.UPDATE_ADVERTISER_STATUS]: "userHistoryServices.UPDATE_ADVERTISER_STATUS",
  [UserHistoryServicesEnum.ADVERTISER_DETAILS]: "userHistoryServices.ADVERTISER_DETAILS",
  [UserHistoryServicesEnum.LIST_LOCATIONS]: "userHistoryServices.LIST_LOCATIONS",
  [UserHistoryServicesEnum.CREATE_LOCATIONS]: "userHistoryServices.CREATE_LOCATIONS",
  [UserHistoryServicesEnum.LOCATION_DETAILS]: "userHistoryServices.LOCATION_DETAILS",
  [UserHistoryServicesEnum.DELETE_LOCATION]: "userHistoryServices.DELETE_LOCATION",
  [UserHistoryServicesEnum.UPDATE_LOCATION]: "userHistoryServices.UPDATE_LOCATION",
  [UserHistoryServicesEnum.LOCATION_PREMIUM_ACCESS]: "userHistoryServices.LOCATION_PREMIUM_ACCESS",
  [UserHistoryServicesEnum.PAYMENT_LOCATION]: "userHistoryServices.PAYMENT_LOCATION",
  [UserHistoryServicesEnum.UPDATE_LOCATION_PAYMENT_STATUS]: "userHistoryServices.UPDATE_LOCATION_PAYMENT_STATUS",
  [UserHistoryServicesEnum.LIST_CAMPAIGN_LOCATIONS]: "userHistoryServices.LIST_CAMPAIGN_LOCATIONS",
  [UserHistoryServicesEnum.CAMPAIGN_LOCATIONS_STATISTICS]: "userHistoryServices.CAMPAIGN_LOCATIONS_STATISTICS",
  [UserHistoryServicesEnum.CAMPAIGN_LOCATIONS_SHOW_VIDEO] : "userHistoryServices.CAMPAIGN_LOCATIONS_SHOW_VIDEO",
  [UserHistoryServicesEnum.DELETE_CAMPAIGN_LOCATION] : "userHistoryServices.DELETE_CAMPAIGN_LOCATION",
  [UserHistoryServicesEnum.CAMPAIGN_LOCATION_UPDATE_STATUS] : "userHistoryServices.CAMPAIGN_LOCATION_UPDATE_STATUS",
  [UserHistoryServicesEnum.CAMPAIGN_LOCATION_UPDATE] : "userHistoryServices.CAMPAIGN_LOCATION_UPDATE",
  [UserHistoryServicesEnum.CAMPAIGN_LOCATION_DETAILS] : "userHistoryServices.CAMPAIGN_LOCATION_DETAILS",
  [UserHistoryServicesEnum.CREATE_CAMPAIGN_LP] : "userHistoryServices.CREATE_CAMPAIGN_LP",
  [UserHistoryServicesEnum.LIST_CAMPAIGNS] : "userHistoryServices.LIST_CAMPAIGNS",
  [UserHistoryServicesEnum.ADVERTISER_CAMPAIGNS_STATISTICS] : "userHistoryServices.ADVERTISER_CAMPAIGNS_STATISTICS",
  [UserHistoryServicesEnum.CAMPAIGN_DETAILS] : "userHistoryServices.CAMPAIGN_DETAILS",
  [UserHistoryServicesEnum.ADMIN_CAMPAIGN_ADVERTISER_UPDATE] : "userHistoryServices.ADMIN_CAMPAIGN_ADVERTISER_UPDATE",
  [UserHistoryServicesEnum.ADMIN_CAMPAIGN_UPDATE_STATUS] : "userHistoryServices.ADMIN_CAMPAIGN_UPDATE_STATUS",
  [UserHistoryServicesEnum.DELETE_CAMPAIGN] : "userHistoryServices.DELETE_CAMPAIGN",
  [UserHistoryServicesEnum.ADMIN_CAMPAIGN_ADVERTISER_SHOW_VIDEO] : "userHistoryServices.ADMIN_CAMPAIGN_ADVERTISER_SHOW_VIDEO",
  [UserHistoryServicesEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT] : "userHistoryServices.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT",
  [UserHistoryServicesEnum.CAMPAIGN_UPLOAD_JUSTIFICATION_FILE] : "userHistoryServices.CAMPAIGN_UPLOAD_JUSTIFICATION_FILE",
  [UserHistoryServicesEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE] : "userHistoryServices.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE",
  [UserHistoryServicesEnum.CALCULATE_COST] : "userHistoryServices.CALCULATE_COST",
  [UserHistoryServicesEnum.CALENDAR] : "userHistoryServices.CALENDAR",
  [UserHistoryServicesEnum.LIST_ROUTERS] : "userHistoryServices.LIST_ROUTERS",
  [UserHistoryServicesEnum.UPDATE_ROUTER] : "userHistoryServices.UPDATE_ROUTER",
  [UserHistoryServicesEnum.DELETE_ROUTER] : "userHistoryServices.DELETE_ROUTER",
  [UserHistoryServicesEnum.ROUTER_DETAILS] : "userHistoryServices.ROUTER_DETAILS",
  [UserHistoryServicesEnum.CREATE_ROUTERS] : "userHistoryServices.CREATE_ROUTERS",
  [UserHistoryServicesEnum.CONSUMER_STATISTICS] : "userHistoryServices.CONSUMER_STATISTICS",
  [UserHistoryServicesEnum.LIST_ACCESS_AND_ROLE] : "userHistoryServices.LIST_ACCESS_AND_ROLE",
  [UserHistoryServicesEnum.DELETE_COLLABORATOR] : "userHistoryServices.DELETE_COLLABORATOR",
  [UserHistoryServicesEnum.COLLABORATOR_HISTORY] : "userHistoryServices.COLLABORATOR_HISTORY",
  [UserHistoryServicesEnum.COLLABORATOR_UPDATE] : "userHistoryServices.COLLABORATOR_UPDATE",
  [UserHistoryServicesEnum.COLLABORATOR_CREATE] : "userHistoryServices.COLLABORATOR_CREATE",
  [UserHistoryServicesEnum.DELETE_SUB_ROLE] : "userHistoryServices.DELETE_SUB_ROLE",
  [UserHistoryServicesEnum.CREATE_SUB_ROLE] : "userHistoryServices.CREATE_SUB_ROLE",
  [UserHistoryServicesEnum.UPDATE_SUB_ROLE] : "userHistoryServices.UPDATE_SUB_ROLE",
  [UserHistoryServicesEnum.LIST_FIXED_CHARGE] : "userHistoryServices.LIST_FIXED_CHARGE",
  [UserHistoryServicesEnum.DELETE_FIXED_CHARGE] : "userHistoryServices.DELETE_FIXED_CHARGE",
  [UserHistoryServicesEnum.CREATE_FIXED_CHARGE] : "userHistoryServices.CREATE_FIXED_CHARGE",
  [UserHistoryServicesEnum.UPDATE_FIXED_CHARGE] : "userHistoryServices.UPDATE_FIXED_CHARGE",
  [UserHistoryServicesEnum.LIST_PROMOTION_CODES] : "userHistoryServices.LIST_PROMOTION_CODES",
  [UserHistoryServicesEnum.DELETE_PROMOTION_CODE] : "userHistoryServices.DELETE_PROMOTION_CODE",
  [UserHistoryServicesEnum.CREATE_PROMOTION_CODE] : "userHistoryServices.CREATE_PROMOTION_CODE",
  [UserHistoryServicesEnum.UPDATE_PROMOTION_CODE] : "userHistoryServices.UPDATE_PROMOTION_CODE",
  [UserHistoryServicesEnum.LIST_OBJECTIVES] : "userHistoryServices.LIST_OBJECTIVES",
  [UserHistoryServicesEnum.UPDATE_OBJECTIVES] : "userHistoryServices.UPDATE_OBJECTIVES",
  [UserHistoryServicesEnum.LIST_REGIONS] : "userHistoryServices.LIST_REGIONS",
  [UserHistoryServicesEnum.DELETE_REGION] : "userHistoryServices.DELETE_REGION",
  [UserHistoryServicesEnum.CREATE_REGION] : "userHistoryServices.CREATE_REGION",
  [UserHistoryServicesEnum.UPDATE_REGION] : "userHistoryServices.UPDATE_REGION",
  [UserHistoryServicesEnum.LIST_ZONES] : "userHistoryServices.LIST_ZONES",
  [UserHistoryServicesEnum.DELETE_ZONE] : "userHistoryServices.DELETE_ZONE",
  [UserHistoryServicesEnum.CREATE_ZONE] : "userHistoryServices.CREATE_ZONE",
  [UserHistoryServicesEnum.UPDATE_ZONE] : "userHistoryServices.UPDATE_ZONE",
  [UserHistoryServicesEnum.LIST_CATEGORIES] : "userHistoryServices.LIST_CATEGORIES",
  [UserHistoryServicesEnum.DELETE_CATEGORY] : "userHistoryServices.DELETE_CATEGORY",
  [UserHistoryServicesEnum.CREATE_CATEGORY] : "userHistoryServices.CREATE_CATEGORY",
  [UserHistoryServicesEnum.UPDATE_CATEGORY] : "userHistoryServices.UPDATE_CATEGORY",
  [UserHistoryServicesEnum.DELETE_SUB_CATEGORY] : "userHistoryServices.DELETE_SUB_CATEGORY",
  [UserHistoryServicesEnum.CREATE_SUB_CATEGORY] : "userHistoryServices.CREATE_SUB_CATEGORY",
  [UserHistoryServicesEnum.UPDATE_SUB_CATEGORY] : "userHistoryServices.UPDATE_SUB_CATEGORY",
  [UserHistoryServicesEnum.LIST_SUB_CATEGORIES] : "userHistoryServices.LIST_SUB_CATEGORIES",
  [UserHistoryServicesEnum.LIST_TAGS] : "userHistoryServices.LIST_TAGS",
  [UserHistoryServicesEnum.DELETE_TAG] : "userHistoryServices.DELETE_TAG",
  [UserHistoryServicesEnum.CREATE_TAG] : "userHistoryServices.CREATE_TAG",
  [UserHistoryServicesEnum.UPDATE_TAG] : "userHistoryServices.UPDATE_TAG",
  [UserHistoryServicesEnum.LIST_IMPRESSIONS] : "userHistoryServices.LIST_IMPRESSIONS",
  [UserHistoryServicesEnum.UPDATE_VIEWS_AVERAGE] : "userHistoryServices.UPDATE_VIEWS_AVERAGE",
  [UserHistoryServicesEnum.EXPORT_VIEWS_AVERAGE] : "userHistoryServices.EXPORT_VIEWS_AVERAGE",
  [UserHistoryServicesEnum.UPDATE_VIEWS_AVERAGE_MANUALLY] : "userHistoryServices.UPDATE_VIEWS_AVERAGE_MANUALLY",
  [UserHistoryServicesEnum.MAIN_DASHBOARD_ADMIN] : "userHistoryServices.MAIN_DASHBOARD_ADMIN",
  [UserHistoryServicesEnum.MAIN_DASHBOARD_ADVERTISER] : "userHistoryServices.MAIN_DASHBOARD_ADVERTISER",
  [UserHistoryServicesEnum.UPDATE_ADVERTISING_CAMPAIGN] : "userHistoryServices.UPDATE_ADVERTISING_CAMPAIGN",
  [UserHistoryServicesEnum.CREATE_ADVERTISING_CAMPAIGN] : "userHistoryServices.CREATE_ADVERTISING_CAMPAIGN",
  [UserHistoryServicesEnum.PAYMENT_BANK_TRANSFER] : "userHistoryServices.PAYMENT_BANK_TRANSFER",
  [UserHistoryServicesEnum.PAYMENT_KONNECT] : "userHistoryServices.PAYMENT_KONNECT",
  [UserHistoryServicesEnum.CHECK_PROMOTION_CODE] : "userHistoryServices.CHECK_PROMOTION_CODE",
  [UserHistoryServicesEnum.APPLY_PROMOTION_CODE] : "userHistoryServices.APPLY_PROMOTION_CODE",
  [UserHistoryServicesEnum.UPLOAD_VIDEO_CAMPAIGN] : "userHistoryServices.UPLOAD_VIDEO_CAMPAIGN",
  [UserHistoryServicesEnum.LIST_CAMPAIGNS_CARD] : "userHistoryServices.LIST_CAMPAIGNS_CARD",
  [UserHistoryServicesEnum.MAIN_DASHBOARD_LP] : "userHistoryServices.MAIN_DASHBOARD_LP",
  [UserHistoryServicesEnum.PROMOTE_CAMPAIGN_LP] : "userHistoryServices.PROMOTE_CAMPAIGN_LP",
  [UserHistoryServicesEnum.REMUNERATION_LP] : "userHistoryServices.REMUNERATION_LP",
  [UserHistoryServicesEnum.REMUNERATION_LP_FSI] : "userHistoryServices.REMUNERATION_LP_FSI",
  [UserHistoryServicesEnum.LOCATION_PARTNER_STATISTICS] : "userHistoryServices.LOCATION_PARTNER_STATISTICS",
  [UserHistoryServicesEnum.PROFILE] : "userHistoryServices.PROFILE",
  [UserHistoryServicesEnum.UPDATE_PROFILE] : "userHistoryServices.UPDATE_PROFILE",
  [UserHistoryServicesEnum.UPDATE_PASSWORD] : "userHistoryServices.UPDATE_PASSWORD",
  [UserHistoryServicesEnum.LIST_HISTORY] : "userHistoryServices.LIST_HISTORY"
};

export interface UserHistoryServicesInterface {
  id: UserHistoryServicesEnum;
  value: string;
}
