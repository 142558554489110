import { createAction, props } from "@ngrx/store";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { CreateUserHistory } from "src/app/shared/models/user-history/create-user-history";
import { FilterUserHistoryInterface } from "src/app/shared/models/user-history/filter-user-history.interface";
import { ResultUserHistoryInterface } from "src/app/shared/models/user-history/result-user-history.interface";

export const invokeUsersHistory = createAction(
  "[ Invoke Get Users History ] invoke get Users History",
  props<{ refreshCache: boolean }>()
);
export const setUsersHistory = createAction(
  "[ Set Users History ] Set Users History",
  props<{ history: ResultUserHistoryInterface }>()
);

export const setUsersHistoryFilter = createAction(
  "[ Invoke Users History Filter ] invoke Users History Filter",
  props<{ filter: FilterUserHistoryInterface}>()
);

export const invokeCreateUsersHistory = createAction(
  "[ Invoke create Users History ] invoke create Users History",
  props<{ history: CreateUserHistory }>()
);
export const resultCreateUsersHistory = createAction(
  "[ Result create Users History ] Result create Users History",
  props<{ successMessage: SuccessDto | null }>()
);
