<div class="modal-header justify-content-start">
  <img [src]="showlogo()" width="7%" class="m-r-10" style="padding-left: 1rem;"/>
  <h4 class="modal-title" id="exampleModalLabel">
    {{ title | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body" style="padding-left: 2rem">
  <ng-template #none>
    <p class="m-b-0">{{ "undefined" | translate }}</p>
  </ng-template>
  <div>
    <h6 style="color: #ff69b4">
      <U>{{ "modalDetails.personalDetails" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.email" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.email != null; else none">
        {{ locationPartner.email }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.fullName" | translate }}
      </h5>
      <p
        class="m-b-0"
        *ngIf="
          locationPartner.firstName != null || locationPartner.lastName != null;
          else none
        "
      >
        {{ locationPartner.firstName }} {{ locationPartner.lastName }}
      </p>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "addLP.secondStep.title" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "addLP.secondStep.companyName" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.companyName != null; else none">
        {{ locationPartner.companyName }}
        <ng-template #none>
          {{ "undefined" | translate }}
        </ng-template>
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "addLP.secondStep.companyAddress" | translate }}
      </h5>
      <p
        class="m-b-0"
        *ngIf="locationPartner.companyAddress != null; else none"
      >
        {{ locationPartner.companyAddress }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "addLP.secondStep.companyActivity" | translate }}
      </h5>
      <p
        class="m-b-0"
        *ngIf="locationPartner.companyActivity != null; else none"
      >
        {{ locationPartner.companyActivity }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "addLP.secondStep.companyPhone" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.phone != null; else none">
        {{ locationPartner.phone }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "profile.profileDetails.role" | translate }}
      </h5>
      <p class="m-b-0">
        {{ locationPartner.companyRole ?? ("undefined" | translate) }}
      </p>

      <h5 class="f-w-600" style="font-size: 12px">
        {{ "addLP.partnerStatus.title" | translate }}
      </h5>
      <p class="m-b-0">
        <span
          *ngIf="locationPartner.partnerStatus != null; else none"
          [ngClass]="
            'badge ' +
            badgeService.getBadgeByLocationPartnerStatus(
              locationPartner.partnerStatus
            )
          "
        >
          {{
            "enumMapping.partnerStatus." + locationPartner.partnerStatus
              | translate
          }}
        </span>
      </p>

      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.premiumEndDate" | translate }}
      </h5>
      <p
        class="m-b-0"
        *ngIf="locationPartner.premiumEndDate != null; else none"
      >
        {{ (locationPartner.premiumEndDate | localizedDate) ?? "--/--" }}
      </p>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "addLP.thirdStep.title" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.category" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.category != null; else none">
        {{ locationPartner.category }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.subCategory" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.subCategories != null; else none">
        {{ locationPartner.subCategories }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.tags" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.tags != null; else none">
        {{ locationPartner.tags }}
      </p>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "addLP.4thStep.title" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.region" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.region != null; else none">
        {{ locationPartner.region }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.zone" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.zoneName != null; else none">
        {{ locationPartner.zoneName }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.longitude" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.longitude != null; else none">
        {{ locationPartner.longitude }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.latitude" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="locationPartner.latitude != null; else none">
        {{ locationPartner.latitude }}
      </p>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "addLP.partnerStatus.title" | translate }}</U>
    </h6>
    <div
      class="grid-column"
      *ngIf="locationPartner.partnerStatus === partnerStatus.REVENUE_SHARING"
    >
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.startPhase" | translate }}
      </h5>
      <p
        class="m-b-0"
        *ngIf="locationPartner.pilotPhaseStartDate != null; else none"
      >
        {{ (locationPartner.pilotPhaseStartDate | localizedDate) ?? "--/--" }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.endPhase" | translate }}
      </h5>
      <p
        class="m-b-0"
        *ngIf="locationPartner.pilotPhaseEndDate != null; else none"
      >
        {{ (locationPartner.pilotPhaseEndDate | localizedDate) ?? "--/--" }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.expenses" | translate }}
      </h5>
      <p class="m-b-0">
        {{ locationPartner.expenses }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.price" | translate }}
      </h5>
      <p class="m-b-0">
        {{ locationPartner.price }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.pricingMethod" | translate }}
      </h5>
      <p class="m-b-0">
        <span
          *ngIf="locationPartner.pricingMethod != null; else spanNone"
          [ngClass]="
            'badge ' +
            badgeService.getBadgeByLocationPartnerPricingMethod(
              locationPartner.pricingMethod
            )
          "
          >{{
            "enumMapping.paymentMethode." + locationPartner.pricingMethod
              | translate
          }}
        </span>
      </p>
    </div>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px">
        {{ "admin.locations.repository.priceFrequency" | translate }}
      </h5>
      <p class="m-b-0">
        <span
          *ngIf="locationPartner.paymentFrequency; else spanNone"
          [ngClass]="
            'badge ' +
            badgeService.getBadgeByLocationPartnerPaymentFrequency(
              locationPartner.paymentFrequency
            )
          "
        >
          {{
            "enumMapping.paymentFrequency." + locationPartner.paymentFrequency
              | translate
          }}
        </span>
        <ng-template #spanNone>
          <span
            [ngClass]="
              'badge ' +
              badgeService.getBadgeByLocationPartnerPaymentFrequency(
                paymentFrequencyToShow.NONE
              )
            "
          >
            {{ "UNDEFINED" | translate }}
          </span>
        </ng-template>
      </p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
</div>
