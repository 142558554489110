<div class="modal-header">
  <div>
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "LPOptions.update" | translate }}
      <b>{{ locationPartner.companyName }}</b>
    </h4>
    <h5 class="m-b-0 m-t-10">
      {{ "enumMapping.updateLPcards." + selectedOption.toString() | translate }}
    </h5>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickCloseModal()"
  ></button>
</div>
<div class="modal-body p-b-0">
  <form class="form theme-form" [formGroup]="personalInformation">
    <div
      *ngIf="
        selectedOption == step.ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION
      "
    >
      <div class="form-group">
        <label class="label"
          >{{ "addLP.firstStep.firstName" | translate }} *</label
        >
        <div class="input-group">
          <input
            formControlName="firstName"
            class="form-control"
            type="text"
            required=""
            [placeholder]="'profile.profileDetails.firstName' | translate"
            [ngClass]="{
              'is-invalid':
                !personalInformation.get('firstName')?.valid &&
                personalInformation.get('firstName')?.touched
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="
              !personalInformation.get('firstName')?.valid &&
              personalInformation.get('firstName')?.touched
            "
          >
            {{ "validation.firstName" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="label"
          >{{ "addLP.firstStep.lastName" | translate }} *</label
        >
        <div class="input-group">
          <input
            formControlName="lastName"
            class="form-control"
            type="text"
            required=""
            [placeholder]="'profile.profileDetails.lastName' | translate"
            [ngClass]="{
              'is-invalid':
                !personalInformation.get('lastName')?.valid &&
                personalInformation.get('lastName')?.touched
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="
              !personalInformation.get('lastName')?.valid &&
              personalInformation.get('lastName')?.touched
            "
          >
            {{ "validation.lastName" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="label">{{ "addLP.firstStep.email" | translate }} *</label>
        <div class="input-group">
          <input
            formControlName="email"
            class="form-control"
            type="email"
            required=""
            [placeholder]="'profile.profileDetails.email' | translate"
            [ngClass]="{
              'is-invalid':
                !personalInformation.get('email')?.valid &&
                personalInformation.get('email')?.touched
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="
              !personalInformation.get('email')?.valid &&
              personalInformation.get('email')?.touched
            "
          >
            {{ "validation.email" | translate }}
          </div>
        </div>
      </div>
      <form class="form theme-form" [formGroup]="companyInformation">
        <app-company-information
          [create]="false"
          [form]="companyInformation! | asFormGroup"
        ></app-company-information>
      </form>
    </div>
  </form>
  <form class="form theme-form" [formGroup]="companySpecification">
    <div
      *ngIf="selectedOption == step.ADMIN_UPDATE_LOCATION_COMPANY_SPECIFICATION"
    >
      <h5 style="text-align: left">
        {{ "addLP.thirdStep.title" | translate }}
      </h5>
      <div class="form-group">
        <label class="label"
          >{{ "addLP.thirdStep.category" | translate }} *</label
        >
        <div class="input-group">
          <select
            class="form-select form-control"
            formControlName="category"
            (change)="onChangeCategory()"
          >
            <option [ngValue]="null" disabled selected>
              {{ "addLP.thirdStep.selectCategory" | translate }}
            </option>
            <option
              *ngFor="let item of allCategoryNames$ | async"
              [ngValue]="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="label"
          >{{ "addLP.thirdStep.subCategories" | translate }} *</label
        >
        <ng-select
          formControlName="subCategories"
          [items]="subCategoriesByCategory$ | async"
          bindLabel="subCategories"
          bindValue="id"
          [multiple]="true"
          (clear)="(true)"
          [placeholder]="'addLP.thirdStep.selectSubCategories' | translate"
          [ngClass]="{
            'is-invalid':
              !companySpecification.get('subCategories')?.valid &&
              companySpecification.get('subCategories')?.touched
          }"
        >
        </ng-select>
        <div
          class="invalid-feedback"
          *ngIf="
            !companySpecification.get('subCategories')?.valid &&
            companySpecification.get('subCategories')?.touched
          "
        >
          {{ "validation.subCategories" | translate }}
        </div>
      </div>
      <div class="form-group">
        <label class="label">{{ "addLP.thirdStep.tags" | translate }}*</label>
        <ng-select
          formControlName="tags"
          [items]="allTagNames$ | async"
          bindLabel="tags"
          bindValue="id"
          [multiple]="true"
          (clear)="(true)"
          [placeholder]="'addLP.thirdStep.selectTags' | translate"
          [ngClass]="{
            'is-invalid':
              !companySpecification.get('tags')?.valid &&
              companySpecification.get('tags')?.touched
          }"
        >
        </ng-select>
        <div
          class="invalid-feedback"
          *ngIf="
            !companySpecification.get('tags')?.valid &&
            companySpecification.get('tags')?.touched
          "
        >
          {{ "validation.tags" | translate }}
        </div>
      </div>
    </div>
  </form>
  <form class="form theme-form" [formGroup]="locationInformation">
    <div
      *ngIf="selectedOption == step.ADMIN_UPDATE_LOCATION_LOCATION_INFORMATION"
    >
      <app-location-information
        [isUpdate]="true"
        [form]="locationInformation! | asFormGroup"
      ></app-location-information>
    </div>
  </form>
  <form class="form theme-form" [formGroup]="partnerStatus">
    <div
      *ngIf="
        selectedOption == step.ADMIN_UPDATE_LOCATION_PARTNER_STATUS_INFORMATION
      "
    >
      <app-location-status
        [isUpdate]="true"
        [form]="partnerStatus! | asFormGroup"
      ></app-location-status>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="onClickBack()">
    {{ "buttons.return" | translate }}
  </button>
  <button
    class="btn btn-primary button-right"
    type="submit"
    (click)="saveChanges()"
    [disabled]="isDisableButton()"
  >
    {{ "buttons.confirm" | translate }}
  </button>
</div>
