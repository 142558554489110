import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  AdvertiserStatusEnum,
  AdvertiserStatusEnumMapping,
  AdvertiserStatusInterface,
} from "src/app/shared/enum/advertiser-status";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { invokeApproveAdvertiser } from "src/app/store/advertiser/advertiser.actions";
import { approveAdvertiserSelector, loadingApproveAdvertiserSelector } from "src/app/store/advertiser/advertiser.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeCreateClient } from "src/app/store/clientIberis/clientIberis.actions";
import { environment } from "src/environments/environment";
import { invokeSideBarNews } from "src/app/store/admin/admin.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { invokeActivateAdvertiserAccount } from "src/app/store/mailing/mailing.actions";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";

@Component({
  selector: "app-update-advertiser-status",
  templateUrl: "./update-advertiser-status.component.html",
})
export class UpdateAdvertiserStatusComponent extends BaseComponent implements OnInit {
  @Input("advertiser") advertiser: AdvertiserDetailsInterface;
  form: FormGroup;
  advertiserStatus: AdvertiserStatusInterface[];
  loading : boolean
  loading$ : Observable<boolean | null>
  advertiserStatusEnum = AdvertiserStatusEnum
  resultApproveAdvertiser$: Observable<SuccessDto | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private saveHistory : SaveUserHistoryService
  ) {
    super(store)
    this.form = this.fb.group({
      status: [null],
    });
    this.loading$ = this.store
      .pipe(select(loadingApproveAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$))
    this.resultApproveAdvertiser$ = this.store
      .pipe(select(approveAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.loading$.subscribe((data)=>{
      if(data) this.loading = data
    })
    this.form.patchValue({ status: this.advertiser.status });
    this.advertiserStatus = Object.keys(AdvertiserStatusEnum)
      .filter((value) => isNaN(Number(value)) === true && value != AdvertiserStatusEnum.EMAIL_VERIFICATION)
      .map((key: string | any) => ({
        id: AdvertiserStatusEnum[key as keyof typeof AdvertiserStatusEnum],
        value:
          AdvertiserStatusEnumMapping[key as keyof typeof AdvertiserStatusEnum],
      }));
      this.resultApproveAdvertiser$.subscribe((result: SuccessDto | null) => {
        const status = this.form.get("status")?.value;
        if (result != null && status == AdvertiserStatusEnum.ENABLED) {
          this.store.dispatch(invokeActivateAdvertiserAccount({email : this.advertiser.email}))
        }
      });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  updateStatus() {
    this.loading = true
    if (
      !this.advertiser.verified &&
      this.form.get("status")?.value != AdvertiserStatusEnum.DISABLED &&
        this.form.get("status")?.value != AdvertiserStatusEnum.EMAIL_VERIFICATION
    ) {
      this.loading = false
      this.toastr.error(
        this.translate.instant(
          "response.error.CANT_APPROVE_ADVERTISER_ACCOUNT_NOT_VERIFIED"
        ),
        this.translate.instant("response.errorTitle")
      );
    } 
    else if (
      this.advertiser.iberis == null &&
      this.form.get("status")?.value == AdvertiserStatusEnum.ENABLED
    ) {
      if (
        this.advertiser.status != AdvertiserStatusEnum.EMAIL_VERIFICATION &&
        this.advertiser.verified
      ) {
        this.createIberisAccount();
      }
    }
    else{
      this.store.dispatch(
        invokeApproveAdvertiser({
          dto: {
            email: this.advertiser.email,
            status: this.form.get("status")?.value,
            iberis: this.advertiser.iberis,
          },
        })
      );
      this.store.dispatch(invokeSideBarNews())
    }
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.PATCH,
      UserHistoryServicesEnum.UPDATE_ADVERTISER_STATUS
    );  
  }
  createIberisAccount() {
    let createClient: any = {
      first_name: this.advertiser?.firstName,
      last_name: this.advertiser?.lastName,
      email: this.advertiser?.email.toLowerCase(),
      phone: this.advertiser?.phone,
      website: this.advertiser?.website,
      company: this.advertiser?.companyName,
      display_name: 1,
      activity_id: this.advertiser?.companyActivity,
      currency_id: "rorJ",
      deadline_id: "VGRwN",
      reference: "Relead",
    };
    if (this.advertiser.website == null) {
      createClient.website = "https://relead.tn/";
    }

    let params: CommonParamsIberis = {
      lang: "fr",
      companyId: environment.IBERIS.IBERIS_COMPANY_ID,
    };

    this.store.dispatch(
      invokeCreateClient({
        params: params,
        createClient: createClient,
      })
    );
  }
}
