<form [formGroup]="form">
  <div class="form-group">
    <label class="label">
      {{ "profile.profileDetails.firstName" | translate }} *
    </label>
    <div class="input-group">
      <input
        formControlName="firstName"
        class="form-control"
        type="text"
        required=""
        [placeholder]="'profile.profileDetails.firstName' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('firstName')?.valid && form.get('firstName')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('firstName')?.valid && form.get('firstName')?.touched"
      >
        {{ "validation.firstName" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">
      {{ "profile.profileDetails.lastName" | translate }} *
    </label>
    <div class="input-group">
      <input
        formControlName="lastName"
        class="form-control"
        type="email"
        required=""
        [placeholder]="'profile.profileDetails.lastName' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('lastName')?.valid && form.get('lastName')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('lastName')?.valid && form.get('lastName')?.touched"
      >
        {{ "validation.lastName" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">
      {{ "profile.profileDetails.email" | translate }} *
    </label>
    <div class="input-group">
      <input
        formControlName="email"
        class="form-control"
        type="email"
        required=""
        [placeholder]="'profile.profileDetails.email' | translate"
        [ngClass]="{
          'is-invalid': !form.get('email')?.valid && form.get('email')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('email')?.valid && form.get('email')?.touched"
      >
        {{ "validation.email" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group m-t-20 m-b-20">
    <label class="label"
      >{{ "profile.profileDetails.tel" | translate }} *</label
    >
    <div class="input-group">
      <input
        formControlName="phone"
        class="form-control"
        type="text"
        required=""
        [placeholder]="'profile.profileDetails.phone' | translate"
        [ngClass]="{
          'is-invalid': !form.get('phone')?.valid && form.get('phone')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('phone')?.valid && form.get('phone')?.touched"
      >
        {{ "validation.phone" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{
      "profile.profileDetails.birthday" | translate
    }}</label>
    <div class="input-group">
      <input
        class="form-control"
        type="text"
        formControlName="birthday"
        placeholder="dd/MM/yyyy"
        [owlDateTimeTrigger]="dt"
        [owlDateTime]="dt"
      />
      <div
        class="input-group-text bg-primary"
        data-target="#dt-date"
        [owlDateTimeTrigger]="dt"
      >
        <i
          class="icofont icofont-ui-calendar"
          style="color: white; font-size: 12px"
        ></i>
      </div>
    </div>
    <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
  </div>
</form>
