<div id="chart">
  <apx-chart style="display: flex; justify-content: center;"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [colors]="chartOptions.colors"
    [legend]="chartOptions.legend"
    *ngIf="length > 0 && !loading"
  >
  </apx-chart>
</div>
<div class="text-center" *ngIf="loading">
  <div class="loader-box">
    <div class="loader-34"></div>
  </div>
  <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
</div>
<div *ngIf="length == 0 && !loading;" class="d-flex justify-content-center m-t-20">
  {{"noData" | translate}}
</div>
