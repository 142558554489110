import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { ChartName } from "src/app/shared/components/chart/chart-name.enum";
import { AudienceType } from "src/app/shared/enum/chart/audiance";
import { TechnicalCardSearchMethodEnum } from "src/app/shared/enum/chart/technical-data";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { ConsumerCountByDate } from "src/app/shared/models/consumers/consumerCountByDate";
import { DateFilter } from "src/app/shared/models/date-filte.interface";
import { InputAudience } from "src/app/shared/models/input-audience.interface";
import { AgeStatistics } from "src/app/shared/models/statistics/age-statistics";
import { GenderStatistics } from "src/app/shared/models/statistics/gender-statistics";
import { TechnicalCardSearchMethod } from "src/app/shared/models/statistics/technical-data";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeConsumerAgeRangeDetails,
  invokeConsumersGenderDetails,
  invokeCountConsumersBetweenDates,
} from "src/app/store/consumers/consumers.actions";
import {
  consumerAgeRangeDetailsSelector,
  consumersGenderDetailsSelector,
} from "src/app/store/consumers/consumers.selectors";
import { resultSindingMail } from "src/app/store/mailing/mailing.actions";
import { sendMailSelector } from "src/app/store/mailing/mailing.selectors";

@Component({
  selector: "app-consumers",
  templateUrl: "./consumers.component.html",
})
export class ConsumersComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Output() resultConsumerGender = new EventEmitter<GenderStatistics>();
  @Output() resultConsumerAgeRange = new EventEmitter<AgeStatistics>();
  /* @Output() resultConsumerByDate = new EventEmitter<ConsumerCountByDate>(); */
  @Output() inputAudience = new EventEmitter<InputAudience>();
  @Output() search = new EventEmitter<TechnicalCardSearchMethod>();

  receivedChart: HTMLElement;
  resultConsumerStatics: ConsumerCountByDate | null;
  consumersChart = {
    title: "admin.consumers.overview",
    style: null,
    yaxisTitle: "Number Of Consumers",
    xaxisTitle: "Days",
    seriesTitles: ["Consumers"],
  };

  pieGenderChart = {
    title: "admin.consumers.gender",
    style: null,
    genderType: ChartName.GENDER,
    ageRangeType: ChartName.AGE_RANGE,
  };
  pieDemographicChart = {
    title: "admin.consumers.demographic",
    style: null,
  };
  startDate: Date;
  endDate: Date;
  dates: Date[];
  form: FormGroup;
  consumerGenderDetails$: Observable<GenderStatistics | null>;
  consumerAgeRangeDetails$: Observable<AgeStatistics | null>;
  resultSendStatics$: Observable<SuccessDto | null>;
  genderChartCachedAt : Date | null
  ageRangeChartCachedAt : Date | null
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private saveHistory : SaveUserHistoryService
  ) {
    super(store);
    this.form = this.fb.group({
      date: [[null, null]],
    });
    this.consumerGenderDetails$ = this.store
      .pipe(select(consumersGenderDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.consumerAgeRangeDetails$ = this.store
      .pipe(select(consumerAgeRangeDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSendStatics$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngAfterViewInit(): void {
    this.inputAudience.emit({
      name: null,
      type: AudienceType.CONSUMERS,
      title: "inseights.details.audiences.title",
      titleSpline: "inseights.details.audiences.summary",
      titleColumnChart: "inseights.details.audiences.distribution",
    });
    this.search.emit({
      technicalCard: TechnicalCardSearchMethodEnum.ADMIN_CONSUMERS,
      value: "default",
      locationPartner : null,
      finished : false,
    });
  }

  ngOnInit(): void {
    this.resultSendStatics$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.store.dispatch(resultSindingMail({ successMessage: null }));
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );
      }
    });
    this.store.dispatch(invokeConsumerAgeRangeDetails({refreshCache : false}));
    this.store.dispatch(invokeConsumersGenderDetails({refreshCache : false}));

    this.consumerGenderDetails$.subscribe((result: GenderStatistics | null) => {
      if (result != null) {
        this.genderChartCachedAt = result.cachedAt
        this.resultConsumerGender.emit(result);
      }
    });
    this.consumerAgeRangeDetails$.subscribe((result: AgeStatistics | null) => {
      if (result != null) {
        this.ageRangeChartCachedAt = result.cachedAt;
        this.resultConsumerAgeRange.emit(result);
      }
    });
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.CONSUMER_STATISTICS
    );
    /* this.countConsumersByDate$.subscribe(
      (result: ConsumerCountByDate | null) => {
        if (result != null) {
          this.resultConsumerByDate.emit(result);
        }
      }
    ); */
  }
  chart(chart: any) {
    this.receivedChart = chart;
  }
  consumerStatics(consumerCountByDate: ConsumerCountByDate | null) {
    this.resultConsumerStatics = consumerCountByDate;
  }
  getChartInDate() {
    this.startDate = this.form.get("date")?.value[0];
    this.endDate = this.form.get("date")?.value[1];
    const dateFilter: DateFilter = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.dates = [this.startDate, this.endDate];
    this.store.dispatch(
      invokeCountConsumersBetweenDates({ dateFilter: dateFilter, refreshCache : false })
    );
  }
  onClickRefreshCacheGenderChart(){
    this.store.dispatch(invokeConsumersGenderDetails({refreshCache : true}));
  }
  onClickRefreshCacheAgeRangeChart(){
    this.store.dispatch(invokeConsumerAgeRangeDetails({refreshCache : true}));
  }
}
