import { Component, OnInit } from "@angular/core";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-case-fsi",
  templateUrl: "./case-fsi.component.html",
})
export class CaseFsiComponent implements OnInit {
  bookMeeting: string;
  constructor(private saveHistory: SaveUserHistoryService) {}

  ngOnInit(): void {
    this.bookMeeting = environment.links.bookMeetingLP;
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.REMUNERATION_LP_FSI
    );
  }
}
