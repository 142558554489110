<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: center">Informations personnelles</h5>
  <div class="form-group">
    <div class="small-group">
      <label>Prénom *</label>
      <label style="margin-left: 36%">Nom de famille *</label>
    </div>
    <div class="small-group">
      <div class="input-group">
        <span class="input-group-text">
          <img src="assets/identity/name.png" alt="icone name" />
        </span>
        <input
          formControlName="firstName"
          class="form-control"
          onlyAlphabets
          type="text"
          required="required"
          placeholder="Votre prénom"
          [ngClass]="{
            'is-invalid':
              !form.get('firstName')?.valid && form.get('firstName')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            !form.get('firstName')?.valid && form.get('firstName')?.touched
          "
        >
          Le prénom doit contenir plus de 3 caractères et moins de 30 caractères
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-text">
          <img src="assets/identity/name.png" alt="icone name" />
        </span>
        <input
          formControlName="lastName"
          class="form-control"
          type="text"
          onlyAlphabets
          required="required"
          placeholder="Votre nom"
          [ngClass]="{
            'is-invalid':
              !form.get('lastName')?.valid && form.get('lastName')?.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="!form.get('lastName')?.valid && form.get('lastName')?.touched"
        >
          Le nom de famille doit contenir plus de 3 caractères et moins de 30
          caractères
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>E-mail *</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/email.png" alt="icone email" />
      </span>
      <input
        formControlName="email"
        class="form-control"
        type="email"
        autocomplete="email"
        required
        placeholder="Entrez votre e-mail"
        [ngClass]="{
          'is-invalid':
            !form.get('email')?.valid && form.get('email')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('email')?.valid && form.get('email')?.touched"
      >
        Veuillez saisir un e-mail valide
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Téléphone *</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/phone.svg" alt="icone phone" />
      </span>
      <input
        formControlName="phone"
        class="form-control m-input digits"
        type="text"
        [mask]="'99 999 999'"
        required=""
        placeholder="Saisir votre numéro."
      />
    </div>
  </div>
  <div class="form-group">
    <label>Mot de passe *</label>
    <div class="input-group">
      <span class="input-group-text">
        <a
          placement="bottom"
          triggers="manual"
          popoverTitle="Le mot de passe doit contenir :"
        >
          <img src="assets/identity/password.png" alt="icone password"
        /></a>
      </span>
      <input
        formControlName="password"
        class="form-control"
        [type]="showPassword ? 'text' : 'password'"
        required=""
        placeholder="Entrez votre mot de passe"
        [ngClass]="{
          'is-invalid':
            !form.get('password')?.valid && form.get('password')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('password')?.valid && form.get('password')?.touched"
      >
        Veuillez entrer un mot de passe valide
        <ul style="padding-left: 10px">
          <li>
            - Au moins un caractère alphabétique (minuscule ou majuscule) doit
            être présent.
          </li>
          <li>- Au moins un chiffre numérique (0-9) doit être présent.</li>
          <li>
            - La longueur totale du mot de passe doit être d'au moins 8
            caractères et au plus 32 caractères.
          </li>
        </ul>
      </div>
      <div
        class="show-hide"
        (click)="clickShowPassword()"
        *ngIf="!showPassword"
      >
        <span>Afficher</span>
      </div>
      <div class="show-hide" (click)="clickShowPassword()" *ngIf="showPassword">
        <span>Cacher</span>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Confirmer mot de passe *</label>
    <div class="input-group">
      <span class="input-group-text"
        ><a
          placement="bottom"
          triggers="manual"
          popoverTitle="Le mot de passe doit contenir :"
        >
          <img src="assets/identity/password.png" alt="icone password" /></a
      ></span>
      <input
        formControlName="confirmPassword"
        class="form-control"
        [type]="showConfirmPassword ? 'text' : 'password'"
        required=""
        placeholder="Re-entrez votre mot de passe"
        [ngClass]="{
          'is-invalid':
            !form.get('confirmPassword')?.valid &&
            form.get('confirmPassword')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('confirmPassword')?.valid &&
          form.get('confirmPassword')?.touched
        "
      >
        Veuillez entrer un mot de passe valide
        <ul style="padding-left: 10px">
          <li>
            - Au moins un caractère alphabétique (minuscule ou majuscule) doit
            être présent.
          </li>
          <li>- Au moins un chiffre numérique (0-9) doit être présent.</li>
          <li>
            - La longueur totale du mot de passe doit être d'au moins 8
            caractères et au plus 32 caractères.
          </li>
          <li>
            - Le mot de passe de confirmation doit correspondre au mot de passe.
          </li>
        </ul>
      </div>
      <div
        class="show-hide"
        (click)="clickShowConfirmPassword()"
        *ngIf="!showConfirmPassword"
      >
        <span>Afficher</span>
      </div>
      <div
        class="show-hide"
        (click)="clickShowConfirmPassword()"
        *ngIf="showConfirmPassword"
      >
        <span>Cacher</span>
      </div>
    </div>
  </div>
</form>
