import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { Account } from "src/app/shared/models/user/account";
import {
  invokeRegister,
  registerSuccess,
} from "src/app/store/advertiser/advertiser.actions";
import {
  advertiserSuccessRegister,
  advertisserAccountSelector,
  confirmEmailSelectorSuccess,
  createAdvertiserSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { checkUserExistByEmailSelector } from "src/app/store/user/user.selectors";
import { MustMatch } from "src/app/shared/validators/passwordMatch";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { Register } from "src/app/shared/models/advertiser/register.interface";
import { ToastrService } from "ngx-toastr";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import { Constants } from "src/app/shared/data/constants";
import {
  invokeCheckIfExistByEmail,
  setCheckUserExistsByEmail,
} from "src/app/store/user/user.actions";
import { WizardComponent } from "angular-archwizard";
import { invokeNotifyAdminWhenAdvertiserCreated } from "src/app/store/mailing/mailing.actions";
import { environment } from "src/environments/environment";
import { RecaptchaService } from "src/app/shared/services/recaptcha.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent extends BaseComponent implements OnInit {
  registrationForm: FormGroup;

  @Output() businessInformationSubmitted = new EventEmitter<boolean>(false);
  @Output() personalInformationSubmitted = new EventEmitter<boolean>(false);

  successMessage$: Observable<SuccessDto | null>;
  registerSuccess: string | null;
  account$: Observable<Account | null>;
  isExist$: Observable<boolean | null>;
  isExist: boolean | null;
  error: string | null = null;
  loading: boolean | null = false;
  submitted: boolean;

  companyActivity: IberisActivities[];

  confirmEmailForRegistration$: Observable<any | null>;
  confirmEmail: any | null;

  registerAdvertiser: Register = {
    email: "",
    password: "",
    resetToken: "",
    iberis: "",
  };
  @ViewChild("wizard") wizard: WizardComponent;
  createAdvertiser$: Observable<SuccessDto | null>;

  reCaptchaKey = environment.RECAPTCHA_PUBLIC_KEY;
  isValidateCaptcha: boolean | null = null;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppStateInterface>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private captchaService: RecaptchaService
  ) {
    super(store);
    this.successMessage$ = this.store
      .pipe(select(advertiserSuccessRegister))
      .pipe(takeUntil(this.ngDestroyed$));
    this.account$ = this.store
      .pipe(select(advertisserAccountSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isExist$ = this.store
      .pipe(select(checkUserExistByEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.confirmEmailForRegistration$ = this.store
      .pipe(select(confirmEmailSelectorSuccess))
      .pipe(takeUntil(this.ngDestroyed$));
    this.createAdvertiser$ = this.store
      .pipe(select(createAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.registrationForm = this.fb.group({
      personalInformation: this.fb.group(
        {
          email: new FormControl(null, [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
            ),
          ]),
          password: new FormControl(null, [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ]),
          lastName: [
            null,
            [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(30),
            ],
          ],
          firstName: [
            null,
            [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(30),
            ],
          ],
          //logo: [null],
          phone: [
            null,
            [Validators.required, Validators.pattern("^(?=.*?[0-9]).{8}$")],
          ],
          confirmPassword: new FormControl(null, [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ]),
        },
        {
          validator: MustMatch("password", "confirmPassword"),
        }
      ),
      businessInformation: this.fb.group({
        companyName: new FormControl(null, [Validators.required]),
        companyActivity: new FormControl(null, [Validators.required]),
        website: new FormControl(null, [
          Validators.pattern(
            "^(?=.*?(https://.))(?=.*?[-a-zA-Z0-9@:%_+.~#?&/=]).{11,}$"
          ),
          Validators.required,
        ]),
        companyAddress: new FormControl(null, [Validators.required]),
        companyRole: new FormControl(null, [Validators.required]),
        acceptTerms: new FormControl(null, Validators.requiredTrue),
      }),
      resetToken: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.isExist$.subscribe((data) => {
      if (data != null) {
        this.isExist = data;
        if (data == false) {
          this.wizard.goToNextStep();
        }
        if (data) {
          this.toastr.error(
            this.translate.instant("response.error.email-exist"),
            this.translate.instant("response.errorTitle")
          );
        }
        this.store.dispatch(setCheckUserExistsByEmail({ isExist: null }));
      }
    });
    this.businessInformationSubmitted.emit(false);
    this.personalInformationSubmitted.emit(false);

    this.successMessage$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.redirection();
        this.registerSuccess = result.message;
      }
    });

    this.account$.subscribe((result) => {
      if (result) {
        this.router.navigate(["../login"]);
      }
    });
    if (this.translate.currentLang == "fr")
      this.companyActivity = Constants.IBERIS_ACTIVITIES_FR;
    else this.companyActivity = Constants.IBERIS_ACTIVITIES_EN;

    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        if (langChangeEvent.lang == "fr")
          this.companyActivity = Constants.IBERIS_ACTIVITIES_FR;
        else this.companyActivity = Constants.IBERIS_ACTIVITIES_EN;
      }
    );

    this.confirmEmailForRegistration$.subscribe((result) => {
      if (result != null) {
        this.confirmEmail = result;
      }
    });

    this.createAdvertiser$.subscribe((data) => {
      if (data) {
        this.store.dispatch(
          invokeNotifyAdminWhenAdvertiserCreated({
            email: this.registrationForm
              .get("personalInformation")
              ?.get("email")
              ?.value.toLowerCase(),
            firstName: this.registrationForm
              .get("personalInformation")
              ?.get("firstName")?.value,
            lastName: this.registrationForm
              .get("personalInformation")
              ?.get("lastName")?.value,
          })
        );
        this.store.dispatch(registerSuccess({ successMessage: null }));
      }
    });
  }

  sendConfirmEmail() {
    this.businessInformationSubmitted.emit(true);
    if (this.isExist == false) {
      let advertiser: any = {
        firstName: this.registrationForm
          .get("personalInformation")
          ?.get("firstName")?.value,
        lastName: this.registrationForm
          .get("personalInformation")
          ?.get("lastName")?.value,
        email: this.registrationForm
          .get("personalInformation")
          ?.get("email")
          ?.value.toLowerCase(),
        phone: this.registrationForm.get("personalInformation")?.get("phone")
          ?.value,
        password: this.registrationForm
          .get("personalInformation")
          ?.get("password")?.value,
        website: this.registrationForm
          .get("businessInformation")
          ?.get("website")?.value,
        companyName: this.registrationForm
          .get("businessInformation")
          ?.get("companyName")?.value,
        companyActivity: this.registrationForm
          .get("businessInformation")
          ?.get("companyActivity")?.value,
        companyAddress: this.registrationForm
          .get("businessInformation")
          ?.get("companyAddress")?.value,
        roleName: RoleEnum.ROLE_ADVERTISER,
        companyRole: this.registrationForm
          .get("businessInformation")
          ?.get("companyRole")?.value,
      };
      this.store.dispatch(invokeRegister({ advertiser: advertiser }));
    }
  }
  register() {
    this.error = "";
    if (this.registrationForm.get("personalInformation")?.get("email")?.value) {
      this.registerAdvertiser.email = this.registrationForm
        .get("personalInformation")
        ?.get("email")
        ?.value.toLowerCase();
    }
    (this.registerAdvertiser.password = this.registrationForm
      .get("personalInformation")
      ?.get("password")?.value),
      (this.registerAdvertiser.resetToken =
        this.registrationForm.get("resetToken")?.value);

    this.loading = true;
  }

  redirection() {
    setTimeout(() => {
      this.router.navigate(["/auth/login"]);
    }, 30000);
  }

  nextStep() {
    this.store.dispatch(
      invokeCheckIfExistByEmail({
        email: this.registrationForm
          .get("personalInformation")
          ?.get("email")
          ?.value.toLowerCase(),
      })
    );
  }

  resolved(token: string) {
    this.captchaService.validateCaptcha(token).subscribe({
      next: (response) => {
        //this.isValidateCaptcha = response;
        console.log("isValidateCaptcha", this.isValidateCaptcha);
      },
    });
    if (token) {
      this.isValidateCaptcha = true;
    }
  }

  onExpired() {
    this.isValidateCaptcha = false;
    console.log(this.isValidateCaptcha);
  }

  onError(log: any) {
    console.log("error ", log);
  }
}
