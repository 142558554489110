<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: left">{{ "addLP.firstStep.title" | translate }}</h5>
  <div class="form-group">
    <label class="label">{{ "addLP.firstStep.firstName" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="firstname"
        class="form-control"
        type="text"
        required
        [placeholder]="'profile.profileDetails.firstName' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('firstname')?.valid && form.get('firstname')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('firstname')?.valid && form.get('firstname')?.touched"
      >
        {{ "validation.firstName" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "addLP.firstStep.lastName" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="lastname"
        class="form-control"
        type="text"
        required
        [placeholder]="'profile.profileDetails.lastName' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('lastname')?.valid && form.get('lastname')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('lastname')?.valid && form.get('lastname')?.touched"
      >
        {{ "validation.lastName" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "addLP.firstStep.email" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="email"
        class="form-control"
        type="email"
        required
        [placeholder]="'profile.profileDetails.email' | translate"
        [ngClass]="{
          'is-invalid': !form.get('email')?.valid && form.get('email')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('email')?.valid && form.get('email')?.touched"
      >
        {{ "validation.email" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "addLP.firstStep.password" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="password"
        class="form-control"
        [type]="showPassword1 ? 'text' : 'password'"
        required=""
        placeholder="*********"
        [ngClass]="{
          'is-invalid':
            !form.get('password')?.valid && form.get('password')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('password')?.valid && form.get('password')?.touched"
      >
        {{ "erreurMsg.validPassword" | translate }}
        <ul style="padding-left: 10px">
          <li>- {{ "password.pattern.validator1" | translate }}</li>
          <li>- {{ "password.pattern.validator2" | translate }}</li>
          <li>- {{ "password.pattern.validator3" | translate }}</li>
        </ul>
      </div>
      <div class="show-hide" (click)="showPassword()" *ngIf="!showPassword1" onKeyPress>
        <span> {{ "password.show" | translate }} </span>
      </div>
      <div class="show-hide" (click)="showPassword()" *ngIf="showPassword1" onKeyPress>
        <span>{{ "password.cash" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label"
      >{{ "addLP.firstStep.confirmPassword" | translate }} *</label
    >
    <div class="input-group">
      <input
        formControlName="confirmPassword"
        class="form-control"
        [type]="showConfirmPassword1 ? 'text' : 'password'"
        required=""
        placeholder="*********"
        [ngClass]="{
          'is-invalid':
            !form.get('confirmPassword')?.valid &&
            form.get('confirmPassword')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('confirmPassword')?.valid &&
          form.get('confirmPassword')?.touched
        "
      >
        {{ "erreurMsg.validPassword" | translate }}
        <ul style="padding-left: 10px">
          <li>- {{ "password.pattern.validator1" | translate }}</li>
          <li>- {{ "password.pattern.validator2" | translate }}</li>
          <li>- {{ "password.pattern.validator3" | translate }}</li>
          <li>- {{ "password.matchPassword" | translate }}</li>
        </ul>
      </div>
      <div
        class="show-hide"
        (click)="showConfirmPassword()"
        onKeyPress
        *ngIf="!showConfirmPassword1"
      >
        <span> {{ "password.show" | translate }} </span>
      </div>
      <div
        class="show-hide"
        (click)="showConfirmPassword()"
        onKeyPress
        *ngIf="showConfirmPassword1"
      >
        <span>{{ "password.cash" | translate }}</span>
      </div>
    </div>
  </div>
</form>
