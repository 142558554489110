import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeCreateUsersHistory } from "src/app/store/userHistory/userHistory.actions";
import { UserHistoryActionsEnum } from "../../enum/userHistoryActions";
import { CreateUserHistory } from "../../models/user-history/create-user-history";

@Injectable({
  providedIn: "root",
})
export class SaveUserHistoryService {
  constructor(private store: Store<AppStateInterface>) {}

  saveUserHistory(action: UserHistoryActionsEnum, endPoint: string) {
    const userHistory: CreateUserHistory = {
      action: action,
      endPoint: endPoint,
      ipAddress: localStorage.getItem("Ip-Address"),
    };
    this.store.dispatch(invokeCreateUsersHistory({ history: userHistory }));
  }
}
