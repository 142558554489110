<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: left" *ngIf="create">
    {{ "addLP.secondStep.title" | translate }}
  </h5>
  <div class="form-group" *ngIf="!franchise">
    <label class="label"> {{ "addLP.secondStep.logo" | translate }} *</label>
    <input
      class="form-control"
      type="file"
      aria-label="file example"
      accept="image/x-png,image/gif,image/jpeg"
      (change)="onFileSelected($event)"
    />
  </div>
  <!--<div class="form-group" *ngIf="franchise">
    <label>Assign Franchise</label>
    <div class="input-group">
      <select
        class="form-select form-control"
        formControlName="category"
      >
        <option [ngValue]="null" disabled selected>Select one category</option>
        <option >
         
        </option>
      </select>
    </div>
  </div>-->
  <div class="form-group">
    <label class="label"
      >{{ "addLP.secondStep.companyName" | translate }} *</label
    >
    <div class="input-group">
      <input
        formControlName="companyName"
        class="form-control"
        type="text"
        required
        [placeholder]="'addLP.secondStep.companyName' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('companyName')?.valid && form.get('companyName')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('companyName')?.valid && form.get('companyName')?.touched
        "
      >
        {{ "validation.companyName" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label"
      >{{ "addLP.secondStep.companyAddress" | translate }} *</label
    >
    <div class="input-group">
      <input
        formControlName="companyAddress"
        class="form-control"
        type="text"
        required
        [placeholder]="'addLP.secondStep.companyAddress' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('companyAddress')?.valid &&
            form.get('companyAddress')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('companyAddress')?.valid &&
          form.get('companyAddress')?.touched
        "
      >
        {{ "validation.companyAddress" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label"
      >{{ "addLP.secondStep.companyActivity" | translate }} *</label
    >
    <div class="input-group">
      <select
        class="form-select form-control"
        formControlName="companyActivity"
      >
        <option selected disabled [ngValue]="null">
          {{ "addLP.secondStep.placeholderActivity" | translate }}
        </option>
        <option
          class="form-select"
          *ngFor="let companyActivity of companyActivity"
          [value]="companyActivity.hashed_id"
        >
          {{ companyActivity.title }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label class="label"
      >{{ "addLP.secondStep.companyPhone" | translate }} *</label
    >
    <div class="input-group">
      <input
        formControlName="phone"
        class="form-control m-input digits"
        type="text"
        [mask]="'99 999 999'"
        required
        [placeholder]="'addLP.secondStep.companyPhone' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('phone')?.valid &&
            form.get('phone')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('phone')?.valid && form.get('phone')?.touched
        "
      >
        {{ "validation.phone" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "addLP.secondStep.role" | translate }} *</label>
    <div class="input-group">
      <input
        class="form-control"
        type="text"
        formControlName="companyRole"
        required
        [placeholder]="'addLP.secondStep.role' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('companyRole')?.valid && form.get('companyRole')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('companyRole')?.valid && form.get('companyRole')?.touched
        "
      >
        {{ "validation.companyRole" | translate }}
      </div>
    </div>
  </div>
</form>
