import { DatePipe } from "@angular/common";
import { Component, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { WizardComponent } from "angular-archwizard";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { Constants } from "src/app/shared/data/constants";
import { defaultFieldsData } from "src/app/shared/data/newFieldsFormData";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { DiscountTypeEnum } from "src/app/shared/enum/discount-type.enum";
import { LocationAvailabilityEnum } from "src/app/shared/enum/location-availability";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import {
  ObjectiveTypeEnum,
} from "src/app/shared/enum/objectives";
import { AdvertisingPaymentMethodEnum } from "src/app/shared/enum/paymentMethod";
import { TypeFieldsLeadGeneration } from "src/app/shared/enum/typeFieldsLeadGeneration";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { AdPaymentDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-payment-details.interface";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "src/app/shared/models/calcul/totalCost";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { LeadGeneration } from "src/app/shared/models/leadGeneration/leadGeneration";
import { ErrorPostKonnectInterface, PostKonnectInterface, ResultKonnectInterface } from "src/app/shared/models/payment/konnect.interface";
import { ErrorPostPaymeeInterface, PostPaymeeInterface, ResultPaymeeInterface } from "src/app/shared/models/payment/paymee.interface";
import { PromotionCodeLiteInterface } from "src/app/shared/models/promotion-code/check-promotion-code.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { KonnectService } from "src/app/shared/services/payment/konnect.service";
import { PaymeeService } from "src/app/shared/services/payment/paymee.service";
import { invokeProfileDetails } from "src/app/store/advertiser/advertiser.actions";
import { advertiserProfileDetailsSelector, resultIdIberisSelector } from "src/app/store/advertiser/advertiser.selectors";
import {
  adDetailsSelector,
  getAdCompaignByHashedIdSelector,
  updateAdCampaignDetailsSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeAdDetails,
  invokeAdvertisingCompaignByHashedId,
  invokeChangeAdStatus,
  invokeUpadateAdPaymentDetails,
  invokeUpdateAdCampaignDetails,
  resultCheckAdvertisingCampaignExistByTitle,
  updateAdCampaignDetails,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeSynthesisEstimate,
  invokeUpdateEstimate,
} from "src/app/store/commande/commande.actions";
import { resultSynthesisEstimateSelector } from "src/app/store/commande/commande.selectors";
import { invokeCalculateTotalLPCostByCampanyNames } from "src/app/store/locationPartner/location-partner.action";
import { calculateTotalLPCostByCampanyNamesSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { invokeSendLeadGenerationForm } from "src/app/store/mailing/mailing.actions";
import { invokeAdHavePromotionCode } from "src/app/store/promotion-code/promotion-code.actions";
import { getAdHavePromotionCodeSelector } from "src/app/store/promotion-code/promotion-code.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-update-campaign",
  templateUrl: "./update-campaign.component.html",
  styleUrls: ["./update-campaign.component.scss"],
})
export class UpdateCampaignComponent extends BaseComponent implements OnInit {
  @Output() form: FormGroup;
  @ViewChild("wizard") wizard: WizardComponent;
  objectiveName: any;
  campaignHashedId: string;
  adByHashedId$: Observable<AdvertisingCampaignInterface | null>;
  adByHashedId: AdvertisingCampaignInterface;
  redirectionLink: string | null = null;
  objectiveTypeEnum = ObjectiveTypeEnum;
  formStep2: FormGroup;
  filename: string;
  idIberis$: Observable<string | null>;
  idIberis: string;
  adDetails$: Observable<AdDetailsInterface>;
  adDetails: AdDetailsInterface = { ...initialState.adDetails };
  formLeadGeneration: LeadGeneration = {
    title: "",
    description: "",
    fields: [],
    buttonName: "",
    adTitle: "",
    owner: "",
    creationDate: null,
  };
  calculateTotalLPCostByCampanyNames$: Observable<TotalCostWithDiscount | null>;
  calculateTotalLPCostByCampanyNames: TotalCost[] = [];
  estimateId: string = "";
  estimateNumber: string = "";
  resultSynthesisEstimate$: Observable<ResultSynthesisEstimate | null>;
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  paymentMethod: any;
  totalCost: number = 0;
  totalImpressions: number = 0;
  totalClicks: number = 0;
  totalLead: number = 0;
  updateAdCampaign: AdvertisingCampaignInterface | null;
  createEstimate: createEstimate;
  conditions: string = "";
  updateAdvertisingCampaign$: Observable<SuccessDto | null>;
  defaultItems = defaultFieldsData;
  promotionExists$: Observable<PromotionCodeLiteInterface | null>;
  promotionExists: PromotionCodeLiteInterface | null = null;
  loading : boolean = false
  profile$: Observable<AdvertiserDetailsInterface | null>;
  profile: AdvertiserDetailsInterface;
  adStatus : CampaignStatusEnum
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store<AppStateInterface>,
    private router: Router,
    private newCampaignCalculatorService: NewCampaignCalculatorService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private paymeeService: PaymeeService,
    private konnectService: KonnectService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.campaignHashedId =
      this.activatedRoute.snapshot.params["campaignHasedID"];
    this.form = this.fb.group({
      title: [
        null,
        [Validators.required, Validators.maxLength(32), Validators.minLength(3)],
      ],
      startingDate: [null, Validators.required],
      endingDate: [null, Validators.required],
      locationPartners: [[], Validators.required],
      subCategories: [[]],
      zones: [[]],
    });
    this.formStep2 = this.fb.group({
      webSite: [
        "https://",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^https://.*"),
        ],
      ],
    });
    this.idIberis$ = this.store
      .pipe(select(resultIdIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.adByHashedId$ = this.store
      .pipe(select(getAdCompaignByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.calculateTotalLPCostByCampanyNames$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSynthesisEstimate$ = this.store
      .pipe(select(resultSynthesisEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.updateAdvertisingCampaign$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.promotionExists$ = this.store
      .pipe(select(getAdHavePromotionCodeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));    
    this.profile$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.profile$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.profile$.subscribe((user) => {
      if (user) this.profile = user;
      else this.store.dispatch(invokeProfileDetails());
    });
    this.updateAdvertisingCampaign$.subscribe((data) => {
      if (data) {
        this.store.dispatch(updateAdCampaignDetails({ successMessage: null }));
      }
    });
    this.store.dispatch(
      resultCheckAdvertisingCampaignExistByTitle({ isExist: null })
    );
    this.idIberis$.subscribe((result) => {
      if (result) {
        this.idIberis = result;
      }
    });
    this.form.valueChanges.subscribe((value) => {
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: {
            ...this.adDetails,
            title: this.form.get("title")?.value,
            startDate: this.form.get("startingDate")?.value,
            endDate: this.form.get("endingDate")?.value,
            objectiveName: this.objectiveName,
            locations: this.form.get("locationPartners")?.value,
            validators: {
              conditions: this.adDetails.validators.conditions,
              dates: this.datesValidator(),
              locations: this.locationsValidator(),
              title: this.titleValidator(),
              zones: this.zonesValidator(),
            },
          },
        })
      );
    });
    this.adByHashedId$.subscribe((result) => {
      if (result) {
        this.adByHashedId = {
          ...result,
          startingDate: new Date(result.startingDate),
          endingDate: new Date(result.endingDate),
        };
        this.adStatus = this.adByHashedId.status
        this.objectiveName = result.objectiveName;
        this.store.dispatch(
          invokeSynthesisEstimate({
            params: this.params,
            estimateId: result.estimateId,
          })
        );
        this.form.patchValue({ ...this.adByHashedId });
        this.formStep2.patchValue({ webSite: this.adByHashedId.redirectionLink });
        this.redirectionLink = this.formStep2.get("webSite")?.value;
        this.filename = this.adByHashedId.videoLink;
        let calculationMethod: LocationSearchMethod =
          LocationSearchMethod.LOCATION_PARTNER;
        if (result.calculationMethod) {
          calculationMethod = result.calculationMethod;
        }
        const calculateCost = {
          calculationMethod: calculationMethod,
          names: result.locationPartners,
          objectiveName: this.objectiveName,
          startDate: this.form.get("startingDate")?.value,
          endDate: this.form.get("endingDate")?.value,
        };
        this.store.dispatch(
          invokeCalculateTotalLPCostByCampanyNames({
            calculateCost: calculateCost,
          })
        );
      }else{
        this.store.dispatch(
          invokeAdvertisingCompaignByHashedId({
            campaignHashedId: this.campaignHashedId,
          })
        );
      }
    });
    this.resultSynthesisEstimate$.subscribe(
      (result: ResultSynthesisEstimate | null) => {
        if (result != null && result.data.estimate) {
          this.estimateId = result.data.estimate.hashed_id;
          this.estimateNumber = result.data.estimate.estimate_number.replace(
            "DV-",
            ""
          );
          this.conditions = result.data.estimate.conditions;
        }
      }
    );
    this.calculateTotalLPCostByCampanyNames$.subscribe((value) => {
      if (value != null) {
        this.calculateTotalLPCostByCampanyNames = value.totalCostPerDay.filter(
          (value) =>
            value.availabilities.availabilityStatus !=
            LocationAvailabilityEnum.NOT_AVAILABLE
        );
        this.totalCost = this.newCampaignCalculatorService.calculateTotalCost(
          value,
          this.newCampaignCalculatorService.calculateDays(
            this.form.get("startingDate")?.value,
            this.form.get("endingDate")?.value
          )
        );
        this.totalImpressions =
          this.newCampaignCalculatorService.calculateTotalImpressions(
            value,
            this.newCampaignCalculatorService.calculateDays(
              this.form.get("startingDate")?.value,
              this.form.get("endingDate")?.value
            )
          );
        this.totalClicks = Math.trunc(this.totalImpressions * 0.05) ?? 0;
        this.totalLead = Math.trunc(this.totalImpressions * 0.6) ?? 0;
        let videoLink: string | null = null;
        if (this.adByHashedId) {
          videoLink = this.adByHashedId.videoLink;
        }
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              title: this.form.get("title")?.value,
              startDate: this.form.get("startingDate")?.value,
              endDate: this.form.get("endingDate")?.value,
              totalCost: this.totalCost,
              totalClicks: this.totalClicks,
              totalLead: this.totalLead,
              totalImpressions: this.totalImpressions,
              locations: this.calculateTotalLPCostByCampanyNames.map(
                (e) => e.companyName
              ),
              videoLink: videoLink,
              estimateNumber: this.estimateNumber,
              estimateId: this.estimateId,
              campaignHashedID : this.campaignHashedId,
              validators: {
                conditions: false,
                title: true,
                dates: true,
                locations: true,
                zones: true,
              },
            },
          })
        );
      }
    });
    this.adDetails$.subscribe((data: AdDetailsInterface | null) => {
      if (data) {
        this.adDetails = data;
      }
    });
    if (this.campaignHashedId) {
      this.store.dispatch(
        invokeAdHavePromotionCode({ havePromotion: this.campaignHashedId })
      );
    }
    this.promotionExists$.subscribe((value) => {
      if (value?.promotionCode) {
        this.promotionExists = value;
      }
    });
  }
  resultPaymentMethod(result: AdvertisingPaymentMethodEnum | null) {
    this.paymentMethod = result;
  }
  totalCostFunction(data: number) {
    this.totalCost = data;
  }
  handleValueChanged(value: any) {
    this.formStep2.get("webSite")?.setValue(value);
  }
  handleFileName(value: string) {
    this.filename = value;
  }
  getWebSite() {
    return this.formStep2.get("webSite")?.valid;
  }
  //*****Validators ******//
  titleValidator(): boolean {
    return this.form.get("title")?.valid ?? false;
  }
  datesValidator(): boolean {
    return (
      (this.form.get("startingDate")?.valid &&
        this.form.get("endingDate")?.valid) ??
      false
    );
  }
  locationsValidator(): boolean {
    return this.form.get("locationPartners")?.valid ?? false;
  }
  zonesValidator(): boolean {
    return this.form.get("zones")?.valid ?? false;
  }
  checkValidation(step: number): boolean {
    switch (step) {
      case 1:
        return (
          this.titleValidator() &&
          this.datesValidator() &&
          (this.form.get("locationPartners")?.valid || this.zonesValidator())
        );
      case 2:
        if (this.objectiveName == ObjectiveTypeEnum.WEB_SITE_TRAFFIC)
          return (
            (this.formStep2.get("webSite")?.valid ?? false) &&
            this.adDetails.videoFile != undefined
          );
        if (this.objectiveName == ObjectiveTypeEnum.GENERATION_DE_PROSPECTS)
          return (
            this.formLeadGeneration.description != "" &&
            this.formLeadGeneration.title != ""
          );
        else {
          return true;
        }
      case 3:
        return true;
      case 4:
        return true;
      default:
        return false;
    }
    //here we must check validations
  }
  next(step: number) {
    // this.adTitle = this.activatedRoute.snapshot.queryParamMap.get("title");
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: {
          ...this.adDetails,
          title: this.form.get("title")?.value,
          startDate: this.form.get("startingDate")?.value,
          endDate: this.form.get("endingDate")?.value,
          totalCost: this.totalCost,
          totalClicks: this.totalClicks,
          totalLead: this.totalLead,
          totalImpressions: this.totalImpressions,
          locations: this.calculateTotalLPCostByCampanyNames.map(
            (e) => e.companyName
          ),
          videoLink: this.adByHashedId.videoLink,
          estimateNumber: this.estimateNumber,
          estimateId: this.estimateId,
       //   promotion: this.promotionExists,
          campaignHashedID : this.campaignHashedId,
          validators: {
            conditions: false,
            title: true,
            dates: true,
            locations: true,
            zones: true,
          },
        },
      })
    );
    var discountType: number = 1;
    var discountValue: number = 0;
    switch (this.adDetails.promotion?.discountType) {
      case DiscountTypeEnum.AMOUNT: {
        discountType = 2;
        discountValue = this.adDetails.promotion?.discountValue;
        break;
      }
      case DiscountTypeEnum.PERCENTAGE: {
        discountType = 1;
        discountValue = this.adDetails.promotion?.discountValue;
        break;
      }
    }
    this.createEstimate = {
      date: this.datePipe.transform(Constants.DATE_IBERIS, "yyyy-MM-dd"),
      due: this.datePipe.transform(Constants.DUE_DATE_IBERIS, "yyyy-MM-dd"),
      estimate_number: this.adDetails.estimateNumber.substring(
        this.adDetails.estimateNumber.lastIndexOf("-") + 1
      ),
      object: "Campagne " + this.form.get("title")?.value,
      notes: this.campaignHashedId,
      tax_type: 1,
      client_id: this.idIberis,
      use_conditions: 0,
      show_stamp: 1,
      show_billing: 1,
      show_delivery: 0,
      show_bank: 1,
      bank_id: environment.IBERIS.BANK_ID,
      show_conditions: 1,
      show_description: 1,
      show_pictures: 1,
      choice: 1,
      currency_rate: 1,
      language: this.params.lang,
      conditions: this.conditions,
      reference: "",
      discount: "",
      additional_contact_id: "",
      show_unity: 1,
      items: {
        //item_hashed_id: "",
        item: "Campagne " + this.form.get("title")?.value,
        description:
          this.datePipe.transform(
            this.form.get("startingDate")?.value,
            "yyyy-MM-dd "
          ) +
          "-" +
          this.datePipe.transform(
            this.form.get("endingDate")?.value,
            "yyyy-MM-dd "
          ) +
          "\n" +
          this.calculateTotalLPCostByCampanyNames.map((e) => e.companyName),
        discountType: discountType,
        discountAmount: discountValue,
        qte: 1,
        price: this.totalCost,
        taxes: [{ tax_hashed_id: environment.IBERIS.TAX_HASHED_ID }],
      },
      useInput: {
        input_hashed_id: environment.IBERIS.TIMBRE_FISCAL_HASHED_ID,
        value: 1,
      },
    };
    this.updateAdCampaign = {
      title: this.form.get("title")?.value,
      startingDate: this.form.get("startingDate")?.value,
      endingDate: this.form.get("endingDate")?.value,
      locationPartners: this.calculateTotalLPCostByCampanyNames.map(
        (e) => e.companyName
      ),
      videoLink: this.filename,
      objectiveName: this.objectiveName,
      status: this.adStatus,
      estimateId: this.estimateId,
      redirectionLink: this.formStep2.get("webSite")?.value,
      views: 0,
      synthesisReport: null,
      justificationFile: this.adByHashedId.justificationFile,
      publishDate: this.adByHashedId.publishDate,
      orderReference: this.adByHashedId.orderReference,
      paymentMethod: this.adByHashedId.paymentMethod,
      calculationMethod: this.adDetails.calculationMethod,
      advertisingCampaignHashedId: this.adByHashedId.advertisingCampaignHashedId,
      firstName : this.profile.firstName,
      lastName : this.profile.lastName,
      emailPublisher : this.profile.email.toLowerCase(),
      publisherCompanyName : this.profile.companyName
    };
    const isEqual =
      this.adByHashedId.locationPartners.length ===
        this.calculateTotalLPCostByCampanyNames.length &&
      this.adByHashedId.locationPartners.every((partner) =>
        this.calculateTotalLPCostByCampanyNames
          .map((e) => e.companyName)
          .includes(partner)
      );
    if (
      step == 1 &&
      (this.adByHashedId.title != this.form.get("title")?.value ||
        this.adByHashedId.startingDate != this.form.get("startingDate")?.value ||
        this.adByHashedId.endingDate != this.form.get("endingDate")?.value ||
        !isEqual)
    ) {
      if (this.campaignHashedId) {
        this.store.dispatch(
          invokeUpdateEstimate({
            params: this.params,
            estimateId: this.estimateId,
            createEstimate: this.createEstimate,
          })
        );
        this.store.dispatch(
          invokeUpdateAdCampaignDetails({
            campaignHashedId: this.campaignHashedId,
            adCompaign: this.updateAdCampaign,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.PATCH,
          UserHistoryServicesEnum.UPDATE_ADVERTISING_CAMPAIGN
        );
      }
    }
    if(step == 1){
      this.wizard.goToNextStep();
    }
    if (this.objectiveName == ObjectiveTypeEnum.GENERATION_DE_PROSPECTS) {
      this.defaultItems = [
        {
          title: "leadGeneration.fields.name",
          logo: "icofont icofont-ui-user",
          type: TypeFieldsLeadGeneration.TEXT,
          values: [],
        },
        {
          title: "leadGeneration.fields.tel",
          logo: "icofont icofont-telephone",
          type: TypeFieldsLeadGeneration.NUMBER,
          values: [],
        },
        {
          title: "leadGeneration.fields.email",
          logo: "icofont icofont-ui-email",
          type: TypeFieldsLeadGeneration.TEXT,
          values: [],
        },
        {
          title: "leadGeneration.fields.birthday",
          logo: "icofont icofont-ui-calendar",
          type: TypeFieldsLeadGeneration.DATE,
          values: [],
        },
        {
          title: "leadGeneration.fields.profession",
          logo: "icofont icofont-teacher",
          type: TypeFieldsLeadGeneration.TEXT,
          values: [],
        },
      ];
    }
    if (
      step == 2 &&
      this.campaignHashedId &&
      (this.adByHashedId.redirectionLink != this.formStep2.get("webSite")?.value ||
        this.adByHashedId.videoLink != this.filename)
    ) {
      this.store.dispatch(
        invokeUpdateAdCampaignDetails({
          campaignHashedId: this.campaignHashedId,
          adCompaign: this.updateAdCampaign,
        })
      );
      this.saveHistory.saveUserHistory(
        UserHistoryActionsEnum.PATCH,
        UserHistoryServicesEnum.UPDATE_ADVERTISING_CAMPAIGN
      );
    }
  }
  saveLead(leadGeneration: LeadGeneration) {
    this.formLeadGeneration = leadGeneration;
  }
  sendLeadGeneration() {
    this.store.dispatch(
      invokeSendLeadGenerationForm({
        leadGeneration: this.formLeadGeneration,
      })
    );
    if (this.checkValidation(2)) {
      this.wizard.goToNextStep();
    }
  }
  paymentSelected() {
    this.loading = true;
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: {
          ...this.adDetails,
          paymentMethod: this.paymentMethod,
        },
      })
    );
    if (this.estimateId) {
      switch (this.paymentMethod) {
        case AdvertisingPaymentMethodEnum.PAYMEE: {
          const payment: PostPaymeeInterface = {
            amount: this.totalCost,
            email: this.profile.email.toLowerCase(),
            first_name: this.profile.firstName,
            last_name: this.profile.lastName,
            note: this.campaignHashedId,
            order_id: this.estimateId,
            phone: this.profile.phone,
            return_url:
              environment.PAYMENT.RETURN_URL + "?estimateId=" + this.estimateId,
            webhook_url: environment.PAYMENT.WEBHOOK_URL,
            cancel_url: environment.PAYMENT.CANCEL_URL,
          };
          this.paymeeService.postPayment(payment).subscribe({
            next: (value: ResultPaymeeInterface) => {
              const payment: AdPaymentDetailsInterface = {
                justificationFile: null,
                paymentMethod: this.paymentMethod,
                paymentToken: value.data.token,
                campaignHashedId: this.campaignHashedId,
              };
              this.store.dispatch(
                invokeUpadateAdPaymentDetails({
                  payment: payment,
                })
              );
              window.location.href = value.data.payment_url;
            },
            error: (err: ErrorPostPaymeeInterface) => {
              this.toastr.error(
                err.details,
                this.translate.instant("response.errorTitle")
              );
            },
          });
          break;
        }

        case AdvertisingPaymentMethodEnum.KONNECT: {
          const payment: PostKonnectInterface = {
            receiverWalletId: environment.PAYMENT.APP_KONNECT.WALLET,
            amount: Math.floor(Number(this.totalCost.toFixed(3)) * 1000),
            email: this.profile.email.toLowerCase(),
            firstName: this.profile.firstName,
            lastName: this.profile.lastName,
            orderId: this.campaignHashedId,
            phoneNumber: this.profile.phone.toString(),
            token: "TND",
            type: "immediate",
            acceptedPaymentMethods: ["wallet", "bank_card", "e-DINAR"],
            lifespan: 20,
            checkoutForm: false,
            addPaymentFeesToAmount: false,
            silentWebhook: true,
            theme: "light",
            successUrl:
              environment.PAYMENT.RETURN_URL + "?estimateId=" + this.estimateId,
            webhook: environment.PAYMENT.WEBHOOK_URL,
            failUrl: environment.PAYMENT.CANCEL_URL,
          };
          this.konnectService.postPayment(payment).subscribe({
            next: (value: ResultKonnectInterface) => {
              const payment: AdPaymentDetailsInterface = {
                justificationFile: null,
                paymentMethod: this.paymentMethod,
                paymentToken: value.paymentRef,
                campaignHashedId: this.campaignHashedId,
              };
              this.store.dispatch(
                invokeUpadateAdPaymentDetails({
                  payment: payment,
                })
              );
              window.location.href = value.payUrl;
              this.loading = false;
            },
            error: (err: ErrorPostKonnectInterface) => {
              this.toastr.error(
                err.errors.map((value) => value.message).toString(),
                this.translate.instant("response.errorTitle")
              );
              this.loading = false;
            },
          });
          break;
        }
        case AdvertisingPaymentMethodEnum.BANK_TRANSFER: {
          this.router.navigate([
            "/",
            "dashboard",
            "advertiser",
            "advertising-campaigns",
            "payment-success",
          ]);
          break;
        }
        case AdvertisingPaymentMethodEnum.NONE:
          break;
      }
    }
  }
  changeStatus(){
    if(this.adStatus == CampaignStatusEnum.DRAFT){
      this.store.dispatch(invokeChangeAdStatus({
        campaignHashedId : this.campaignHashedId,
        status : CampaignStatusEnum.AWAITING_PAYMENT
      }))
      this.adStatus = CampaignStatusEnum.AWAITING_PAYMENT
    }
  }

  unMuteVideo(){
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: { ...this.adDetails, isMuted : false },
      })
    );
  }

  muteVideo(){
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: { ...this.adDetails, isMuted : true },
      })
    );
  }
}
