<div class="modal-header">
  <div>
    <h4 class="modal-title f-w-600" id="exampleModalLabel">
      {{"actions.updateRouter" | translate}} <b>{{ routerToEdit.mac }}</b>
    </h4>
    <h5 class="m-b-0 m-t-10">{{ "enumMapping.updateRouterCards." + selectedOption.toString() | translate}}</h5>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickCloseModal()"
  ></button>
</div>
<div class="modal-body">
  <form class="form theme-form" [formGroup]="accessInformationForm">
    <div *ngIf="selectedOption == SECTION.ADMIN_ROUTER_UPDATE_ACCESS_INFORMATION">
      <app-access-information [isUpdate]="true"
        [form]="accessInformationForm! | asFormGroup"
      ></app-access-information>
    </div>
  </form>
  <form class="form theme-form" [formGroup]="locationInformationForm">
    <div *ngIf="selectedOption == SECTION.ADMIN_ROUTER_UPDATE_LOCATION_INFORMATION">
      <app-location-partner-information [isUpdate]="true"
        [form]="locationInformationForm! | asFormGroup"
      ></app-location-partner-information>
    </div>
  </form>
  <div *ngIf="selectedOption == SECTION.ADMIN_ROUTER_UPDATE_ROUTER_SPECIFICATION">
    <form class="form theme-form" [formGroup]="routerSpecificationForm">
      <app-router-specification [isUpdate]="true"
        [form]="routerSpecificationForm! | asFormGroup"
      ></app-router-specification>
    </form>
    <form class="form theme-form"  [formGroup]="releadForm">
      <div *ngIf="routerToEdit.router.type == routerType.RELEAD_ROUTER; else fsi">
        <app-relead-router
          [form]="releadForm! | asFormGroup"
        ></app-relead-router>
      </div>
    </form>
    <form class="form theme-form"  [formGroup]="fsiRouterForm">
      <ng-template #fsi>
        <app-fsi-router [form]="fsiRouterForm! | asFormGroup"></app-fsi-router>
      </ng-template>
    </form>
  </div>
  <div class="media">
    <button class="btn btn-outline-primary m-t-20" (click)="onClickRetour()">
      {{ "buttons.return" | translate }}
    </button>
    <button
      class="btn btn-primary button-right m-t-20"
      type="submit"
      (click)="saveChanges()"
      [disabled]="isDisableButton()"
    >
      {{ "buttons.confirm" | translate }}
    </button>
  </div>
</div>
