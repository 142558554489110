import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { adDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { AdDetailsInterface } from "../../models/advertising-campaigns/ad-details.interface";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-ad-video-preview",
  templateUrl: "./ad-video-preview.component.html",
  styleUrls: ["./ad-video-preview.component.scss"],
})
export class AdVideoPreviewComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  mute: boolean = true;
  //@Input("videoSrc") videoSrc: Observable<File>;
  @ViewChild("myVideo", { static: true }) myVideo: ElementRef;
  @ViewChild("phone") phone: ElementRef;
  @ViewChild("muteIcon") muteIcon: ElementRef<HTMLImageElement>;

  @Input("changeMuting") changeMuting: Observable<any>;

  imgWidth: number;
  imgHeight: number;
  phoneHeight: number = 500;
  phoneWidth: number = 250;
  loadingVideo: boolean = true;
  constructor(
    private renderer: Renderer2,
    private store: Store<AppStateInterface>
  ) {
    super(store);
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngAfterViewInit() {
    const imgElement = this.phone.nativeElement;
    const videoElement = this.myVideo.nativeElement;
    this.renderer.listen(videoElement, "loadedmetadata", () => {
      const videoAspectRatio =
        videoElement.videoWidth / videoElement.videoHeight;
      const newImgHeight = imgElement.offsetWidth / videoAspectRatio;
      //imgElement.style.height = newImgHeight + "px";
    });

    videoElement.width = imgElement.offsetWidth;
  }
  adDetails$: Observable<AdDetailsInterface>;

  getAdDetails: AdDetailsInterface = initialState.adDetails;

  ngOnInit(): void {
    const video = this.myVideo.nativeElement;
    this.adDetails$.subscribe((result) => {
      video.muted = result.isMuted
      if (result.videoFile && result.videoFile != this.getAdDetails.videoFile) {
        this.getAdDetails = result;
        this.loadingVideo = false;
        const url = URL.createObjectURL(result.videoFile);
        video.src = url;
        video.load();
      } else if (result.videoLink) {
        video.muted = result.isMuted
        this.loadingVideo = false;
        video.src = environment.CDN_RELEAD + result.videoLink;
        video.load();
      }
    });
    this.imgHeight = 500;
  }

  loadIcon(){
    const video = this.myVideo.nativeElement;
    return video.muted
      ? "assets/identity/muted-button.svg"
      : "assets/identity/unmuted-button.svg";
  }
  toggleMute() {
    const video = this.myVideo.nativeElement;
    const muteIcon = this.muteIcon.nativeElement;
    muteIcon.src = this.loadIcon()
    video.muted = !video.muted;
  }
}
