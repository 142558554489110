<form
  novalidate
  class="container-fluid p-0"
  [formGroup]="form"
  *ngIf="
    objectiveName != objectiveTypeEnum.GENERATION_DE_PROSPECTS;
    else prospect
  "
>
  <aw-wizard
    #wizard
    [disableNavigationBar]="true"
    [navBarLocation]="'bottom'"
    navBarLayout="small"
    [defaultStepIndex]="defaultIndex()"
  >
    <aw-wizard-step [awCompletedStep]="isPublish">
      <app-step1 [form]="form" [objectiveName]="objectiveName"></app-step1>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          [disabled]="true"
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          [disabled]="!form.valid"
          (mouseenter)="checkValidation(1)"
          (click)="next(1)"
        >
          {{ "buttons.next" | translate }}
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step [awCompletedStep]="isPublish">
      <app-step2
        [objectiveName]="objectiveName"
        (valueChanged)="handleValueChanged($event)"
        (fileName)="handleFileName($event)"
        (loadingVideo)="handleLoadingVideo($event)"
        [webSite]="formStep2.get('webSite')?.value"
      ></app-step2>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
          (click)="muteVideo()"
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          awNextStep
          (click)="next(2)"
          [disabled]="
            (!formStep2.get('webSite')?.valid &&
              objectiveName == objectiveTypeEnum.WEB_SITE_TRAFFIC) ||
            loadingVideo
          "
        >
          <loading-button
            [loading]="loadingVideo"
            [value]="'buttons.next' | translate"
            [icon]="null"
          ></loading-button>
        </button>
      </div>
    </aw-wizard-step>

    <aw-wizard-step>
      <app-step3 [idIberis]="idIberis"></app-step3>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
          (click)="unMuteVideo()"
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          [disabled]="estimateNumber == '' && !isCreated"
          awNextStep
          (click)="changeStatus()"
        >
          {{ "buttons.next" | translate }}
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-completion-step awEnableBackLinks>
      <app-step4
        [clientId]="idIberis"
        [campaignHashedId]="campaignHashedID"
        [createEstimate]="createEstimate"
        (resultPaymentMethod)="resultPaymentMethod($event)"
        (emitTotalCost)="totalCostFunction($event)"
      ></app-step4>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          (click)="paymentSelected()"
          [disabled]="
            !paymentMethod || adDetails.validators.conditions == false
          "
          awNextStep
        >
          <loading-button
            [loading]="loading"
            [value]="'buttons.confirm' | translate"
            [icon]="null"
          ></loading-button>
        </button>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</form>
<ng-template #prospect>
  <form novalidate class="container-fluid p-0" [formGroup]="form">
    <aw-wizard
      #wizard
      [disableNavigationBar]="true"
      [navBarLocation]="'bottom'"
      navBarLayout="small"
      [defaultStepIndex]="defaultIndex()"
    >
      <aw-wizard-step [awCompletedStep]="isPublish">
        <app-step1 [form]="form" [objectiveName]="objectiveName"></app-step1>
        <div class="stepper-ad">
          <button
            class="btn btn-outline-primary pull-left me-1"
            type="button"
            [disabled]="true"
          >
            {{ "buttons.return" | translate }}
          </button>
          <button
            class="btn btn-primary pull-right"
            type="button"
            [disabled]="!form.valid"
            (mouseenter)="checkValidation(1)"
            (click)="next(1)"
          >
            {{ "buttons.next" | translate }}
          </button>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [awCompletedStep]="isPublish">
        <app-form
          [defaultItems]="defaultItems"
          [adTitle]="adDetails.title"
          (resultLeadGenerationForm)="saveLead($event)"
        ></app-form>
        <div class="stepper-ad">
          <button
            class="btn btn-outline-primary pull-left me-1"
            type="button"
            awPreviousStep
          >
            {{ "buttons.return" | translate }}
          </button>
          <button
            class="btn btn-primary pull-right"
            type="button"
            (click)="next(2)"
          >
            {{ "buttons.next" | translate }}
          </button>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [awCompletedStep]="isPublish">
        <app-step2
          [objectiveName]="objectiveName"
          (valueChanged)="handleValueChanged($event)"
          (fileName)="handleFileName($event)"
          (loadingVideo)="handleLoadingVideo($event)"
          [webSite]="formStep2.get('webSite')?.value"
        ></app-step2>
        <div class="stepper-ad">
          <button
            class="btn btn-outline-primary pull-left me-1"
            type="button"
            awPreviousStep
            (click)="muteVideo()"
          >
            {{ "buttons.return" | translate }}
          </button>
          <button
            class="btn btn-primary pull-right"
            type="button"
            awNextStep
            (click)="next(3)"
            [disabled]="
              (!formStep2.get('webSite')?.valid &&
                objectiveName == objectiveTypeEnum.WEB_SITE_TRAFFIC) ||
              loadingVideo
            "
          >
            <loading-button
              [loading]="loadingVideo"
              [value]="'buttons.next' | translate"
              [icon]="null"
            ></loading-button>
          </button>
        </div>
      </aw-wizard-step>

      <aw-wizard-step>
        <app-step3 [idIberis]="idIberis"></app-step3>
        <div class="stepper-ad">
          <button
            class="btn btn-outline-primary pull-left me-1"
            type="button"
            awPreviousStep
            (click)="unMuteVideo()"
          >
            {{ "buttons.return" | translate }}
          </button>
          <button
            class="btn btn-primary pull-right"
            type="button"
            [disabled]="estimateNumber == '' && !isCreated"
            awNextStep
            (click)="changeStatus()"
          >
            {{ "buttons.next" | translate }}
          </button>
        </div>
      </aw-wizard-step>
      <aw-wizard-completion-step awEnableBackLinks>
        <app-step4
          [clientId]="idIberis"
          [campaignHashedId]="campaignHashedID"
          [createEstimate]="createEstimate"
          (resultPaymentMethod)="resultPaymentMethod($event)"
          (emitTotalCost)="totalCostFunction($event)"
        ></app-step4>
        <div class="stepper-ad">
          <button
            class="btn btn-outline-primary pull-left me-1"
            type="button"
            awPreviousStep
          >
            {{ "buttons.return" | translate }}
          </button>
          <button
            class="btn btn-primary pull-right"
            type="button"
            (click)="paymentSelected()"
            [disabled]="
              !paymentMethod || adDetails.validators.conditions == false
            "
            awNextStep
          >
            <loading-button
              [loading]="loading"
              [value]="'buttons.confirm' | translate"
              [icon]="null"
            ></loading-button>
          </button>
        </div>
      </aw-wizard-completion-step>
    </aw-wizard>
  </form>
</ng-template>
