import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { OptionActionInterface } from "src/app/shared/models/option-action.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  saveOptionsSelector,
  updateFieldsSelector,
} from "src/app/store/settings/settings.selectors";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { CreateLocationPartnerComponent } from "src/app/components/admin/location-partners/create-location-partner/create-location-partner.component";
import {
  invokeAllAdvertiserByFilter,
  invokeUpdateAdvertiser,
  resultUpdateAdvertiser,
} from "src/app/store/advertiser/advertiser.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ToastrService } from "ngx-toastr";
import { SubRoleInterface } from "src/app/shared/models/sub-role/subRole.interface";
import { createUpdateBillingInfo } from "src/app/store/billing/billing.actions";
import { BillingInterface } from "src/app/shared/models/billing/billing.interface";
import { UpdateRouterComponent } from "src/app/components/admin/router/update-router/update-router.component";
import {
  invokeRouters,
  invokeUpdateRouter,
  updateRouterSuccess,
} from "src/app/store/router/router.action";
import { TranslateService } from "@ngx-translate/core";
import { TypeCards } from "src/app/shared/enum/typeCards";
import { UpdateLocationPartnerComponent } from "src/app/components/admin/location-partners/update-location-partner/update-location-partner.component";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import {
  ActionInterface,
  OptionActionEnum,
} from "src/app/shared/data/actions-data";
import { invokeUpdateLocationPartner } from "src/app/store/locationPartner/actions/update.actions";
import { checkUserExistByEmailSelector } from "src/app/store/user/user.selectors";
import { RouterToUpdateInterface } from "src/app/shared/models/router/router-update.interface";
import { routerUpdateSuccessMessageSelector } from "src/app/store/router/router.selectors";
import { UpdateAdvertiserComponent } from "src/app/components/admin/advertisers/update-advertiser/update-advertiser.component";
import { resultUpdateAdvertiserSlector } from "src/app/store/advertiser/advertiser.selectors";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";

@Component({
  selector: "app-update",
  templateUrl: "./update.component.html",
})
export class UpdateComponent extends BaseComponent implements OnInit {
  updateFields$: Observable<OptionActionInterface | null>;
  updateFields: OptionActionInterface;

  advertiserEditProfileSuccess$: Observable<SuccessDto | null>;

  subRole: SubRoleInterface;
  routerType: boolean;
  routerToEdit: RouterToUpdateInterface | null = null;
  successMessageRouter$: Observable<string | null>;

  /** */
  saveOptions$: Observable<ActionInterface[] | null>;
  saveOption: ActionInterface[];
  @Input() type: TypeCards;
  @Input() title: string;
  @Input() isCol4: boolean;
  @Input() reference: string;
  option = OptionActionEnum;

  @Input() locationPartner: LocationPartnerInterface;

  isExist$: Observable<boolean | null>;
  typeCard = TypeCards;
  @Input() email: string;

  advertiser: AdvertiserDetailsInterface;

  billingInfo: BillingInterface | null = null;
  constructor(
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.updateFields$ = this.store
      .pipe(select(updateFieldsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.successMessageRouter$ = this.store
      .pipe(select(routerUpdateSuccessMessageSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.saveOptions$ = this.store
      .pipe(select(saveOptionsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isExist$ = this.store
      .pipe(select(checkUserExistByEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.advertiserEditProfileSuccess$ = this.store
      .pipe(select(resultUpdateAdvertiserSlector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    
    this.isExist$.subscribe((data) => {
      if (data == true) {
        this.toastr.error(
          this.translate.instant("response.error.email-exist"),
          this.translate.instant("response.errorTitle")
        );
      }
    });

    this.saveOptions$.subscribe((data) => {
      if (data) {
        this.saveOption = data;
      }
    });

    this.updateFields$.subscribe((result) => {
      if (result) {
        this.updateFields = result;
      }
    });

    this.successMessageRouter$.subscribe((value) => {
      if (value != null) {
        this.toastr.success(
          this.translate.instant("response.success." + value),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(invokeRouters());
        this.store.dispatch(updateRouterSuccess({ successMessage: null }));
      }
    });

    this.advertiserEditProfileSuccess$.subscribe(
      (message: SuccessDto | null) => {
        if (message) {
          this.toastr.success(
            this.translate.instant("response.success." + message.message),
            this.translate.instant("response.successTitle")
          );

          this.store.dispatch(invokeAllAdvertiserByFilter());
          this.store.dispatch(resultUpdateAdvertiser({ successMessage: null }));

          this.onClickCloseModal();
        }
      }
    );
  }
  onClickOpenModal(field: OptionActionEnum) {
    switch (this.type) {
      case TypeCards.ADMIN_LOCATION_UPDATE: {
        this.onClickCloseModal();
        const modalRef = this.modalService.open(
          UpdateLocationPartnerComponent,
          {
            centered: true,
          }
        );
        modalRef.componentInstance.locationPartner = this.locationPartner;
        modalRef.componentInstance.email = this.email;
        modalRef.componentInstance.selectedOption = field;
        break;
      }
      case TypeCards.ADMIN_ROUTER_UPDATE: {
        this.onClickCloseModal();
        const modalRef = this.modalService.open(UpdateRouterComponent, {
          centered: true,
        });

        modalRef.componentInstance.routerToEdit = this.routerToEdit;
        modalRef.componentInstance.selectedOption = field;
        break;
      }
      case TypeCards.ADMIN_ADVERTISER_UPDATE: {
        this.onClickCloseModal();
        const modalRef = this.modalService.open(UpdateAdvertiserComponent, {
          centered: true,
        });

        modalRef.componentInstance.selectedOption = field;
        modalRef.componentInstance.advertiser = this.advertiser;
        modalRef.componentInstance.email = this.email;
        modalRef.componentInstance.billingInfo = this.billingInfo;
        break;
      }
      case TypeCards.ADMIN_LOCATION_CREATE: {
        this.onClickCloseModal();
        const modalRef = this.modalService.open(
          CreateLocationPartnerComponent,
          {
            centered: true,
          }
        );
        modalRef.componentInstance.selectedOption = field;
        break;
      }
    }
  }
  onClickCloseModal() {
    this.modalService.dismissAll(UpdateComponent);
  }
  onClickUpdate() {
    this.onClickCloseModal();
    if (this.advertiser != undefined) {
      if (this.billingInfo) {
        const billingToUPdate: BillingInterface = {
          email: this.billingInfo.email,
          address: this.billingInfo.address,
          taxRegistration: this.billingInfo.taxRegistration,
          legalName: this.billingInfo.legalName,
        };

        this.store.dispatch(
          createUpdateBillingInfo({ billing: billingToUPdate })
        );
      }

      this.store.dispatch(
        invokeUpdateAdvertiser({
          email: this.email,
          profileDetails: {
            companyActivity: this.advertiser?.companyActivity,
            companyName: this.advertiser.companyName,
            email: this.advertiser.email.toLowerCase(),
            phone: this.advertiser.phone,
            companyAddress: this.advertiser.companyAddress,
            website: this.advertiser.website,
            logo: this.advertiser?.logo,
            firstName: this.advertiser?.firstName,
            lastName: this.advertiser.lastName,
            creationDate: this.advertiser.creationDate,
           // activated: this.advertiser.activated,
            birthday: this.advertiser.birthday,
            iberis: this.advertiser?.iberis,
            companyRole: this.advertiser?.companyRole,
            verified : this.advertiser.verified,
            status : this.advertiser.status
          },
        })
      );
      this.saveHistory.saveUserHistory(
        UserHistoryActionsEnum.PATCH,
        UserHistoryServicesEnum.UPDATE_ADVERTISER
      );
    }
    if (this.routerToEdit != null) {
      this.store.dispatch(
        invokeUpdateRouter({
          oldMAC: this.routerToEdit.mac,
          routerDto: this.routerToEdit.router,
        })
      );
      this.saveHistory.saveUserHistory(
        UserHistoryActionsEnum.PATCH,
        UserHistoryServicesEnum.UPDATE_ROUTER
      );
    }
    switch (this.type) {
      case TypeCards.ADMIN_LOCATION_UPDATE: {
        this.store.dispatch(
          invokeUpdateLocationPartner({
            email: this.email,
            locationPartnerDto: this.locationPartner,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.PATCH,
          UserHistoryServicesEnum.UPDATE_LOCATION
        );
      }
    }
  }
}
