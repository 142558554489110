import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable, of, Subject, takeUntil } from "rxjs";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { updateAdCampaignDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeUpdateAdCampaignDetails,
  updateAdCampaignDetails,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeUploadFileWithProgress,
  setFileName,
} from "src/app/store/file/file.actions";
import { uploadFileProgressSelector } from "src/app/store/file/file.selectors";
import { invokeLocationsToCreateCampaign } from "src/app/store/locationPartner/actions/get-locations.actions";
import { locationPartnersToCreateCampaignResultSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { catchServerError } from "src/app/store/user/user.actions";
import { catchServerErrorSelector } from "src/app/store/user/user.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-update-campaign",
  templateUrl: "./update-campaign.component.html",
  styleUrls: ["./update-campaign.component.scss"],
})
export class UpdateCampaignComponent implements OnInit, OnDestroy {
  form: FormGroup;
  @Input() ad: AdvertisingCampaignInterface;
  validate: boolean;
  showAlert: boolean = false;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  files: File[] = [];
  showError: boolean = false;
  isShown: boolean = false;
  filename: string | null = null;
  approveInProgress: boolean;
  uploadFile$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  submitted: boolean;
  update$: Observable<SuccessDto | null>;
  updated: string;

  locationPartnersResult$: Observable<LocationPartnerInterface[] | null>;
  locations: string[];
  objectiveEnum = ObjectiveTypeEnum;

  loading: boolean = false;
  catchServerErreur$: Observable<boolean | null>;

  videoLink: string;
  ngDestroyed$: Subject<void> = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private extensionService: ExtensionService,
    private translate: TranslateService,
    private saveHistory: SaveUserHistoryService,
    configPB: NgbProgressbarConfig
  ) {
    configPB.max = 100;
    configPB.striped = true;
    configPB.animated = true;
    configPB.type = "primary";
    configPB.height = "20px";
    this.form = fb.group({
      redirectionLink: [null],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      locations: [[], [Validators.required]],
    });
    this.update$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFile$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnersResult$ = this.store
      .pipe(select(locationPartnersToCreateCampaignResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.catchServerErreur$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
  loadImage(): Observable<Blob> {
    return of(environment.CDN_RELEAD + this.ad.videoLink).pipe(
      map((data) => {
        const blob = new Blob([data], {
          type: `video/${this.extensionService.getExtension(
            this.ad.videoLink
          )}`,
        });
        return blob;
      })
    );
  }
  ngOnInit(): void {
    this.catchServerErreur$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });

    this.store.dispatch(invokeLocationsToCreateCampaign());
    this.form.patchValue({ redirectionLink: this.ad.redirectionLink });
    this.form.patchValue({ startDate: this.ad.startingDate });
    this.form.patchValue({ endDate: this.ad.endingDate });

    if (this.ad.videoLink) {
      this.loadImage().subscribe((i) => {
        const myFile = new File(
          [i],
          this.translate.instant("campaignDetails.video") + "-" + this.ad.title,
          {
            type: i.type,
          }
        );
        this.files.push(myFile);
      });
    }

    this.locationPartnersResult$.subscribe(
      (result: LocationPartnerInterface[] | null) => {
        if (result != null) {
          this.locations = result.map((value) => value.companyName);

          this.form.patchValue({ locations: this.ad.locationPartners });
        }
      }
    );
    this.videoLink = environment.CDN_RELEAD + this.ad.videoLink;
    this.filename = this.ad.videoLink;
    this.uploadFile$.subscribe(
      (result: HttpEvent<any> | null | HttpResponse<{ filename: string }>) => {
        if (result?.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          const total = result?.total ?? 1;
          this.uploadProgress = Math.round((result.loaded / total) * 100);
        }
        if (result?.type == HttpEventType.Response) {
          this.filename =
            (result as HttpResponse<{ filename: string }>)?.body?.filename ??
            "";
          this.submitted = true;
          this.loading = false;
          this.store.dispatch(setFileName({ filename: null }));
          setTimeout(() => {
            this.showProgress = false;
          }, 2000);
        }
      }
    );

    this.update$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.updated = result.message;
        this.loading = false;
        this.store.dispatch(updateAdCampaignDetails({ successMessage: null }));
        this.close();
      }
    });
  }
  update() {
    this.loading = true;
    let adCampaign: any = {
      title: this.ad.title,
      startingDate: this.form.get("startDate")?.value,
      endingDate: this.form.get("endDate")?.value,
      publishDate: this.ad.publishDate,
      redirectionLink: this.form.get("redirectionLink")?.value,
      status: this.ad.status,
      locationPartners: this.form.get("locations")?.value,
      views: this.ad.views,
      objectiveName: this.ad.objectiveName,
      orderReference: this.ad.orderReference,
      synthesisReport: this.ad.synthesisReport,
      videoLink: this.filename,
    };
    if (this.ad.advertisingCampaignHashedId) {
      this.store.dispatch(
        invokeUpdateAdCampaignDetails({
          campaignHashedId: this.ad.advertisingCampaignHashedId,
          adCompaign: adCampaign,
        })
      );
      this.saveHistory.saveUserHistory(
        UserHistoryActionsEnum.PATCH,
        UserHistoryServicesEnum.ADMIN_CAMPAIGN_ADVERTISER_UPDATE
      );
    }
    this.uploadProgress = 0;
  }
  onSelect(event: File[]) {
    this.showProgress = true;
    if (event[0].size > 5242880){
      this.showError = true; 
      this.showProgress = false;
    }
    else {
      this.files.push(event[0]);
      this.isShown = true;
      const formData = new FormData();
      formData.append("file", this.files[0], this.files[0].name);
      this.store.dispatch(invokeUploadFileWithProgress({ file: formData }));
    }
  }
  onRemove() {
    this.files = [];
    this.filename = null;
  }
  close() {
    this.files = [];
    this.modalService.dismissAll();
  }
}
