import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { select, Store } from "@ngrx/store";
import {
  deleteAdvertisingCampaignSelector,
  filterAdvertisingCompaignSelector,
  loadingAdvertisingCampaignSelector as loadingAdvertisingCampaignSelector,
  loadingAdvertisingCampaignTableSelector,
  resultFilterAdvertisingCompaignSelector,
  successUploadFileSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  advertisingCompaignResult,
  deleteAdCampaign,
  invokeAdvertisingCompaignByHashedId,
  invokeAdvertisingCompaignFilter,
  resultSuccessAd,
  resultUploadJustificationFile,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { FilterListAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { ResultFilterAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/result-filter-advertising-compaign.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { JwtService } from "src/app/shared/services/jwt.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  CampaignStatusEnum,
  CampaignStatusEnumMapping,
} from "src/app/shared/enum/campaign-status";
import {
  ObjectiveTypeEnum,
  ObjectiveTypeEnumMapping,
} from "src/app/shared/enum/objectives";
import { TranslateService } from "@ngx-translate/core";
import {
  advertisingCampaignsFilter_Advertiser,
  FilterInterface,
  typeFilter,
} from "src/app/shared/data/filter-data";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import {
  ActionInterface,
  ActionReturned,
  OptionActionEnum,
  advertiserCampaignAwaitingApprovalActions,
  advertiserCampaignAwaitingPaymentActions,
  advertiserCampaignDraftActions,
  advertiserCampaignLastActions,
} from "src/app/shared/data/actions-data";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteComponent } from "src/app/shared/components/drob-zone-options/modal/delete/delete.component";
import { Router } from "@angular/router";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ToastrService } from "ngx-toastr";
import { UploadFilesComponent } from "src/app/shared/components/upload-files/upload-files.component";
import {
  invokeDownloadFile,
  resultDownloadFile,
} from "src/app/store/file/file.actions";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { environment } from "src/environments/environment";
import { invokeDownloadEstimate } from "src/app/store/commande/commande.actions";
import { resultDownloadEstimateSelector } from "src/app/store/commande/commande.selectors";
import { downloadedFileSelector } from "src/app/store/file/file.selectors";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
@Component({
  selector: "app-insights",
  templateUrl: "./insights.component.html",
})
export class InsightsComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;

  objectiveTypeEnumMapping = ObjectiveTypeEnumMapping;
  columns: IColumn[] = [
    {
      key: "title",
      label: "campaignDetails.campaignName",
      sorter: true,
    },
    {
      key: "impressions",
      label: "dashboard.vues",
      sorter: false,
    },
    {
      key: "objective",
      label: "campaignDetails.objective",
      sorter: true,
    },
    {
      key: "locations",
      label: "campaignDetails.location",
      sorter: true,
    },
    {
      key: "startingDate",
      label: "campaignDetails.startDate",
      sorter: true,
    },
    {
      key: "endingDate",
      label: "campaignDetails.endDate",
      sorter: true,
    },
    {
      key: "status",
      label: "dashboard.status",
      sorter: true,
    },
  ];
  loadingAds$: Observable<boolean | null>;
  loading$: Observable<boolean | null>;
  pageSize: number = 10;
  pageNumber: number = 1;
  advertisingCompaignFilter$: Observable<FilterListAdvertisingCompaign>;
  advertisingCompaignFilter: FilterListAdvertisingCompaign = {
    ...initialState.filter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  resultFilterAdvertisingCompaign$: Observable<ResultFilterAdvertisingCompaign | null>;
  resultAdvertisingCompainFilter: AdvertisingCampaignInterface[] | null = [];

  deleteAd$: Observable<SuccessDto | null>;

  formFilter: FormGroup;
  isFiltring: boolean | null;
  active = 1;
  interface: string[];
  count: number;
  filterAds: FilterInterface = advertisingCampaignsFilter_Advertiser;
  specificColumun: number;

  downloadFile$: Observable<any>;
  justificationFile: string | null;
  successUploadJustificationFile$: Observable<SuccessDto | null>
  cachedAt : Date | null
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    public jwtService: JwtService,
    private fb: FormBuilder,
    protected translate: TranslateService,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private extensionService: ExtensionService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.deleteAd$ = this.store
      .pipe(select(deleteAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.advertisingCompaignFilter$ = this.store
      .pipe(select(filterAdvertisingCompaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultFilterAdvertisingCompaign$ = this.store.pipe(
      select(resultFilterAdvertisingCompaignSelector)
    );
    this.loadingAds$ = this.store
      .pipe(select(loadingAdvertisingCampaignTableSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.deleteAd$ = this.store
      .pipe(select(deleteAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.downloadEstimate$ = this.store
      .pipe(select(resultDownloadEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.downloadFile$ = this.store
      .pipe(select(downloadedFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.successUploadJustificationFile$ = this.store
      .pipe(select(successUploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.formFilter = this.fb.group({
      title: [""],
      date: [[null, null]],
    });
  }
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  ngOnInit(): void {
    this.store.dispatch(resultSuccessAd({ successMessage: null }));
    Object.values(ObjectiveTypeEnum).map((item) => {
      return {
        id: item,
        value: ObjectiveTypeEnumMapping[item],
      };
    });
    this.filterAds.name.map((e) => {
      if (e.type == typeFilter.STATUS) {
        e.data = Object.keys(CampaignStatusEnum)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              CampaignStatusEnumMapping[key as keyof typeof CampaignStatusEnum]
          );
      }
      if (e.type == typeFilter.OBJECTIVE) {
        e.data = Object.values(ObjectiveTypeEnum)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              ObjectiveTypeEnumMapping[key as keyof typeof ObjectiveTypeEnum]
          );
      }
    });
    this.store.dispatch(
      advertisingCompaignResult({ listAdvertisingCompaign: null })
    );
    this.loadingAds$.subscribe((data) => {});
    this.loading$.subscribe((data) => {});
    this.formFilter.valueChanges.subscribe((changes) => {
      this.advertisingCompaignFilter = {
        ...this.advertisingCompaignFilter,
        startingDate: changes.date[0],
        endingDate: changes.date[1],
        title: changes.title,
      };
      return this.filter();
    });
    this.resultFilterAdvertisingCompaign$.subscribe(
      (result: ResultFilterAdvertisingCompaign | null) => {
        if (result) {
          this.cachedAt = result.cachedAt
          this.resultAdvertisingCompainFilter = result.advertisingCampaigns;
          this.count = result.count;
          if (this.advertisingCompaignFilter.pager.pageSize == 0) {
            this.advertisingCompaignFilter = {
              ...this.advertisingCompaignFilter,
              pager: {
                ...this.advertisingCompaignFilter.pager,
                pageSize: result.count,
              },
            };
          }
        } else {
          this.store.dispatch(
            invokeAdvertisingCompaignFilter({
              filter: {
                ...this.advertisingCompaignFilter,
                email: this.jwtService.getEmail(),
                pager: {
                  pageSize: this.pageSize,
                  pageNumber: this.pageNumber,
                },
              },
              refreshCache : false
            })
          );
          this.saveHistory.saveUserHistory(
            UserHistoryActionsEnum.GET,
            UserHistoryServicesEnum.LIST_CAMPAIGNS
          );
        }
      }
    );
    this.deleteAd$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.store.dispatch(
          resultUploadJustificationFile({ successUpload: null })
        );
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(
          invokeAdvertisingCompaignFilter({
            filter: this.advertisingCompaignFilter,
            refreshCache : false
          })
        );
        this.modalService.dismissAll();
        this.store.dispatch(deleteAdCampaign({ successMessage: null }));
      }
    });
    this.downloadFile$.subscribe((result) => {
      if (result) {
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${
          this.justificationFile
        }.${this.extensionService.getExtension(result.url)}`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
        this.justificationFile = null;
        this.store.dispatch(resultDownloadFile({ downloadedFile: null }));
      }
    });
    this.successUploadJustificationFile$.subscribe((success: SuccessDto | null) => {
      if (success != null) {
        this.store.dispatch(
          resultUploadJustificationFile({ successUpload: null })
        );
        this.toastr.success(
          this.translate.instant("response.success." + success.message),
          this.translate.instant("response.successTitle")
        );
        this.filter()
      }
    });
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.pageNumber = event
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          email: this.jwtService.getEmail(),
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageNumber: event,
          },
        },
        refreshCache : false
      })
    );
  }
  itemsPerPageChange(pageSize: number) {
    this.advertisingCompaignFilter = {
      ...this.advertisingCompaignFilter,
      email: this.jwtService.getEmail(),
      pager: {
        ...this.advertisingCompaignFilter.pager,
        pageSize: pageSize,
      },
    };
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
        },
        refreshCache : false
      })
    );
  }
  get changePageSize() {
    return this.advertisingCompaignFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          email: this.jwtService.getEmail(),
          sortName: column,
          isAscending: sort,
        },
        refreshCache : false
      })
    );
  }
  filter() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          email: this.jwtService.getEmail(),
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageNumber: this.pageNumber,
          },
        },
        refreshCache : false
      })
    );
  }
  removeFilter() {
    this.store.dispatch(
      advertisingCompaignResult({ listAdvertisingCompaign: null })
    );
    this.formFilter.reset({
      companyName: "",
      email: "",
      lastName: "",
      firstName: "",
      companyAddress: "",
      companyActivity: "",
    });
    this.isFiltring = false;
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...initialState.filter,
          email: this.jwtService.getEmail(),
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
        refreshCache : false
      })
    );
  }
  resultOptionSelected(options: any[]) {
    let listStatus: any[] = [];
    let listObjective: string[] = [];
    options?.map((option) => {
      const keyFound = Object.keys(CampaignStatusEnumMapping).find(
        (key) => CampaignStatusEnumMapping[key as CampaignStatusEnum] === option
      );
      if (keyFound) listStatus = [...listStatus, keyFound];
    });
    options?.map((option) => {
      const keyFound = Object.keys(ObjectiveTypeEnumMapping).find(
        (key) => ObjectiveTypeEnumMapping[key as ObjectiveTypeEnum] === option
      );
      if (keyFound) listObjective = [...listObjective, keyFound];
    });
    this.advertisingCompaignFilter = {
      ...this.advertisingCompaignFilter,
      status: listStatus.filter((value: string | null) => value !== null),
      objectiveName: listObjective.filter(
        (value: string | null) => value !== null
      ),
    };
    this.filter();
  }
  saveAction(action: ActionReturned, ad: AdvertisingCampaignInterface) {
    switch (action.option.type) {
      case OptionActionEnum.CAMPAIGN_DETAILS: {
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "insights",
          "statistics",
          ad.advertisingCampaignHashedId,
        ]);
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGN_PUBLISH: {
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "advertising-campaigns",
          "create-ad",
          "payment",
          ad.advertisingCampaignHashedId,
        ]);
        break;
      }
      case OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE: {
        const modal = this.modalService.open(UploadFilesComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.campaignHashedId =
          ad.advertisingCampaignHashedId;
        modal.componentInstance.modalTitle = action.option.name;
        modal.componentInstance.action =
          OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE;
        modal.componentInstance.savedFile = ad.justificationFile
        modal.componentInstance.accept = "application/pdf, image/*"
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE: {
        this.justificationFile = ad.title;
        this.store.dispatch(
          invokeDownloadFile({ filename: ad.justificationFile })
        );
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE: {
        this.downloadEstimate(ad.title, ad.estimateId);
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_INVOICE: {
        this.router.navigate(["/", "dashboard", "advertiser", "payments"]);
        break;
      }
      case OptionActionEnum.CAMPAIGN_DELETE: {
        const modal = this.modalService.open(DeleteComponent, {
          centered: true,
        });
        modal.componentInstance.name = "pop-up.advertisingCampaign.title";
        modal.componentInstance.reference = ad.title;
        modal.componentInstance.CampaignHashedId =
          ad.advertisingCampaignHashedId;
        modal.componentInstance.type = OptionActionEnum.CAMPAIGN_DELETE;
        modal.componentInstance.title = action.option.name;
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGN_UPDATE: {
        this.store.dispatch(
          invokeAdvertisingCompaignByHashedId({
            campaignHashedId: ad.advertisingCampaignHashedId,
          })
        );
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "advertising-campaigns",
          "update-ad",
          ad.advertisingCampaignHashedId,
        ]);
      }
    }
  }

  downloadEstimate$: Observable<any>;
  blob: Blob;
  downloadEstimate(title: string, estimateId: string) {
    this.store.dispatch(
      invokeDownloadEstimate({ params: this.params, estimateId: estimateId })
    );
    this.downloadEstimate$.subscribe((result) => {
      if (result) {
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${title + "-devis"}.pdf`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
      }
    });
  }
  getOptions(advertising: AdvertisingCampaignInterface): ActionInterface[] {
    switch (advertising.status) {
      case CampaignStatusEnum.FINISHED:
      case CampaignStatusEnum.ONGOING:
      case CampaignStatusEnum.WAITING: {
        var actions: ActionInterface[] = advertiserCampaignLastActions
        if (!advertising.justificationFile)
          actions = actions.filter(
            (value) =>
              value.type !=
              OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
          );

        return actions;
      }

      case CampaignStatusEnum.DRAFT:
        return advertiserCampaignDraftActions;

      case CampaignStatusEnum.AWAITING_PAYMENT:
        return advertiserCampaignAwaitingPaymentActions;
      case CampaignStatusEnum.AWAITING_APPROVAL: {
        var actions: ActionInterface[] = advertiserCampaignAwaitingApprovalActions
        if (advertising.justificationFile == null) {
          actions = actions.filter(
            (value) =>
              value.type !=
              OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
          );
        }

        return actions;
      }
    }
  }

  refreshCampaigns(refreshCache : boolean){
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...initialState.filter,
          email: this.jwtService.getEmail(),
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
        refreshCache : refreshCache
      })
    );
  }
}
