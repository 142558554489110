import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/base.component";
import { Observable, takeUntil } from "rxjs";
import { AppStateInterface } from "src/app/store/appState.interface";
import { select, Store } from "@ngrx/store";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  sendForgetPasswordLoaderSelector,
  userSuccessMessageSelector,
} from "src/app/store/user/user.selectors";
import {
  invokeUserChangePassword,
  userSuccessMessage,
} from "src/app/store/user/user.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ToastrService } from "ngx-toastr";
import { MustMatch } from "src/app/shared/validators/passwordMatch";
import { TranslateService } from "@ngx-translate/core";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {
  loading$: Observable<boolean | null>;
  changePasswordSuccessMessage$: Observable<SuccessDto | null>;
  form: FormGroup;
  submitted: boolean = false;

  public showPassword: boolean = false;
  public showNewPassword : boolean = false
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private saveHistory : SaveUserHistoryService
  ) {
    super(store);
    this.loading$ = this.store
      .pipe(select(sendForgetPasswordLoaderSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.changePasswordSuccessMessage$ = this.store
      .pipe(select(userSuccessMessageSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group(
      {
        currentPassword: [
          null,
          [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
        newPassword: [
          null,
          [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
        reNewPassword: [
          null,
          [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
      },
      {
        validator: MustMatch("newPassword", "reNewPassword"),
      }
    );
  }

  ngOnInit(): void {
    this.changePasswordSuccessMessage$.subscribe((message) => {
      if (message)
        this.toastr.success(
          this.translate.instant("response.success." + message.message),
          this.translate.instant("response.successTitle")
        );
      this.store.dispatch(userSuccessMessage({ successMessage: null }));
    });
  }
  get newPassword() {
    return this.form.get("newPassword");
  }
  onSubmitChangePassword() {
    this.submitted = true;
    if (this.form.valid){
      this.store.dispatch(
        invokeUserChangePassword({
          changePassword: {
            currentPassword: this.form.get("currentPassword")?.value,
            newPassword: this.form.get("newPassword")?.value,
          },
        })
      );

      this.saveHistory.saveUserHistory(
        UserHistoryActionsEnum.PATCH,
        UserHistoryServicesEnum.UPDATE_PASSWORD
      );
    }
      
  }
  clickShowPassword() {
    this.showPassword = !this.showPassword;
  }
  clickShowNewPassword() {
    this.showNewPassword = !this.showPassword;
  }
}
