import { createReducer, on } from "@ngrx/store";
import {
  invokeCollaborators,
  invokeCreateCollaborator,
  invokeMainDashboard,
  setCollaborators,
  setCrudCollaboratorSuccess,
  setMainDashboard,
  setProfileSuccess,
  setSideBarNews,
} from "./admin.actions";
import { AdminStateInterface } from "./adminState.interface";

export const initialState: Readonly<AdminStateInterface> = {
  mainDashboard: null,
  loading: false,
  collaborators: [],
  success: null,
  profile: null,
  sideBarNews: {
    ads: 0,
    advertisers: 0,
    support: 0,
    lpCampaigns : 0
  },
};

export const AdminReducer = createReducer(
  initialState,
  on(invokeMainDashboard, (state) => ({
    ...state,
    loading: true,
  })),
  on(setMainDashboard, (state, { mainDashboard }) => ({
    ...state,
    mainDashboard: mainDashboard,
    loading: false,
  })),

  on(invokeCollaborators, (state) => ({
    ...state,
    loading: true,
  })),
  on(setCollaborators, (state, { collaborators }) => ({
    ...state,
    collaborators: collaborators,
    loading: false,
  })),
  on(invokeCreateCollaborator, (state, { collaborator }) => ({
    ...state,
    loading: true,
  })),
  on(setCrudCollaboratorSuccess, (state, { success }) => ({
    ...state,
    success: success,
    loading: false,
  })),
  on(setProfileSuccess, (state, { profile }) => ({
    ...state,
    profile: profile,
  }))
  ,
  on(setSideBarNews, (state, { sideBarNews }) => ({
    ...state,
    sideBarNews: sideBarNews,
  }))
);
