import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { IColumn } from "src/app/shared/models/table/column.interface";
import {
  RoleEnum,
  RoleEnumMapping,
} from "src/app/shared/models/user/role.interface";
import { FilterUserHistoryInterface } from "src/app/shared/models/user-history/filter-user-history.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeUsersHistory,
  setUsersHistoryFilter,
} from "src/app/store/userHistory/userHistory.actions";
import { initialState } from "src/app/store/userHistory/userHistory.reducers";
import {
  usersHistoryFilterSelector,
  usersHistoryLoadingSelector,
  usersHistoryResultSelector,
} from "src/app/store/userHistory/userHistory.selectors";
import { ResultUserHistoryInterface } from "src/app/shared/models/user-history/result-user-history.interface";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  FilterInterface,
  historyFilter,
} from "src/app/shared/data/filter-data";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";

const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
  selector: "app-logger",
  templateUrl: "./logger.component.html",
})
export class LoggerComponent extends BaseComponent implements OnInit {
  toNumber(number: string): number {
    return Number(number);
  }
  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(FILTER_PAG_REGEX, "");
  }
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  pageSize: number = 15;
  pageNumber: number = 1;
  sortedColumns: IColumn[] = [
    {
      key: "email",
      label: "admin.history.email",
      sorter: true,
    },
  ];
  columns: IColumn[] = [
    {
      key: "companyName",
      label: "admin.history.companyName",
      sorter: false,
    },
    {
      key: "roleName",
      label: "admin.history.role",
      sorter: false,
    },
    {
      key: "ipAddress",
      label: "admin.history.address",
      sorter: false,
    },
    {
      key: "action",
      label: "Action",
      sorter: false,
    },
    {
      key: "endPoint",
      label: "admin.history.service",
      sorter: false,
    },
  ];
  dateColumn: IColumn[] = [
    {
      key: "creationDate",
      label: "admin.history.date",
      sorter: false,
    },
  ];
  filter: FilterUserHistoryInterface = {
    ...initialState.filter,
    pager: {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    },
  };
  loading$: Observable<boolean | null>;
  formFilter: FormGroup;
  roleEnumMapping = RoleEnumMapping;
  historyResult$: Observable<ResultUserHistoryInterface>;
  historyResult: ResultUserHistoryInterface = {
    count: 0,
    history: [],
    cachedAt: null,
  };
  historyFilter$: Observable<FilterUserHistoryInterface>;
  cachedAt: Date | null;
  historyFilter: FilterInterface = historyFilter;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    public badgeService: BadgeService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);

    this.historyResult$ = this.store
      .pipe(select(usersHistoryResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.historyFilter$ = this.store
      .pipe(select(usersHistoryFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.loading$ = this.store
      .pipe(select(usersHistoryLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      emailOrNameOrCompanyName: [null],
      date: [[null, null]],
    });
  }

  ngOnInit(): void {
    this.historyFilter.name.map(
      (e) =>
        (e.data = Object.keys(RoleEnum)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) => RoleEnumMapping[key as keyof typeof RoleEnum]
          ))
    );
    this.loading$.subscribe((data) => {});
    this.invokeHistory(false);
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          emailOrNameOrCompanyName: null,
          startDate: null,
          endDate: null,
        },
      })
    );
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.LIST_HISTORY
    );
    this.historyResult$.subscribe((data) => {
      this.historyResult = data;
      this.cachedAt = data.cachedAt;
    });

    this.historyFilter$.subscribe((filter) => {
      this.filter = filter;
      this.invokeHistory(false);
    });

    this.formFilter.valueChanges.subscribe((form) => {
      this.store.dispatch(invokeApiDistroyed());
      if(form.emailOrNameOrCompanyName == "") form.emailOrNameOrCompanyName = null
      this.store.dispatch(
        setUsersHistoryFilter({
          filter: {
            ...this.filter,
            emailOrNameOrCompanyName: form.emailOrNameOrCompanyName,
            startDate: form.date[0],
            endDate: form.date[1],
          },
        })
      );
    });
  }
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = null;
        col = null;
    }

    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          sortName: column,
          isAscending: sort,
        },
      })
    );
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          pager: {
            ...this.filter.pager,
            pageSize: pageSize,
          },
        },
      })
    );
  }
  get changePageSize() {
    return this.filter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          pager: {
            ...this.filter.pager,
            pageNumber: event,
          },
        },
      })
    );
  }

  removeFilter() {
    this.store.dispatch(invokeApiDistroyed());
    this.formFilter.reset({
      emailOrNameOrCompanyName: null,
      date: [null, null],
      roleName: null,
    });
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          emailOrNameOrCompanyName: null,
          roleName: null,
          startDate: null,
          endDate: null,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }

  invokeHistory(refreshCache: boolean) {
    this.store.dispatch(invokeUsersHistory({ refreshCache: refreshCache }));
  }

  saveOptionFilter(option: string | null) {
    this.store.dispatch(invokeApiDistroyed());
    let filter: RoleEnum | null = null;
    const KeyFound = Object.keys(RoleEnumMapping).find(
      (key) => RoleEnumMapping[key as RoleEnum] === option
    );
    switch (KeyFound) {
      case RoleEnum.ROLE_ADMIN:
        filter = RoleEnum.ROLE_ADMIN;
        break;
      case RoleEnum.ROLE_ADVERTISER:
        filter = RoleEnum.ROLE_ADVERTISER;
        break;
      case RoleEnum.ROLE_LOCATION:
        filter = RoleEnum.ROLE_LOCATION;
        break;
      default:
        filter = null;
    }
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: { ...this.filter, roleName: filter },
      })
    );
  }
}
