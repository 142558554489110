import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal, NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { map, Observable, of, Subject } from "rxjs";
import {
  invokeAddSynthesisReport,
  invokeUploadJustificationFile,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeUploadFile, invokeUploadFileWithProgress, setFileName } from "src/app/store/file/file.actions";
import {
  uploadFileProgressSelector,
  uploadFileSelector,
} from "src/app/store/file/file.selectors";
import { environment } from "src/environments/environment";
import { OptionActionEnum } from "../../data/actions-data";
import { UserHistoryActionsEnum } from "../../enum/userHistoryActions";
import { UserHistoryServicesEnum } from "../../enum/userHistoryService";
import { ExtensionService } from "../../services/extension/extension.service";
import { SaveUserHistoryService } from "../../services/history/saveHistory";
import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";

@Component({
  selector: "app-upload-files",
  templateUrl: "./upload-files.component.html",
  styleUrls: ["./upload-files.component.scss"],
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  @Output() closeTheModal: EventEmitter<boolean> = new EventEmitter();
  @Input() campaignHashedId: string;
  @Input() modalTitle: string;
  @Input() action: OptionActionEnum;
  showError: boolean = false;
  isShown: boolean = false;
  isUploaded: boolean;
  filename: string | null = null;
  files: File[] = [];
  uploadFile$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  ngDestroyed$: Subject<void> = new Subject<void>();
  savedFile: string;
  accept: string;
  showAlert: boolean = false;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private extensionService: ExtensionService,
    private saveHistory: SaveUserHistoryService,
    configPB: NgbProgressbarConfig
  ) {
    configPB.max = 100;
    configPB.striped = true;
    configPB.animated = true;
    configPB.type = "primary";
    configPB.height = "20px";
    this.uploadFile$ = this.store.pipe(select(uploadFileProgressSelector));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
  loadFile(): Observable<Blob> {
    let type: string;
    switch (this.extensionService.getExtension(this.savedFile)) {
      case "pdf":
      case "pptx":
        type = "application";
        break;
      case "csv":
        type = "text";
        break;
      default:
        type = "image";
    }
    return of(environment.CDN_RELEAD + this.savedFile).pipe(
      map((data) => {
        const blob = new Blob([data], {
          type: `${type}/${this.extensionService.getExtension(this.savedFile)}`,
        });
        return blob;
      })
    );
  }
  ngOnInit(): void {
    this.filename = this.savedFile
    if (this.savedFile) {
      this.loadFile().subscribe((i) => {
        const myFile = new File([i], this.campaignHashedId, {
          type: i.type,
        });
        this.files.push(myFile);
      });
    }
    this.uploadFile$.subscribe(
      (result: HttpEvent<any> | null | HttpResponse<{ filename: string }>) => {
        if (result?.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          const total = result?.total ?? 1;
          this.uploadProgress = Math.round((result.loaded / total) * 100);
        }
        if (result?.type == HttpEventType.Response) {
          this.filename =
            (result as HttpResponse<{ filename: string }>)?.body?.filename ??
            "";
          this.store.dispatch(setFileName({ filename: null }));
          setTimeout(() => {
            this.showProgress = false;
          }, 2000);
        }
      }
    );
  }
  onSelect(event: File[]) {
    if (event[0].size > 4194304) {
      this.showError = true;
      this.showProgress = false;
    } else {
      this.files.push(event[0]);
      this.isShown = true;
      const formData = new FormData();
      formData.append("file", this.files[0], this.files[0].name);
      this.store.dispatch(invokeUploadFileWithProgress({ file: formData }));
    }
  }
  onRemove() {
    this.files = [];
    this.filename = null
  }
  onClickSaveSynthesisReport() {
    switch (this.action) {
      case OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT: {
        this.store.dispatch(
          invokeAddSynthesisReport({
            campaignHashedId: this.campaignHashedId,
            synthesisReport: this.filename,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.UPLOAD,
          UserHistoryServicesEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE: {
        this.store.dispatch(
          invokeUploadJustificationFile({
            campaignHashedId: this.campaignHashedId,
            justificationFile: this.filename,
          })
        );
        this.saveHistory.saveUserHistory(
          UserHistoryActionsEnum.UPLOAD,
          UserHistoryServicesEnum.CAMPAIGN_UPLOAD_JUSTIFICATION_FILE
        );
        break;
      }
    }
    const modal = this.modalService.dismissAll(UploadFilesComponent);
  }
  closeModal() {
    this.closeTheModal.emit(true);
  }
}
