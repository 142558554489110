import { Component, Input, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { BadgeService } from "src/app/shared/services/badge.service";
import { ObjectiveTypeEnumMapping } from "src/app/shared/enum/objectives";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";

@Component({
  selector: "app-card-insights",
  templateUrl: "./card-insights.component.html",
})
export class CardInsightsComponent implements OnInit {
  @Input() ad: AdvertisingCampaignInterface[] | null;
  objectiveTypeEnumMapping = ObjectiveTypeEnumMapping;
  @Input() loading: boolean | null;
  @Input() count: number;
  constructor(
    public badgeService: BadgeService,
    private saveHistory: SaveUserHistoryService
  ) {}
  owlcarouselOptions: OwlOptions = {
    loop: false,
    nav: false,
    autoWidth: true,
    margin: 195,
    responsive: {
      0: {
        items: 1,
      },
      50: {
        items: 1,
        margin: 65,
      },
      150: {
        items: 2,
        margin: 125,
      },
    },
  };
  ngOnInit(): void {
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.LIST_CAMPAIGNS_CARD
    );
  }
}
