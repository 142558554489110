export enum UserHistoryActionsEnum {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PATCH = "PATCH",
  DOWNLOAD = "DOWNLOAD",
  UPLOAD = "UPLOAD",
}

export const UserHistoryActionsEnumMapping: Record<
  UserHistoryActionsEnum,
  string
> = {
  [UserHistoryActionsEnum.GET]: "enumMapping.history.action.GET",
  [UserHistoryActionsEnum.POST]: "enumMapping.history.action.POST",
  [UserHistoryActionsEnum.DELETE]: "enumMapping.history.action.DELETE",
  [UserHistoryActionsEnum.PATCH]: "enumMapping.history.action.PATCH",
  [UserHistoryActionsEnum.DOWNLOAD]: "enumMapping.history.action.DOWNLOAD",
  [UserHistoryActionsEnum.UPLOAD]: "enumMapping.history.action.UPLOAD",
};

export interface UserHistoryActionsInterface {
  id: UserHistoryActionsEnum;
  value: string;
}
