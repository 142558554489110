<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 class="m-b-15" *ngIf="!isUpdate">
    {{ "addRouter.routerSpecificationStep.title" | translate }}
  </h5>
  <div class="form-group">
    <label class="label">{{ "routers.@mac" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="MACAddress"
        class="form-control"
        type="text"
        required
        [placeholder]="
          'addRouter.routerSpecificationStep.placeholderMac' | translate
        "
        [ngClass]="{
          'is-invalid':
            !form.get('MACAddress')?.valid && form.get('MACAddress')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('MACAddress')?.valid && form.get('MACAddress')?.touched
        "
      >
        {{ "validation.MACAddress" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "routers.acquisitionPrice" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="acquisitionPrice"
        class="form-control"
        type="number"
        required
        [placeholder]="
          'addRouter.routerSpecificationStep.placeholderAcquisitionPrice'
            | translate
        "
        [ngClass]="{
          'is-invalid':
            !form.get('acquisitionPrice')?.valid &&
            form.get('acquisitionPrice')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('acquisitionPrice')?.valid &&
          form.get('acquisitionPrice')?.touched
        "
      >
        {{ "validation.acquisitionPrice" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "routers.serialNumber" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="serialNumber"
        class="form-control"
        type="text"
        required
        [placeholder]="
          'addRouter.routerSpecificationStep.placeholderSerialNumber'
            | translate
        "
        [ngClass]="{
          'is-invalid':
            !form.get('serialNumber')?.valid &&
            form.get('serialNumber')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('serialNumber')?.valid && form.get('serialNumber')?.touched
        "
      >
        {{ "validation.serialNumber" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "routers.ssid" | translate }} *</label>
    <div class="input-group">
      <input
        formControlName="SSID"
        class="form-control"
        type="text"
        required
        [placeholder]="
          'addRouter.routerSpecificationStep.placeholderSSID' | translate
        "
        [ngClass]="{
          'is-invalid': !form.get('SSID')?.valid && form.get('SSID')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('SSID')?.valid && form.get('SSID')?.touched"
      >
        {{ "validation.ssid" | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "routers.dateOfPurshase" | translate }} *</label>
    <div class="input-group">
      <div class="input-group date" id="dt-date" data-target-input="nearest">
        <div
          class="input-group-text bg-primary"
          data-target="#dt-date"
          [owlDateTimeTrigger]="dt"
        >
          <i
            class="icofont icofont-ui-calendar"
            style="color: white; font-size: 12px"
          ></i>
        </div>
        <input
          class="form-control datetimepicker-input digits"
          [owlDateTime]="dt"
          [owlDateTimeTrigger]="dt"
          [placeholder]="
            'addRouter.routerSpecificationStep.placeholderDateOfPurshase'
              | translate
          "
          formControlName="dateOfPurchase"
        />
      </div>
    </div>
    <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
  </div>
  <div class="form-group">
    <label class="label"> {{ "routers.status" | translate }} *</label>
    <div class="input-group">
      <select class="form-select form-control digits" formControlName="status">
        <option [ngValue]="null" disabled selected>
          {{
            "addRouter.routerSpecificationStep.placeholderStatus" | translate
          }}
        </option>
        <option
          class="form-select"
          *ngFor="let status of routerStatus"
          [value]="status.id"
        >
          {{ status.value | translate }}
        </option>
      </select>
    </div>
  </div>
</form>
