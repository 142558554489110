import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { DiscountTypeEnum } from "src/app/shared/enum/discount-type.enum";
import { AdvertisingPaymentMethodEnum } from "src/app/shared/enum/paymentMethod";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { PromotionCodeLiteInterface } from "src/app/shared/models/promotion-code/check-promotion-code.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { invokeProfileDetails } from "src/app/store/advertiser/advertiser.actions";
import { advertiserProfileDetailsSelector } from "src/app/store/advertiser/advertiser.selectors";
import {
  adDetailsSelector,
  successUploadFileSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeAdDetails,
  invokeChangeAdStatus,
  invokeUploadJustificationFile,
  resultSuccessAd,
  resultUploadJustificationFile,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeUploadFile,
  invokeUploadFileWithProgress,
  setFileName,
} from "src/app/store/file/file.actions";
import {
  uploadFileProgressSelector,
  uploadFileSelector,
} from "src/app/store/file/file.selectors";
import { getAdHavePromotionCodeSelector } from "src/app/store/promotion-code/promotion-code.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-step4",
  templateUrl: "./step4.component.html",
  styleUrls: ["./step4.component.scss"],
})
export class Step4Component extends BaseComponent implements OnInit {
  success$: Observable<SuccessDto | null>;
  files: File[] = [];

  showError: boolean = false;

  adDetails$: Observable<AdDetailsInterface>;
  adDetails: AdDetailsInterface = {
    ...initialState.adDetails,
  };
  active: number = 1;
  @Input() clientId: string | null;
  @Input() campaignHashedId: string;
  @Input() createEstimate: createEstimate;
  @Output() totalCost: number = 0;
  @Output() emitTotalCost = new EventEmitter<number>();
  conditionsChecked: boolean;
  profile$: Observable<AdvertiserDetailsInterface | null>;
  profile: AdvertiserDetailsInterface;
  loadingEstimate: boolean = false;

  promotionExists$: Observable<PromotionCodeLiteInterface | null>;
  resultPromotionExists: PromotionCodeLiteInterface | null;
  promotion: PromotionCodeLiteInterface | null = null;
  promotionExists: boolean = false;
  termsOfUse = environment.links.termsOfUse;
  fileName: string | null = null;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  showAlert: boolean = false;
  isShown: boolean = false;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translate: TranslateService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.profile$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFile$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.success$ = this.store
      .pipe(select(successUploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.promotionExists$ = this.store
      .pipe(select(getAdHavePromotionCodeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  tva: number;
  priceHT: number;
  discount: number = 0;
  activePayment: AdvertisingPaymentMethodEnum =
    AdvertisingPaymentMethodEnum.NONE;
  @Output("resultPaymentMethod") resultPaymentMethod =
    new EventEmitter<AdvertisingPaymentMethodEnum | null>();
  uploadFile$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  ngOnInit(): void {
    this.profile$.subscribe((user) => {
      if (user) this.profile = user;
      else this.store.dispatch(invokeProfileDetails());
    });
    this.success$.subscribe((success: SuccessDto | null) => {
      if (success != null) {
        this.store.dispatch(
          invokeChangeAdStatus({
            campaignHashedId: this.adDetails.campaignHashedID,
            status: CampaignStatusEnum.AWAITING_APPROVAL,
          })
        );
        this.store.dispatch(
          resultUploadJustificationFile({ successUpload: null })
        );
        this.store.dispatch(resultSuccessAd({ successMessage: null }));
        this.toastr.success(
          this.translate.instant("response.success." + success.message),
          this.translate.instant("response.successTitle")
        );
      }
    });
    this.adDetails$.subscribe((adDetails) => {
      this.priceHT = parseFloat(adDetails.totalCost.toFixed(3));
      this.promotion = adDetails.promotion;
      this.updateTotale();
      this.adDetails = adDetails;
    });
    this.promotionExists$.subscribe((value) => {
      this.resultPromotionExists = value;
      if (value != null && this.promotion == null) {
        if (value.discountValue != 0) this.promotionExists = true;

        this.promotion = value;
        this.updateTotale();
      }
    });
    this.uploadFile$.subscribe(
      (data: HttpEvent<any> | HttpResponse<any> | null) => {
        if (data?.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          const total = data?.total ?? 1;
          this.uploadProgress = Math.round((data.loaded / total) * 100);
        }
        if (data?.type == HttpEventType.Response) {
          this.fileName =
            (data as HttpResponse<{ filename: string }>)?.body?.filename ?? "";
          this.adDetails = {
            ...this.adDetails,
            justificationLink: this.fileName,
          };
          this.store.dispatch(setFileName({ filename: null }));
          setTimeout(() => {
            this.showProgress = false;
          }, 2000);
        }
      }
    );
  }
  updateTotale() {
    this.tva = parseFloat((this.priceHT * 0.19).toFixed(3));
    this.totalCost = this.priceHT + this.tva + 1;
    switch (this.promotion?.discountType) {
      case DiscountTypeEnum.AMOUNT: {
        this.discount = this.promotion.discountValue;
        const newPriceHT = this.priceHT - this.promotion.discountValue;
        this.tva = newPriceHT * 0.19;
        this.totalCost = newPriceHT + this.tva + 1;
        break;
      }
      case DiscountTypeEnum.PERCENTAGE: {
        if (this.promotion.discountValue != 0) {
          var percentageToCalculate: number =
            1 - this.promotion.discountValue / 100;
          const newPriceHT = Number(
            (this.priceHT * percentageToCalculate).toFixed(3)
          );
          this.tva = newPriceHT * 0.19;
          this.totalCost = newPriceHT + this.tva + 1;
          this.discount = this.priceHT * (this.promotion.discountValue / 100);
        }
        break;
      }
    }
  }
  getUnit(type: DiscountTypeEnum | undefined): string {
    switch (type) {
      case DiscountTypeEnum.AMOUNT:
        return "dt";
      case DiscountTypeEnum.PERCENTAGE:
        return "%";
      default:
        return "";
    }
  }

  getPromotionValue(): string {
    return (
      this.promotion?.discountValue +
      " " +
      this.getUnit(this.promotion?.discountType)
    );
  }
  uploadJustificationModal(justificationModal: any) {
    this.modalService.open(justificationModal, {
      animation: true,
      centered: true,
      size: "lg",
    });
  }
  onSelect(file: File[]) {
    if (file[0].size > 5242880) {
      this.showError = true;
      this.showProgress = false;
    } else {
      this.files.push(file[0]);
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: {
            ...this.adDetails,
            justificationFile: file[0],
            paymentMethod: AdvertisingPaymentMethodEnum.BANK_TRANSFER,
          },
        })
      );
      this.isShown = true;
      const formData = new FormData();
      formData.append("file", this.files[0], this.files[0].name);
      this.store.dispatch(invokeUploadFileWithProgress({ file: formData }));
      this.saveHistory.saveUserHistory(
        UserHistoryActionsEnum.POST,
        UserHistoryServicesEnum.CAMPAIGN_UPLOAD_JUSTIFICATION_FILE
      );
    }
  }
  onRemove() {
    this.files = [];
    this.fileName = null
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: {
          ...this.adDetails,
          justificationFile: undefined,
          paymentMethod: AdvertisingPaymentMethodEnum.NONE,
        },
      })
    );
  }
  uploadProof() {
    this.resultPaymentMethod.emit(this.activePayment);
  }
  onChecked(event: any) {
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: {
          ...this.adDetails,
          validators: {
            ...this.adDetails.validators,
            conditions: event.target.checked,
          },
        },
      })
    );
  }
  paymentMethodEnum = AdvertisingPaymentMethodEnum;
  paymentSelected(paymentMethod: AdvertisingPaymentMethodEnum | null) {
    this.resultPaymentMethod.emit(paymentMethod);
    this.emitTotalCost.emit(this.totalCost);
  }
  uploadJustificationFile() {
    this.store.dispatch(
      invokeUploadJustificationFile({
        campaignHashedId: this.adDetails.campaignHashedID,
        justificationFile: this.fileName,
      })
    );
    this.onRemove();
    this.modalService.dismissAll();
  }
}
