import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { CalculateAverageViewsDto } from "src/app/shared/models/location-partners/CalculateAverageViewsDto";
import { BadgeService } from "src/app/shared/services/badge.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  loadingViewsSelector,
  resultCalculateAndUpdateViewsAverageSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { SelectExportLocationsComponent } from "./select-export-locations/select-export-locations.component";
import { invokeCalculateAndUpdateViewAverage } from "src/app/store/locationPartner/actions/update.actions";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
@Component({
  selector: "app-export-location",
  templateUrl: "./export-location.component.html",
})
export class ExportLocationComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  calculateAverageViews: CalculateAverageViewsDto | null;
  calculateViewsAverage$: Observable<string | null>;
  selectedLps: string[] = [];
  sendSuccess$: Observable<string | null>;

  @Input() public sendMail: boolean;

  @Output() closeTheModal: EventEmitter<boolean> = new EventEmitter();
  selectLocations: NgbModalRef;
  loading$: Observable<boolean | null>;
  startDate: Date;
  endDate: Date = new Date();
  constructor(
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    public badgeService: BadgeService,
    private saveHistory : SaveUserHistoryService
  ) {
    super(store);
    this.calculateViewsAverage$ = this.store
      .pipe(select(resultCalculateAndUpdateViewsAverageSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.loading$ = this.store
      .pipe(select(loadingViewsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group({
      startDate: ["", [Validators.required]],
      endDate: [null, [Validators.required]],
    });
  }
  ngOnInit(): void {
  }
  showModal(simpleContent: TemplateRef<any>) {
    const modalRef = this.modalService.open(simpleContent, {
      centered: true,
    });
  }

  openLocations() {
    this.loading$.subscribe((data) => {});
    this.selectLocations = this.modalService.open(
      SelectExportLocationsComponent,
      {
        centered: true,
        size: "lg",
      }
    );
    this.selectLocations.componentInstance.closeTheModal.subscribe(
      (value: string[]) => {
        this.selectedLps = value;
        this.selectLocations.close();
      }
    );
    //this.selectLocations.closed.subscribe((lps) => this.selectedLps);
  }

  confirm(sendMail: boolean) {
    this.calculateAverageViews = {
      companyNames: this.selectedLps,
      startDate: this.form.get("startDate")?.value,
      endDate: this.form.get("endDate")?.value,
    };
    this.store.dispatch(
      invokeCalculateAndUpdateViewAverage({
        calculateAverageViews: this.calculateAverageViews,
        sendMail: sendMail,
      })
    );
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.PATCH,
      UserHistoryServicesEnum.EXPORT_VIEWS_AVERAGE
    );
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  onChange(){
    this.startDate.setDate(new Date(this.form.get("startDate")?.value).getDate() + 1)
    this.endDate = this.form.get("endDate")?.value
  }
}
