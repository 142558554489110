import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { Menu } from "src/app/shared/models/side-bar-menu.interface";
import { LayoutService } from "src/app/shared/services/layout/layout.service";
import { advertiserLoadingSelector } from "src/app/store/advertiser/advertiser.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { Constants } from "../../data/constants";
import { UserHistoryActionsEnum } from "../../enum/userHistoryActions";
import { UserHistoryServicesEnum } from "../../enum/userHistoryService";
import { IberisActivities } from "../../models/iberis/activities";
import { SaveUserHistoryService } from "../../services/history/saveHistory";
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent extends BaseComponent implements OnInit {
  public screenwidth: any = window.innerWidth;

  //RTL and LTR
  public menuItems: Menu[] = [];
  public myBtnIdClicked: boolean = false;
  public margin: any = 0;
  public width: any = window.innerWidth;

  active = 1;
  loading$: Observable<boolean | null>;

  overview = {
    email: "",
    phone: 0,
    companyActivity: "",
    logo: "",
    companyName: "",
    companyAddress: "",
    firstName: "",
    lastName: "",
    website: "",
    activated: false,
  };
  @Output() companyActivities: IberisActivities[];
  constructor(
    private store: Store<AppStateInterface>,
    private layout: LayoutService,
    @Inject(DOCUMENT) private document: any,
    protected translate: TranslateService,
    private saveHistory : SaveUserHistoryService
  ) {
    super(store);
    this.loading$ = this.store
      .pipe(select(advertiserLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    if (this.translate.currentLang == "fr")
      this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
    else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;

    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        if (langChangeEvent.lang == "fr")
          this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
        else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;
      }
    );

    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.PROFILE
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.screenwidth = event.target.innerWidth;
  }
}
