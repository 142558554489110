import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  createBugReport,
  invokeBugReport,
} from "src/app/store/bug-report/bug-report.action";
import {
  bugReportSelector,
  loadingBugReport,
} from "src/app/store/bug-report/bug-report.selectors";
import {
  invokeUploadFile,
  invokeUploadFileWithProgress,
  setFileName,
} from "src/app/store/file/file.actions";
import {
  uploadFileProgressSelector,
  uploadFileSelector,
} from "src/app/store/file/file.selectors";
@Component({
  selector: "app-create-bug-report",
  templateUrl: "./create-bug-report.component.html",
  styleUrls: ["./create-bug-report.component.scss"],
})
export class CreateBugReportComponent extends BaseComponent implements OnInit {
  loading$: Observable<boolean | null>;
  bugReport$: Observable<SuccessDto | null>;
  createBReport: string | null;
  showError: boolean = false;
  isShown: boolean = false;
  form: FormGroup;
  validate: boolean;
  submitted: boolean;
  isUploaded: boolean;
  filename: string | null;
  files: File[] = [];
  uploadFile$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  isNotValid: boolean | null;
  isAdvertiser: boolean;
  showAlert: boolean = false;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  loading: boolean = false;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    configPB: NgbProgressbarConfig
  ) {
    super(store);
    configPB.max = 100;
    configPB.striped = true;
    configPB.animated = true;
    configPB.type = "primary";
    configPB.height = "20px";
    this.uploadFile$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingBugReport))
      .pipe(takeUntil(this.ngDestroyed$));
    this.bugReport$ = this.store
      .pipe(select(bugReportSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAdvertiser = this.router.url.includes("/advertiser");
      }
    });
  }

  ngOnInit(): void {
    this.bugReport$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.createBReport = result.message;
        this.store.dispatch(createBugReport({ successDto: null }));
        if (this.createBReport != "") {
          this.toastr.success(
            this.translate.instant("response.success." + this.createBReport),
            this.translate.instant("response.successTitle")
          );
          if (this.isAdvertiser) {
            this.router.navigate(["dashboard/advertiser/support"]);
          } else {
            this.router.navigate(["dashboard/location-partner/support"]);
          }
        }
      }
    });
    this.uploadFile$.subscribe(
      (result: HttpEvent<any> | null | HttpResponse<{ filename: string }>) => {
        if (result?.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          const total = result?.total ?? 1;
          this.uploadProgress = Math.round((result.loaded / total) * 100);
        }
        if (result?.type == HttpEventType.Response) {
          this.filename =
            (result as HttpResponse<{ filename: string }>)?.body?.filename ??
            "";
          this.submitted = true;
          this.loading = false;
          this.store.dispatch(setFileName({ filename: null }));
          setTimeout(() => {
            this.showProgress = false;
          }, 2000);
        }
      }
    );
    /*this.uploadFile$.subscribe((result: string | null) => {
      if (result != null) {
        this.filename = result;
        let bugReport = this.form.value;
        this.submitted = true;
        this.store.dispatch(
          invokeBugReport({
            bugReport: {
              ...bugReport,
              fileName: this.filename,
            },
          })
        );
        this.store.dispatch(setFileName({ filename: null }));
      }
    });*/
    this.loading$.subscribe((data) => {});
  }
  get title() {
    return this.form.get("title");
  }
  get description() {
    return this.form.get("description");
  }
  onSubmitAddBugReport() {
    this.submitted = true;
    this.validate = this.form.invalid;
    this.uploadProgress = 0;
    if (!this.validate) {
      this.store.dispatch(
        invokeBugReport({
          bugReport: {
            ...this.form.value,
            fileName: this.filename,
          },
        })
      );
    }
  }
  onSelect(event: File[]) {
    if (event[0].size > 5242880) {
      this.showError = true;
      this.showProgress = false;
    } else {
      this.files.push(event[0]);
      this.isShown = true;
      const formData = new FormData();
      formData.append("file", this.files[0], this.files[0].name);
      this.store.dispatch(invokeUploadFileWithProgress({ file: formData }));
    }
  }
  onRemove() {
    this.files = [];
  }
  clear() {
    this.onRemove();
    this.form.reset({
      title: "",
      description: "",
    });
  }
}
