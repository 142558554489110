<h6 class="mt-2">{{ "campaignDetails.locations" | translate }} *</h6>
<div class="form-check checkbox checkbox-primary mb-0">
  <input
    class="form-check-input"
    id="checkbox-primary-2"
    [checked]="CheckLocationSelectedAll()"
    (click)="selectAllLocations($event)"
    type="checkbox"
  />
  <label class="form-check-label label" for="checkbox-primary-2">
    {{ "select.selectAllLocations" | translate }}
  </label>
</div>
<ng-select
  [placeholder]="'select.selectLocations' | translate"
  [searchWhileComposing]="true"
  [items]="getLocationPartnersCompanyNames"
  [closeOnSelect]="false"
  [hideSelected]="true"
  (change)="
    newCampaignCalculatorService.onSelectCalculateCost(
      adDetails,
      selectedLocations
    )
  "
  [(ngModel)]="selectedLocations"
  [clearSearchOnAdd]="true"
  [multiple]="true"
>
</ng-select>
<div *ngIf="calculateTotalLPCostByCampanyNames.length > 0">
  <div class="col-xxl-12 col-lg-12 box-col-12 box-container">
    <ng-container *ngFor="let item of calculateTotalLPCostByCampanyNames"
      ><span
        class="m-l-5 m-b-5"
        data-bs-toggle="tooltip"
        placement="bottom"
        [ngbTooltip]="tooltipAvailability"
        (mouseenter)="locationSelected = item"
        [ngClass]="
          'badge ' + badgeService.getBadgeForLocationPartnerAvailability(item)
        "
        >{{ item.companyName }}
      </span></ng-container
    >
  </div>
</div>
<p *ngIf="notAvailableExists" class="color-darkGray m-15">
  <i>{{ "newCampaign.step1.availibilityNotice" | translate }}</i>
</p>

<ng-template #tooltipAvailability>
  <div>
    {{
      "enumMapping.locationAvailibility." +
        locationSelected.availabilities.availabilityStatus | translate
    }}
  </div>
</ng-template>

<!-- <div
    *ngIf="
      locationSelected.availabilities.availabilityStatus ===
      partiallyAvailableLocationEnum
    "
  >
    <hr />
    <div class="row justify-content-center position-sticky">
      Not available dates:
      <ul>
        <li
          *ngFor="
            let date of locationSelected.availabilities.noneAvailableDates
          "
        >
          {{ date | localizedDate : "dd/MM/yyyy" }}
        </li>
      </ul>
    </div>
  </div> -->
