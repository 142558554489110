import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/base.component";
import { Observable, takeUntil } from "rxjs";
import { AdvertiserDashboard } from "src/app/shared/models/advertiser/advertiser-dashboard.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import { select, Store } from "@ngrx/store";
import {
  advertiserMainDashbordSelector,
  invokeLoadingMainSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import {
  invokeMainDashboard,
  invokeProfileDetails,
} from "src/app/store/advertiser/advertiser.actions";
import { advertiserProfileDetailsSelector } from "src/app/store/advertiser/advertiser.selectors";
import { BadgeService } from "src/app/shared/services/badge.service";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import {
  setMainDashboard,
  setProfileDetails,
} from "src/app/store/advertiser/advertiser.actions";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { resultSuccessAd } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { resultUniqueConsumersSelector } from "src/app/store/statistics/statistics.selectors";
import { invokeUniqueConsumers , resultUniqueConsumers } from "src/app/store/statistics/statistics.actions";
import { JwtService } from "src/app/shared/services/jwt.service";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent extends BaseComponent implements OnInit {
  loading$: Observable<boolean | null>;
  isLoading: boolean | null;
  dashboardAdvertiser$: Observable<AdvertiserDashboard | null>;
  profile$: Observable<AdvertiserDetailsInterface | null>;
  isExistAdvertisingCampaign: boolean | null = true;
  adsCard = {
    value: 0,
    title: "",
    unit: "last month",
    icon: "assets/identity/ad.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthUnit: "this month",
    hasYesterdayGrowth: false,
  };
  impression = {
    value: 0,
    title: "Impressions",
    unit: "yesterday",
    icon: "assets/identity/eye-icon.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthYesterday: 0,
    growthUnit: "Today",
    growthYesterdayUnit: "Yesterday",
    hasYesterdayGrowth: false,
  };
  completedVideos = {
    value: 0,
    title: "Engagement",
    unit: "yesterday",
    icon: "assets/identity/time-icon.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthYesterday: 0,
    growthUnit: "Today",
    growthYesterdayUnit: "Yesterday",
    hasYesterdayGrowth: false,
  };
  clicks = {
    value: 0,
    title: "Clicks",
    unit: "yesterday",
    icon: "assets/identity/click-icon.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthYesterday: 0,
    growthUnit: "Today",
    growthYesterdayUnit: "Yesterday",
    hasYesterdayGrowth: false,
  };
  advertisingCampaingStatus: string[];
  title: string;
  advertiser: string;
  bookMeeting: string;
  uniquViews: number;
  blogLink: string;
  resultUniqueConsumers$: Observable<number | null>;
  resultUniqueConsumers: number;
  cachedAt : Date | null
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    protected translate: TranslateService,
    private jwtService : JwtService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.resultUniqueConsumers$ = this.store
    .pipe(select(resultUniqueConsumersSelector))
    .pipe(takeUntil(this.ngDestroyed$));
    this.dashboardAdvertiser$ = this.store
      .pipe(select(advertiserMainDashbordSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(invokeLoadingMainSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.profile$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(resultUniqueConsumers({uniqueConsommers : null}))
    this.store.dispatch(
      invokeUniqueConsumers({ email: this.jwtService.getEmail(), isRefreshCache : false })
    );
    this.resultUniqueConsumers$.subscribe((result: number | null) => {
      
      if (result != null) {
        this.resultUniqueConsumers = result ;
        
      }
    });
    this.store.dispatch(setMainDashboard({dashboardAdvertiser : null}))
    this.loading$.subscribe((data) => {
      this.isLoading = data;
    });
    this.blogLink = environment.links.blogs;
    this.store.dispatch(resultSuccessAd({ successMessage: null }));
    this.bookMeeting = environment.links.bookMeetingAdvertiser;
    this.store.dispatch(setMainDashboard({ dashboardAdvertiser: null }));
    this.store.dispatch(setProfileDetails({ profileDetails: null }));
    this.profile$.subscribe((data) => {
      if (data == null) {
        this.store.dispatch(invokeProfileDetails());
      }
    });
    this.dashboardAdvertiser$.subscribe(
      (result: AdvertiserDashboard | null) => {
        if (result != null) {
          this.uniquViews = result.uniqueViews;
          this.adsCard.growth = result?.adsGrowth;
          this.adsCard.value = result?.ads;
          this.adsCard.changes = result?.adsChanges;

          this.completedVideos.value = result?.completed;
          this.completedVideos.changes = result?.completedChanges;
          this.completedVideos.growthYesterday = result.completedYesterday;

          this.impression.value = result?.views;
          this.impression.changes = result?.viewsChanges;
          this.impression.growth = result?.viewsToday;
          this.impression.growthYesterday = result.viewsYesterday;

          this.clicks.value = result?.redirection;
          this.clicks.changes = result.clicksChanges;
          this.clicks.growth = result.clicksToday;
          this.clicks.growthYesterday = result.clicksYesterday;
          this.cachedAt = result.cachedAt
        } else {
          this.store.dispatch(invokeMainDashboard({isRefreshCache : false}));
          this.saveHistory.saveUserHistory(
            UserHistoryActionsEnum.GET,
            UserHistoryServicesEnum.MAIN_DASHBOARD_ADVERTISER
          );
        }
      }
    );

    this.advertisingCampaingStatus = Object.keys(CampaignStatusEnum)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => key as keyof typeof CampaignStatusEnum);
  }
  isExistCampaign(isExist: boolean | null) {
    this.isExistAdvertisingCampaign = isExist;
  }

  onClickRefreshCache(){
    this.store.dispatch(
      invokeUniqueConsumers({ email: this.jwtService.getEmail(), isRefreshCache : true })
    );
    this.store.dispatch(invokeMainDashboard({isRefreshCache : true }));
  }
}