import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { InputAudience } from "src/app/shared/models/input-audience.interface";
import { ResultGlobalStatisticsForLP } from "src/app/shared/models/statistics/resultGlobalStaticsForLP";
import { JwtService } from "src/app/shared/services/jwt.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeGlobalStatisticsForLP,
  invokeUniqueConsumers,
} from "src/app/store/statistics/statistics.actions";
import {
  loadingConsumersSelector,
  loadingGlobalStaticsSelector,
  resultGlobalStatisticsForLPSelector,
  resultUniqueConsumersSelector,
  loadingSummaryStatisticsSelector,
} from "src/app/store/statistics/statistics.selectors";
import {
  resultGlobalStatisticsForLP,
  resultUniqueConsumers,
} from "src/app/store/statistics/statistics.actions";
import { AudienceType } from "src/app/shared/enum/chart/audiance";
import { environment } from "src/environments/environment";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import { resultLPInfoSelector } from "src/app/store/locationPartner/location-partner.selectors";
import {
  invokeLPInfo,
  resultLPInfo,
} from "src/app/store/locationPartner/actions/get-locations.actions";
import { ToastrService } from "ngx-toastr";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { sendMailSelector } from "src/app/store/mailing/mailing.selectors";
import { resultSindingMail } from "src/app/store/mailing/mailing.actions";
import { TranslateService } from "@ngx-translate/core";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
})
export class MainComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Output("LPcompanyName") LPcompanyName = new EventEmitter<string>();
  impressionCard = {
    value: 0,
    title: "dashboard.location.viewsNumber",
    icon: "assets/identity/eye-icon.svg",
    class: "card info-card",
  };
  vueUniqueCard = {
    value: 0,
    title: "dashboard.location.uniqueViewsNumber",
    icon: "assets/identity/uniqueViews.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    hasYesterdayGrowth: false,
  };
  utilisateurUniqueCard = {
    value: 0,
    title: "dashboard.location.uniqueUsers",
    icon: "assets/identity/uniqueConsumers.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    hasYesterdayGrowth: false,
  };
  routersCard = {
    value: 0,
    title: "dashboard.location.installedRoutersNumber",
    icon: "assets/identity/icon-router.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    hasYesterdayGrowth: false,
  };
  ads: number;
  resultGlobalStaticsForLP$: Observable<ResultGlobalStatisticsForLP | null>;
  resultGlobalStaticsForLP: ResultGlobalStatisticsForLP | null;
  companyName: string = "";
  loading$: Observable<boolean | null>;
  resultUniqueConsumers$: Observable<number | null>;
  resultUniqueConsumers: number;
  loadingUniqueConsumers$: Observable<boolean | null>;
  @Output() inputAudience = new EventEmitter<InputAudience>();
  globalStatics: ResultGlobalStatisticsForLP | null;
  loadingGlobalStatics$: Observable<boolean | null>;
  bookMeeting: string;

  profile$: Observable<LPInfo | null>;

  resultSendStatics$: Observable<SuccessDto | null>;
  cachedAt : Date | null
  constructor(
    private store: Store<AppStateInterface>,
    private jwtService: JwtService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.companyName = this.jwtService.getCompanyName();
    this.resultGlobalStaticsForLP$ = this.store
      .pipe(select(resultGlobalStatisticsForLPSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingSummaryStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultUniqueConsumers$ = this.store
      .pipe(select(resultUniqueConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingUniqueConsumers$ = this.store
      .pipe(select(loadingConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingGlobalStatics$ = this.store
      .pipe(select(loadingGlobalStaticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.profile$ = this.store
      .pipe(select(resultLPInfoSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultSendStatics$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngAfterViewInit(): void {
    this.LPcompanyName.emit(this.companyName);
    this.inputAudience.emit({
      name: this.companyName,
      type: AudienceType.LOCATION,
      title: "dashboard.location.summary",
      titleSpline: null,
      titleColumnChart: null,
    });
  }

  ngOnInit(): void {
    this.resultSendStatics$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.store.dispatch(resultSindingMail({ successMessage: null }));
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );
      }
    });
    this.store.dispatch(resultLPInfo({ lpInfo: null }));
    this.profile$.subscribe((data) => {
      if (data) {
        
      } else {
        this.store.dispatch(invokeLPInfo());
      }
    });
    this.bookMeeting = environment.links.bookMeetingLP;
    this.store.dispatch(
      resultGlobalStatisticsForLP({ resultGlobalStatisticsForLP: null })
    );
    this.store.dispatch(resultUniqueConsumers({ uniqueConsommers: null }));
    this.loading$.subscribe((data) => {});
    this.loadingUniqueConsumers$.subscribe((data) => {});
    this.loadingGlobalStatics$.subscribe((data) => {});
    this.resultGlobalStaticsForLP$.subscribe(
      (result: ResultGlobalStatisticsForLP | null) => {
        if (result != null) {
          this.globalStatics = result;
          this.ads = result.ads;
          this.impressionCard.value = result.views;
          this.vueUniqueCard.value = result.uniqueViews;
          this.routersCard.value = result.routers;
          this.cachedAt = result.cachedAt
        } else {
          this.fetchGlobalStatistics(false)
        }
      }
    );
    this.resultUniqueConsumers$.subscribe((result: number | null) => {
      if (result != null) {
        this.utilisateurUniqueCard.value = result;
      } else {
        this.fetchGlobalUniqueConsumers(false)
      }
    });
    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.MAIN_DASHBOARD_LP
    );
  }
  fetchGlobalStatistics(refreshCache : boolean){
    this.store.dispatch(
      invokeGlobalStatisticsForLP({ companyName: this.companyName, refreshCache : refreshCache })
    );
  }
  fetchGlobalUniqueConsumers(refreshCache : boolean){
    this.store.dispatch(
      invokeUniqueConsumers({ email: this.jwtService.getEmail() , isRefreshCache : refreshCache})
    );
  }
}
