import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeCheckTokenForResetPassword,
  invokeForgotPassword,
  invokeResetPassword,
  setForgotPasswordSuccess,
  setResetPassword,
  userSuccessMessage,
} from "src/app/store/user/user.actions";
import {
  sendForgetPasswordLoaderSelector,
  forgotPasswordSuccesSelector,
  forgotPasswordFailedSelector,
  checkTokenForResetPasswordSuccessSelector,
  userSuccessMessageSelector,
} from "src/app/store/user/user.selectors";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ApiStateInterface } from "src/app/store/apiState.interface";
import { MustMatch } from "src/app/shared/validators/passwordMatch";
import { Location } from "@angular/common";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent extends BaseComponent implements OnInit {
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  formForgotPasswordStep1: FormGroup;
  formResetPasswordStep2: FormGroup;
  error: string | null = null;
  public validate = false;
  step = 1;
  submitted: boolean;
  emailStep: boolean = false;

  successResetPassword: string | null;
  forgotPasswordSuccess$: Observable<SuccessDto | null>;
  sendEmail: string | null;
  mailSended: boolean = false;
  sendEmailLoader$: Observable<boolean | null>;
  forgotPasswordFailed$: Observable<ApiStateInterface | null>;
  checkTokenForResetPassword$: Observable<SuccessDto | null>;
  success$: Observable<SuccessDto | null>;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppStateInterface>,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private location: Location
  ) {
    super(store);
    this.sendEmailLoader$ = this.store
      .pipe(select(sendForgetPasswordLoaderSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.forgotPasswordSuccess$ = this.store
      .pipe(select(forgotPasswordSuccesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.forgotPasswordFailed$ = this.store
      .pipe(select(forgotPasswordFailedSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.checkTokenForResetPassword$ = this.store
      .pipe(select(checkTokenForResetPasswordSuccessSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.success$ = this.store
      .pipe(select(userSuccessMessageSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.formForgotPasswordStep1 = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
    this.formResetPasswordStep2 = this.fb.group(
      {
        token: [null, Validators.required],
        password: [
          null,
          [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
        confirmPassword: [
          null,
          [
            Validators.required,
            Validators.pattern("^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"),
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }
  get email() {
    return this.formForgotPasswordStep1.get("email");
  }
  get token() {
    return this.formResetPasswordStep2.get("token");
  }
  get password() {
    return this.formResetPasswordStep2.get("password");
  }
  get confirmPassword() {
    return this.formResetPasswordStep2.get("confirmPassword");
  }

  ngOnInit(): void {
    this.forgotPasswordFailed$.subscribe((value) => {
      if (value) {
        this.loading = false;
      }
    });
    this.forgotPasswordSuccess$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.sendEmail = result?.message;
        this.store.dispatch(setForgotPasswordSuccess({ successMessage: null }));
        this.mailSended = true;
        this.toastr.success(
          this.translate.instant("response.success." + this.sendEmail),
          this.translate.instant("response.successTitle")
        );
      }
    });

    this.checkTokenForResetPassword$.subscribe((success) => {
      if (success) {
        this.step++;
        this.store.dispatch(setResetPassword({ successMessage: null }));
      }
    });
    this.success$.subscribe((success: SuccessDto | null) => {
      if (success) {
        this.toastr.success(
          this.translate.instant("response.success." + success.message),
          this.translate.instant("response.successTitle")
        );

        this.store.dispatch(userSuccessMessage({ successMessage: null }));
        setTimeout(() => {
          window.location.href =
            this.location.prepareExternalUrl("/auth/login");
        }, 3000);
      }
    });
  }
  clickShowPassword() {
    this.showPassword = !this.showPassword;
  }
  clickShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  forgotPasswordStep1(event: any) {
    this.emailStep = true;
    this.validate = this.formForgotPasswordStep1.invalid;
    if (!this.validate) {
      let forgotPassword = this.formForgotPasswordStep1.value;
      this.store.dispatch(
        invokeForgotPassword({
          email: forgotPassword.email.toLowerCase(),
        })
      );
    }
  }

  nextStep() {
    this.store.dispatch(
      invokeCheckTokenForResetPassword({
        email: this.formForgotPasswordStep1.value.email.toLowerCase(),
        token: this.formResetPasswordStep2.value.token,
      })
    );
  }

  resetPasswordStep2(event: any) {
    this.validate = this.formResetPasswordStep2.invalid;
    if (
      !this.validate &&
      this.formResetPasswordStep2.value.password ===
        this.formResetPasswordStep2.value.confirmPassword
    ) {
      let resetPasswordStep2 = this.formResetPasswordStep2.value;
      this.store.dispatch(
        invokeResetPassword({
          password: resetPasswordStep2.password,
          token: resetPasswordStep2.token,
        })
      );
      this.submitted = true;
    }
  }
}
