import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { ExportData, exportDataType } from "src/app/shared/data/exportData";
import { AudienceType } from "src/app/shared/enum/chart/audiance";
import { TechnicalCardSearchMethodEnum } from "src/app/shared/enum/chart/technical-data";
import { DateFilter } from "src/app/shared/models/date-filte.interface";
import { InputAudience } from "src/app/shared/models/input-audience.interface";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import { ResultGlobalStatisticsForLP } from "src/app/shared/models/statistics/resultGlobalStaticsForLP";
import { RetentionRate } from "src/app/shared/models/statistics/retentionRate";
import { TechnicalCardSearchMethod } from "src/app/shared/models/statistics/technical-data";
import { JwtService } from "src/app/shared/services/jwt.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeBrowserFamily,
  invokeDeviceBrand,
  InvokeDeviceType,
  invokeOsFamily,
} from "src/app/store/consumers/consumers.actions";
import { invokeLPInfo } from "src/app/store/locationPartner/actions/get-locations.actions";
import { resultLPInfoSelector } from "src/app/store/locationPartner/location-partner.selectors";
import {
  invokeGlobalStatisticsForLP,
  invokeRetainedConsumers,
  invokeRetentionRateByWeeks,
  invokeRetentionTime,
  invokeSummaryStaticsInDateBetweenForLP,
  invokeUniqueConsumers,
  invokeViewsByGenderDemographicForLocationPartner,
  resultGlobalStatisticsForLP,
  resultRetainedConsumers,
  resultRetentionRateByWeeks,
  resultRetentionTime,
  resultUniqueConsumers,
} from "src/app/store/statistics/statistics.actions";
import {
  loadingConsumersSelector,
  loadingGlobalStaticsSelector,
  loadingresultRetentionTimeSelector,
  loadingRetainedConsumersSelector,
  resultGlobalStatisticsForLPSelector,
  resultRetentionRateByWeeksSelectors,
  resultRetentionTimeSelector,
  resultUniqueConsumersSelector,
  resutlRetainedConsumersSelector,
  loadingSummaryStatisticsSelector,
} from "src/app/store/statistics/statistics.selectors";
import { environment } from "src/environments/environment";
import { RetentionRateData } from "src/app/shared/data/exportData";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { resultSindingMail } from "src/app/store/mailing/mailing.actions";
import { sendMailSelector } from "src/app/store/mailing/mailing.selectors";
import { ToastrService } from "ngx-toastr";
import { UserHistoryActionsEnum } from "src/app/shared/enum/userHistoryActions";
import { UserHistoryServicesEnum } from "src/app/shared/enum/userHistoryService";
import { SaveUserHistoryService } from "src/app/shared/services/history/saveHistory";

@Component({
  selector: "app-unlocked-screens",
  templateUrl: "./unlocked-screens.component.html",
  styleUrls: ["./unlocked-screens.component.scss"],
})
export class UnlockedScreensComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Output() retentionRate = new EventEmitter<RetentionRate | null>();
  @Output() search = new EventEmitter<TechnicalCardSearchMethod>();
  loading$: Observable<boolean | null>;
  companyName: string;
  Retention_time = {
    value: "",
    title: "location.statistics.retentionTime",
    icon: "assets/identity/retention.svg",
    unit: 0,
  };
  consummers = {
    value: 0,
    title: "location.statistics.consumers",
    icon: "assets/identity/consummers.svg",
    unit: 0,
  };
  impression = {
    value: 0,
    title: "location.statistics.impression",
    icon: "assets/identity/eye-icon.svg",
    unit: 0,
  };
  uniqueViews = {
    value: 0,
    title: "location.statistics.uniqueViews",
    icon: "assets/identity/uniqueViews.svg",
    unit: 0,
  };
  @Output() inputAudience = new EventEmitter<InputAudience>();
  uniqueConsumers$: Observable<number | null>;
  uniqueConsumers: number;

  form: FormGroup;
  resultGlobalStaticsForLP$: Observable<ResultGlobalStatisticsForLP | null>;
  globalStatics: ResultGlobalStatisticsForLP | null;
  loadingConsumers$: Observable<boolean | null>;
  premium: boolean;
  columnChart = {
    title: "Données démographiques",
    yaxisTitle: "Taux de rétention en (%)",
    xaxisTitle: "Semaines",
  };
  resultRetentionRate$: Observable<RetentionRate | null>;
  loadingGlobalStatics$: Observable<boolean | null>;
  resultRetainedConsumers$: Observable<number>;
  resultRetainedConsumers: number;
  resultRetentionTime$: Observable<number[] | null>;
  loadingRetentionTime$: Observable<boolean | null>;
  loadingReteinedConsumers$: Observable<boolean | null>;
  profile$: Observable<LPInfo | null>;
  retentionChart: ExportData[] = RetentionRateData;
  dateFilter: DateFilter = {
    startDate: null,
    endDate: null,
  };
  bookMeeting: string;
  title: string;

  resultSendStatics$: Observable<SuccessDto | null>;
  cachedAt: Date | null;
  retentionRateCachedAt : Date | null
  constructor(
    private store: Store<AppStateInterface>,
    private jwtService: JwtService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private saveHistory: SaveUserHistoryService
  ) {
    super(store);
    this.uniqueConsumers$ = this.store
      .pipe(select(resultUniqueConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultGlobalStaticsForLP$ = this.store
      .pipe(select(resultGlobalStatisticsForLPSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingSummaryStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingConsumers$ = this.store
      .pipe(select(loadingConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultRetentionRate$ = this.store
      .pipe(select(resultRetentionRateByWeeksSelectors))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingGlobalStatics$ = this.store
      .pipe(select(loadingGlobalStaticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultRetainedConsumers$ = this.store.pipe(
      select(resutlRetainedConsumersSelector)
    );
    this.resultRetentionTime$ = this.store
      .pipe(select(resultRetentionTimeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingRetentionTime$ = this.store
      .pipe(select(loadingresultRetentionTimeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingReteinedConsumers$ = this.store
      .pipe(select(loadingRetainedConsumersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.profile$ = this.store
      .pipe(select(resultLPInfoSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultSendStatics$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.form = this.fb.group({
      date: [[null, null]],
    });
  }
  ngAfterViewInit(): void {
    this.inputAudience.emit({
      name: this.companyName,
      type: AudienceType.LOCATION,
      title: "Audiences",
      titleSpline: "invoices.summary",
      titleColumnChart: "invoices.distributionAgeGender",
    });
    this.search.emit({
      technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
      value: this.companyName ?? "",
      locationPartner: null,
      finished: false,
    });
  }
  ngOnInit(): void {
    this.resultSendStatics$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.store.dispatch(resultSindingMail({ successMessage: null }));
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );
      }
    });
    this.title = this.jwtService.getCompanyName();
    this.store.dispatch(invokeLPInfo());
    this.companyName = this.jwtService.getCompanyName();
    this.store.dispatch(
      resultGlobalStatisticsForLP({ resultGlobalStatisticsForLP: null })
    );
    this.store.dispatch(resultRetentionRateByWeeks({ retentionRate: null }));
    this.store.dispatch(resultRetainedConsumers({ consumers: 0 }));
    this.store.dispatch(resultUniqueConsumers({ uniqueConsommers: null }));
    this.store.dispatch(resultRetentionTime({ retentionTime: null }));
    this.bookMeeting = environment.links.bookMeetingLP;
    this.profile$.subscribe((data) => {
      if (data) {
        if (
          data.premiumEndDate != null &&
          new Date(data.premiumEndDate) >= new Date()
        ) {
          this.premium = true;
          this.fetchRetentionRate(false)
          this.fetchCardsStatistics(false);
          const dateFilte = { startDate: null, endDate: null };
          this.store.dispatch(
            invokeSummaryStaticsInDateBetweenForLP({
              companyName: this.companyName ?? "",
              dateFilter: dateFilte,
              refreshCache: false,
            })
          );
          this.store.dispatch(
            invokeViewsByGenderDemographicForLocationPartner({
              companyName: this.companyName ?? "",
              forMap: false,
              dateFilter: dateFilte,
              refreshCache: false,
            })
          );
          this.store.dispatch(
            invokeOsFamily({
              search: {
                technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
                value: this.companyName ?? "",
                locationPartner: null,
                finished: false,
              },
              refreshCache: false,
            })
          );
          this.store.dispatch(
            invokeBrowserFamily({
              search: {
                technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
                value: this.companyName ?? "",
                locationPartner: null,
                finished: false,
              },
              refreshCache : false
            })
          );
          this.store.dispatch(
            InvokeDeviceType({
              search: {
                technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
                value: this.companyName ?? "",
                locationPartner: null,
                finished: false,
              },
              refreshCache : false
            })
          );
          this.store.dispatch(
            invokeDeviceBrand({
              search: {
                technicalCard: TechnicalCardSearchMethodEnum.LOCATION,
                value: this.companyName ?? "",
                locationPartner: null,
                finished: false,
              },
              refreshCache : false
            })
          );
        }
      }
    });

    this.loadingConsumers$.subscribe((data) => {});
    this.loading$.subscribe((data) => {});
    this.loadingGlobalStatics$.subscribe((data) => {});
    this.loadingRetentionTime$.subscribe((data) => {});
    this.loadingReteinedConsumers$.subscribe((data) => {});
    this.resultGlobalStaticsForLP$.subscribe(
      (result: ResultGlobalStatisticsForLP | null) => {
        if (result != null) {
          this.globalStatics = result;
          this.impression.value = result.views;
          this.uniqueViews.value = result.uniqueViews;
          this.cachedAt = result.cachedAt;
        }
      }
    );
    this.resultRetentionRate$.subscribe((result) => {
      if (result != null) {
        this.retentionRateCachedAt = result.cachedAt
        this.retentionRate.emit(result);
        let csvRows: string[] = [];
        let headers = [];
        headers = [
          this.translate.instant("Charts.retentionRate.week"),
          this.translate.instant("Charts.retentionRate.week"),
          this.translate.instant("Charts.retentionRate.yaxisTitle"),
        ];
        for (let i = 0; i < result.weeks.length; i++) {
          const cols = [result.weeks[i], result.retentionRate[i]];
          csvRows.push(cols.join(","));
        }
        for (let i = 0; i < this.retentionChart.length; i++) {
          if (this.retentionChart[i].type == exportDataType.RETENTION_RATE)
            this.retentionChart[i].data = [headers, ...csvRows].join("\n");
        }
      }
    });
    this.resultRetainedConsumers$.subscribe((result: number) => {
      if (result) {
        this.resultRetainedConsumers = result;
      }
    });
    this.uniqueConsumers$.subscribe((result: number | null) => {
      if (result != null) {
        this.uniqueConsumers = result;
        this.consummers.value = result;
      }
    });
    this.resultRetentionTime$.subscribe((result) => {
      if (result) {
        const sum = result.reduce((acc, num) => acc + num, 0) / result.length;
        const days = Math.floor(sum);
        const remainingHoursDecimal = (sum - days) * 24;
        const hours = Math.floor(remainingHoursDecimal);
        const remainingMinutesDecimal = (remainingHoursDecimal - hours) * 60;
        const minutes = Math.round(remainingMinutesDecimal);
        this.Retention_time.value =
          days + " jours " + hours + " heures " + minutes + " minute ";
      }
    });

    this.saveHistory.saveUserHistory(
      UserHistoryActionsEnum.GET,
      UserHistoryServicesEnum.CAMPAIGN_LOCATIONS_STATISTICS
    );
  }
  getChartInDate() {
    this.dateFilter = {
      startDate: this.form.get("date")?.value[0],
      endDate: this.form.get("date")?.value[1],
    };
    if (this.premium) {
      this.fetchRetentionRate(false)
    }
  }

  fetchCardsStatistics(refreshCache: boolean) {
    this.store.dispatch(
      invokeGlobalStatisticsForLP({
        companyName: this.companyName,
        refreshCache: refreshCache,
      })
    );
    this.store.dispatch(
      invokeRetainedConsumers({
        email: this.jwtService.getEmail(),
        refreshCache: refreshCache,
      })
    );
    this.store.dispatch(
      invokeUniqueConsumers({
        email: this.jwtService.getEmail(),
        isRefreshCache: refreshCache,
      })
    );
    this.store.dispatch(
      invokeRetentionTime({
        email: this.jwtService.getEmail(),
        refreshCache: refreshCache,
      })
    );
  }

  fetchRetentionRate(refreshCache : boolean){
    this.store.dispatch(
      invokeRetentionRateByWeeks({
        companyName: this.companyName,
        dateFilter: this.dateFilter,
        refreshCache : refreshCache
      })
    );
  }

}
